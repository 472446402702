import MyAppCard from "./AppCard";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { IMatchRoleApiResponse } from "../../redux/slices/matchRoleSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NoDataCard from "../Cards/NoDataCard";

const AppSection: React.FC = () => {
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const skeletonBaseColor = isDarkMode ? "#2c2c2c" : "#e0e0e0";
  const skeletonHighlightColor = isDarkMode ? "#3a3a3a" : "#f0f0f0";

  const role = useSelector((state: RootState) => state.matchRole);
  const appData = role.isSuccess && role.data?.length ? role.data : [];

  return (
    <div className=''>

      <div className='flex items-center justify-between mb-2'>
        <h1 className='text-[#31C6BB] font-poppins font-medium text-md md:text-xl'>MyApps</h1>
        {/* <button className={`${isDarkMode ? "text-[#4D4D4D]" : "text-[#31C6BB]"} cursor-pointer p-1 rounded-md font-poppins font-normal text-base  md:text-lg flex flex-row items-center`}>View all</button> */}
      </div>

      {
        role.isSuccess && appData && appData.length
          ? <div className={`grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 ${isDarkMode ? "fill-white" : ""}`}>
            {
              appData.map((cardData: IMatchRoleApiResponse, index: number) => (
                <div key={index}>
                  <MyAppCard
                    imgSrc={cardData?.subDomainSvg}
                    title={cardData?.subDomainName}
                    domainName={cardData?.domainName}
                    url={cardData?.subDomainURL}
                    desc={cardData?.subDomainDescription} />
                </div>
              ))
            }
          </div>
          : role.isError
            ? <NoDataCard message="No App Access" />
            : <div className={`grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 ${isDarkMode ? "fill-white" : ""}`}>
              {
                Array.from({ length: 5 }).map((_, index) => (
                  <div key={index}>
                    <Skeleton
                      height={200}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                    />
                  </div>
                ))
              }
            </div>
      }
    </div>
  );
};

export default AppSection;
