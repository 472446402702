import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import LeftFooter from "./FooterSection/LeftFooter";
import LeftHeader from "./HeaderSection/LeftHeader";

const LeftContainer: React.FC = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);


    return (

        <div className="w-full h-full mx-auto flex items-center justify-center">
            <div className={`w-full  h-[97%] ${isDarkMode ? "bg-[#1B1B1D] bg-opacity-70" : ""} flex items-center justify-center`}>
                <div className="w-full h-full  flex flex-col items-center justify-between ">
                    <LeftHeader />
                    <LeftFooter />
                </div>
            </div>
        </div>
    );
};

export default LeftContainer;