import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import ThemeToggle from "./themeToggle";
import avatar from "../../assets/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { LogoImage, LogoName } from "../../assets";
import { RootState } from "../../redux/store";
import { activeDrawer } from "../../redux/slices/DrawerSlice";
import { setActiveDomain } from "../../redux/slices/activeDomain";
import { MdKeyboardArrowRight } from "react-icons/md";
import { getInitials } from "../../App";




const Navbar: React.FC = () => {
    const [selectedButton, setSelectedButton] = useState("Home");
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredDomains, setFilteredDomains] = useState([]);
    const suggestionsRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const domain = useSelector((state: RootState) => state.domain);
    const domainData = domain.domainIsSuccess && domain.domainData.length ? domain.domainData : [];

    const dispatch = useDispatch();

    const handleDrawerToggle = () => {
        dispatch(activeDrawer());
    };

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const userImg = useSelector((state: RootState) => state.userImg);
    const userInfo = useSelector((state: RootState) => state.userInfo);

    const handleButtonClick = (buttonName: string) => {
        setSelectedButton(buttonName);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (value.trim() === "") {
            setFilteredDomains([]);
        } else {
            const filtered = domainData?.filter(domain =>
                domain.name?.toLowerCase().includes(value.toLowerCase()) ||
                domain.subDomains?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredDomains(filtered);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            searchInputRef.current &&
            !searchInputRef.current.contains(event.target as Node) &&
            suggestionsRef.current &&
            !suggestionsRef.current.contains(event.target as Node)
        ) {
            setSearchTerm("");
            setFilteredDomains([]);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const handleDomainClick = (domainId: number) => {
        dispatch(setActiveDomain(domainId));
        setSearchTerm("");
        setFilteredDomains([]);
    };

    return (
        <nav className='w-full flex items-center '>
            <div className='w-full flex h-full gap-2 justify-between items-center'>
                <div className={`flex items-center space-x-2 cursor-pointer ${isDarkMode ? "fill-white" : "fill-[#4B4C56]"}`}>
                    <div className='flex items-center justify-center'><button onClick={handleDrawerToggle}><LogoImage width={38} height={38} /></button></div>
                    <div className='hidden md:block'><LogoName width={45} height={45} /></div>
                </div>
                <div className='relative hidden lg:block w-[40%]' >
                    <input
                        type="default-search"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className={` items-center w-full max-full mx-auto font-poppins text-sm py-2 pl-10 pr-4 border ${isDarkMode ? "border-[#A7A7A7] border-opacity-30 focus:none bg-[#FFFFFF] bg-opacity-10 text-white text-opacity-60" : " placeholder-[#31C6BB] text-[#31C6BB]"} rounded-md  focus:outline-none `}
                        ref={searchInputRef}
                    />
                    <FaSearch className={`absolute left-3 top-1/2 -mt-2 ${isDarkMode ? "text-gray-400" : "text-[#31C6BB]"} `} size={16} />
                    {filteredDomains.length > 0 && (
                        <div
                            ref={suggestionsRef} className={` absolute z-50 left-0 top-full mt-1 w-full border  ${isDarkMode ? "bg-[#1B1B1D] border-[#A7A7A7] border-opacity-30 text-[#FFFFFF] text-opacity-70" : "bg-white"} rounded-md shadow-lg `}>
                            {filteredDomains.map((domain, index: number) => (
                                <div
                                    key={index}
                                    onClick={() => handleDomainClick(domain.domainId)}

                                    className={`px-4 py-2 cursor-pointer ${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-10" : "hover:bg-[#ebe9e9]"} font-poppins`}>
                                    <div className="flex items-center text-sm font-normal  ">
                                        <div className="flex items-center text-[12px] ">
                                            {"Apps"}<MdKeyboardArrowRight className="text-[#31C6BB] mx-2" />{domain.name}
                                        </div>
                                        {domain.subDomains && (
                                            <div className="flex items-center text-[12px]">
                                                <MdKeyboardArrowRight className="text-[#31C6BB] mx-2" />{domain.subDomains}
                                            </div>
                                        )}
                                    </div>
                                    <div><p className="text-[10px] text-[#aeaeae]">{domain.subDomains_Description}</p></div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* <div className="hidden lg:flex gap-2">
                    <button
                        className={`border text-sm border-transparent px-2 py-1 rounded-md font-poppins font-light ${selectedButton === "Home" ? "bg-[#31C6BB]" : `${isDarkMode ? "" : "bg-white"}`} ${isDarkMode ? "text-white" : "text-[#141A25]"} hover:border hover:border-[#31C6BB]`}
                        onClick={() => handleButtonClick("Home")} >
                        Home
                    </button>
                    <button
                        className={`border text-sm border-transparent px-2 py-1 rounded-md  font-poppins font-light ${selectedButton === "Document" ? "bg-[#31C6BB]" : `${isDarkMode ? "" : "bg-white"}`} ${isDarkMode ? "text-white" : "text-[#141A25]"} hover:border hover:border-[#31C6BB]`}
                        onClick={() => handleButtonClick("Document")} >
                        Document
                    </button>
                </div> */}

                <div className='flex items-center gap-4'>
                    <div className={` cursor-pointer ${isDarkMode ? "dark" : ""}`}>
                        <ThemeToggle isDarkMode={isDarkMode} />
                    </div>
                    {/* <div className='rounded-full cursor-pointer bg-[#31C6BB] '>
                        <BsBell className={`m-[10px] ${isDarkMode?"":"text-[#005E50]"} text-black`} size={16} />
                    </div> */}

                    <div className="h-[38px] border border-[#31C6BB] w-[38px] bg-transparent rounded-full cursor-pointer bg-black flex items-center justify-center text-white font-semibold" >

                        {userImg.IsSuccess && userImg.data
                            ? <img className="h-[38px] w-[38px] rounded-full overflow-hidden" src={userImg.data} alt="User profile" title={userInfo.userInfoIsSuccess ? userInfo.userInfoData?.displayName : ""} />
                            : userInfo.userInfoIsSuccess && userInfo.userInfoData
                                ? <div className="text-[#31C6BB] w-full h-full flex items-center justify-center rounded-full" title={userInfo.userInfoData.displayName}>{getInitials(userInfo.userInfoData?.givenName, userInfo.userInfoData?.surname)}</div>
                                : <img className="h-[38px] w-[38px] rounded-full overflow-hidden" src={avatar} alt="avatar" />
                        }

                    </div>
                </div>
            </div>
            <div className='h-2 bg-white'></div>
        </nav >
    );
};

export default Navbar;
