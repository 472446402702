import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Link } from "react-router-dom";
import "./app.css";

const MyAppCard: React.FC<{ imgSrc: string, title: string, url: string, desc: string, domainName: string }> = ({ imgSrc, title, domainName, url, desc }) => {
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const textColor = isDarkMode ? "text-white" : "text-[#0F141E]";

  const renderImage = () => {
    if (imgSrc.startsWith("<svg")) {
      return <div className={`${isDarkMode ? "svg-style-dark" : "svg-style-light"}`} dangerouslySetInnerHTML={{ __html: imgSrc }} />;
    }
    return <img src={imgSrc} alt="Domain Logo" className="w-10 h-10" />;
  };

  return (
    <Link to={url} target={url === null ? "_self" : "_blank"}>
      <div className=" w-full h-full relative transform transition-transform duration-300 hover:scale-105">
        <div className="h-56">
          <div
            className={`h-full w-full ${isDarkMode ? "!fill-white" : ""} shadow p-2  py-3 shadow-[#101010]-300/30 border ${isDarkMode ? "border-[#4B4C56] bg-[#FFFFFF] bg-opacity-10" : "border-gray-200 bg-white"} flex flex-col justify-start gap-1 rounded-md w-[220px]  `}>
            <div className="p-[3px] "> {renderImage()}</div>
            <p className={`truncate text-sm font-poppins max-h-10 font-medium text-wrap tracking-tight  overflow-y-auto no-scrollbar ${textColor}`}>{title}</p>
            <h6 className='text-[#31C6BB] font-poppins font-normal text-xs '>{domainName}</h6>
            <p className={` text-[10px] overflow-y-auto font-poppins font-light no-scrollbar ${textColor}`} title={desc}>{desc}</p>
          </div >
        </div>



      </div>

    </Link>
  );
};

export default MyAppCard;
