import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../containers/Home";
import AskMeAnything from "../containers/AskMeAnything";
import DVRAutomation from "../containers/DVRAutomation";

const Routers: React.FC = () => {
    // const role = useSelector((state: RootState) => state.matchRole);

    return (
        <Routes>
            <Route
                path="/"
                element={<Home />}
            />
            <Route
                path="/askmeanything"
                element={<AskMeAnything />}
            />
                <Route
                path="/dvrautomation"
                element={< DVRAutomation />} />
            <Route
                path="*"
                element={<Navigate to="/" />} />

        </Routes>
    );
};

export default Routers;
