/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import MailTable from "./MailTable";
import MailTablePagination from "./MailTablePagination";
import { useDispatch, useSelector } from "react-redux";
import { getEmailListStart, IGetEmailListItem } from "../../../redux/slices/EmailSlice/getEmailListSlice";
import { RootState } from "../../../redux/store";
import { getRepliedEmailListStart, IGetRepliedEmailListItem } from "../../../redux/slices/EmailSlice/getRepliedEmailListSlice";
import MailRepliedTable from "./MailRepliedTable";
import MailRepliedTablePagination from "./MailRepliedTablePagination";

type IMailRepliedScreen = {
  setIsDetailView: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailRow: React.Dispatch<React.SetStateAction<IGetRepliedEmailListItem>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
};
const MailRepliedScreen: React.FC<IMailRepliedScreen> = ({ setIsDetailView, setDetailRow, rowsPerPage, setRowsPerPage }) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const [mailData, setMailData] = useState<IGetRepliedEmailListItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const fetchEmails = (pageNo: number, pageSize: number, confidenceStatus: string, search: string) => {
    dispatch(getRepliedEmailListStart({ pageNo, pageSize, emailId: process.env.REACT_APP_MAIL_MITRA_EMAIL_ID, confidenceStatus, search }));
  };


  useEffect(() => {
    fetchEmails(currentPage, rowsPerPage, state.app.confidence_level, state.app.search);
  }, [currentPage, rowsPerPage, state.app.confidence_level, state.app.search]);

  useEffect(() => {
    if (state.getRepliedEmailList.isSuccess) {
      setMailData(state.getRepliedEmailList.response?.data || []);
      setTotalItems(state.getRepliedEmailList.response?.totalRecords || 0);  // Assume API returns total items
    }
  }, [state.getRepliedEmailList.isSuccess]);

  const handlePageChange = (newPageNo: number, newRowsPerPage: number) => {
    setCurrentPage(newPageNo);
    setRowsPerPage(newRowsPerPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  return (
    <div className="w-full mt-3 mb-[5px] h-full rounded-[10px] flex flex-col justify-between">
      <MailRepliedTable mailData={mailData} setIsDetailView={setIsDetailView} setDetailRow={setDetailRow} />
      <MailRepliedTablePagination
        mailData={mailData}
        totalItems={totalItems}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange} />
    </div>

  );
};

export default MailRepliedScreen;
