// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Define the keyframes for the animation */
@keyframes slideUpDetails {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        height: 100%; /* Adjust the height to 100% */
        opacity: 1;
    }
}

/* Apply the animation to the div */
.slide-details-div {
    height: 0; /* Start with a height of 0 */
    overflow: hidden; /* Hide overflow during animation */
    animation: slideUpDetails 0.3s linear forwards; /* Animation name, duration, easing function, and fill mode */
}
`, "",{"version":3,"sources":["webpack://./src/components/AdditionalDetails/details.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;IACI;QACI,SAAS;QACT,UAAU;IACd;IACA;QACI,YAAY,EAAE,8BAA8B;QAC5C,UAAU;IACd;AACJ;;AAEA,mCAAmC;AACnC;IACI,SAAS,EAAE,6BAA6B;IACxC,gBAAgB,EAAE,mCAAmC;IACrD,8CAA8C,EAAE,6DAA6D;AACjH","sourcesContent":["/* Define the keyframes for the animation */\n@keyframes slideUpDetails {\n    0% {\n        height: 0;\n        opacity: 0;\n    }\n    100% {\n        height: 100%; /* Adjust the height to 100% */\n        opacity: 1;\n    }\n}\n\n/* Apply the animation to the div */\n.slide-details-div {\n    height: 0; /* Start with a height of 0 */\n    overflow: hidden; /* Hide overflow during animation */\n    animation: slideUpDetails 0.3s linear forwards; /* Animation name, duration, easing function, and fill mode */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
