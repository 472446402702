import React from "react";
import { useDispatch } from "react-redux";
import { toggleDarkMode } from "../../redux/slices/themeSlice";
import { PiSun } from "react-icons/pi";
import { IoMoonOutline } from "react-icons/io5";

interface ThemeToggleProps {
    isDarkMode: boolean;
}

const ThemeToggle: React.FC<ThemeToggleProps> = ({ isDarkMode }) => {
    const dispatch = useDispatch();
    const handleToggleClick = () => {
        dispatch(toggleDarkMode());
    };

    return (
        <button
            onClick={handleToggleClick}
            className={`flex items-center justify-center w-10 h-[22px] border border-[#31C6BB] rounded-full outline-none transition duration-350 px-0.5 ${isDarkMode ? "bg-[#4B4C56]" : "bg-[#31C6BB] "}`}
        >
            {!isDarkMode ? (
                <div className='flex w-full h-full items-center justify-start '>
                    <div className='flex items-center p-[1px] justify-center'><PiSun className="text-black " size={13} /></div>
                </div>
            ) : (
                <div className='flex w-full h-full items-center justify-end '>
                    <div className='bg-[#31C6BB] p-[1px] rounded-full flex items-center justify-center'><IoMoonOutline className="text-black" size={13} /></div>
                </div>
            )
            }
        </button>
    );
};

export default ThemeToggle;
