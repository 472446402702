/* eslint-disable quotes */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from "react";
import { IGetEmailListItem } from "../../../redux/slices/EmailSlice/getEmailListSlice";
import { PiArrowFatRight } from "react-icons/pi";
import "./emailView.css";
import Avatar from "../../../assets/img/Avatar.png";
import editIcon from "../../../assets/img/message-edit.png";
import editIconLight from "../../../assets/img/light-message-edit.png";
import sendIcon from "../../../assets/img/sms-tracking.png";
import RichTextEditor, { extractPlainText } from "./RichTextEditor";
import { useDispatch, useSelector } from "react-redux";
import { postEmailReplyStart } from "../../../redux/slices/EmailSlice/postEmailReplySlice";
import { createEditor, Descendant, Element as SlateElement, Text, BaseText, BaseEditor } from "slate";
import { ReactEditor } from 'slate-react';
import { RootState } from "../../../redux/store";
import { SuccessToast } from "../../ToastifyComponent";
import moment from "moment";
import { CustomText } from "./Toolbar";
import { ConfidenceStatus, ConfidenceStatusDarkStyles, ConfidenceStatusLightStyles } from "../../../constants";

type Props = { setIsDetailView: React.Dispatch<React.SetStateAction<boolean>>; detailRow: IGetEmailListItem; };

// Custom element type
type CustomElement = {
    type: string;
    children: Descendant[];
};
declare module 'slate' {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor;
        Element: CustomElement;
        Text: CustomText;
    }
}

const initialValue: CustomElement[] = [
    {
        type: "paragraph",
        children: [{ text: "" }],
    },
];

// Updated deserialize function
const deserialize = (el: any): Descendant[] => {
    console.log("deserialize", el, el.nodeType, el.nodeName);
    if (el.nodeType === 3) {  // Text node
        // Wrap every text node in a paragraph
        return [{ type: 'paragraph', children: [{ text: el.textContent }] }];
    } else if (el.nodeType !== 1) {  // Not an element node
        return [];
    }

    const children = Array.from(el.childNodes).flatMap(deserialize);

    // If no children, add an empty text node
    if (children.length === 0) {
        children.push({ text: '' });
    }

    switch (el.nodeName) {
        case 'BODY':
            return children;
        case 'BR':
            // Wrap newline in a paragraph
            return [{ type: 'paragraph', children: [{ text: ' ' }] }];
        case 'P':
            return [{ type: 'paragraph', children } as CustomElement];
        case 'H1':
            return [{ type: 'heading-one', children } as CustomElement];
        case 'H2':
            return [{ type: 'heading-two', children } as CustomElement];
        case 'B':
        case 'STRONG':
            return children.map(child => ({ ...child, bold: true }));
        case 'I':
        case 'EM':
            return children.map(child => ({ ...child, italic: true }));
        case 'U':
            return children.map(child => ({ ...child, underline: true }));
        case 'STRIKE':
        case 'S':
            return children.map(child => ({ ...child, strikethrough: true }));
        case 'SPAN':
            const hasStyle = el.style.backgroundColor || el.style.color || el.style.padding || el.style.display;
            if (hasStyle) {
                return children.map(child => ({
                    ...child,
                    spanStyles: {
                        backgroundColor: el.style.backgroundColor,
                        color: el.style.color,
                        padding: el.style.padding,
                        display: el.style.display
                    }
                }));
            } else {
                return children;
            }
        default:
            // Default case: wrap each text in a separate paragraph
            return children.flatMap(child => [{ type: 'paragraph', children: [child] }]);
    }
};



const isCustomText = (node: BaseText): node is CustomText => {
    return 'bold' in node || 'italic' in node;
};
const serialize = (nodes: Descendant[]): string => {
    return nodes.map(n => {
        if (Text.isText(n)) {
            let text = n.text;
            if (isCustomText(n)) {
                if (n.bold) {
                    text = `<b>${text}</b>`;
                }
                if (n.italic) {
                    text = `<i>${text}</i>`;
                }
                if (n.underline) {
                    text = `<u>${text}</u>`;
                }
            }
            return text;
        }

        if (SlateElement.isElement(n) && n.type === 'paragraph') {
            return `<p>${serialize(n.children)}</p>`;
        }

        return '';
    }).join('');
};
const EmailDetailView = ({ setIsDetailView, detailRow }: Props) => {
    const dispatch = useDispatch();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const [isEditorVisible, SetIsEditorVisible] = useState(false);

    const [refresh, SetRefresh] = useState(false);
    
    const [isActionButtonVisible, setIsActionButtonVisible] = useState(detailRow?.Reply_status ?? true);
    const [value, setValue] = useState<Descendant[]>([]);
    const state = useSelector((state: RootState) => state);

    // Format the time to 'h:mmA' (e.g., 9:14AM)
    const formattedTime = moment(detailRow.ReceivedDateTime).format('h:mmA');

    // Calculate time ago using fromNow()
    const timeAgo = moment(detailRow.ReceivedDateTime).fromNow();

    const onMailSend = async () => {
        const plainText = extractPlainText(value);
        const htmlContent = serialize(value);
        await dispatch(postEmailReplyStart({
            internetMessageId: detailRow.InternetMessageId,
            replyBy: detailRow.EmailId ?? "",
            confidenceStatus: detailRow.Confidence_status ?? "",
            emailContentType: detailRow.EmailContentType,
            emailContentBody: htmlContent,
            emailAutomationId: detailRow.EmailAutomationID
        }));
        SetRefresh(true);

    };
    useEffect(() => {
        if (state.postEmailReply.isSuccess && refresh) {
            SuccessToast("Mail successfully sent");
            setIsActionButtonVisible(true);
            setIsDetailView(false);
            SetRefresh(false);
        }
    }, [state.postEmailReply.isSuccess]);

    useEffect(() => {
        if (detailRow) {
            SetIsEditorVisible(true);
            // console.log("first", detailRow.ResponseEmailContentBody);
            const doc = new DOMParser().parseFromString(detailRow.ResponseEmailContentBody.replace(/\n+/g, ' '), 'text/html');
            // console.log("Content", detailRow.ResponseEmailContentBody.replace(/\n+/g, ' '));
            // console.log("body", doc.body);
            const initialValue = deserialize(doc.body);
            // console.log("initialValue", initialValue);
            setValue(initialValue);
        }
    }, [detailRow]);
    // console.log("Value----->", value);

    return (
        <div className={`w-full mt-3 mb-[5px] h-full rounded-[10px] flex flex-col justify-between border border-opacity-10  ${isDarkMode ? "border-white" : "border-black"}`}>


            {detailRow ? (
                <div className="px-3 pt-3 w-full h-full flex flex-col gap-2">

                    <div className="flex flex-row justify-between items-center  text-[12px] 2xl:text-[16px]">
                        {/* title */}
                        <div className="flex flex-row items-center justify-start gap-4 w-[80%]">
                            <div className={`${isDarkMode ? "text-white text-opacity-75" : "text-black"}`}><span className="font-poppins font-medium text-[18px] 2xl:text-[26px] ">{detailRow.Subject}</span></div>
                            <div className={`${isDarkMode ? "text-white text-opacity-75" : "text-black"} text-opacity-40 font-extrabold`}><PiArrowFatRight /></div>
                            <div className=" font-poppins ">
                                <div
                                    className="text-center text-[11px] 2xl:text-[14px] font-medium px-2.5 py-1 rounded w-20"
                                    style={{
                                        backgroundColor: isDarkMode
                                            ? ConfidenceStatusDarkStyles[detailRow?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.bgColor
                                            : ConfidenceStatusLightStyles[detailRow?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.bgColor,
                                        color: isDarkMode
                                            ? ConfidenceStatusDarkStyles[detailRow?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.textColor
                                            : ConfidenceStatusLightStyles[detailRow?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.textColor
                                    }}
                                >
                                    {detailRow?.Confidence_status
                                        ? `${detailRow?.Confidence_status[0].toUpperCase()}${detailRow?.Confidence_status.substring(1).toLowerCase()}`
                                        : ''}
                                </div>

                            </div>
                        </div>
                        {/* Go tO list */}
                        <button onClick={() => setIsDetailView(false)} className="bg-[#18958B] p-1 border border-black border-opacity-[16%] font-poppins text-white font-normal px-2 rounded-md hover:scale-105 transition-all text-[12px] 2xl:text-[16px]">Back to List </button>
                    </div>


                    <div className={`flex flex-row items-start justify-between  ${isDarkMode ? "text-white text-opacity-75" : "text-black"} `}>
                        <div className="flex flex-row items-start justify-start gap-2 w-full h-full ">

                            <div className="">
                                <img className="w-11 bg-white bg-opacity-50 rounded-full " src={Avatar} alt="avatar" />
                            </div>
                            <div className="w-full h-full flex flex-col gap-2 justify-start ">
                                <div className=" flex flex-row justify-between items-start w-full text-[12px] 2xl:text-[16px] font-poppins">
                                    <div className="flex flex-col items-start justify-start">
                                        <div><span className={` ${isDarkMode}  font-medium text-[12px] 2xl:text-[18px] `}>{detailRow.Sender_email}</span></div>
                                        <div><span className=" text-opacity-30">to me</span></div>
                                    </div>
                                    <div> {formattedTime} ({timeAgo})</div>
                                </div>

                                {/* Body */}
                                <div

                                    className={`w-full body-container flex flex-col justify-between overflow-y-auto ${isDarkMode ? "scrollbar-dark" : "scrollbar-light"} pt-2`}>
                                    {/* mail body */}
                                    <div className={` ${isDarkMode ? "emailview-dark text-white text-opacity-75 border-white " : "emailview-light text-black border-black "} border-b border-opacity-10 py-2 w-full`}>
                                        <div className={`text-[12px] 2xl:text-[16px] font-poppins bg-transparent ${isDarkMode ? "text-white text-opacity-55" : "text-black text-opacity-60"}`}
                                            dangerouslySetInnerHTML={{ __html: detailRow.EmailContentBody }} />
                                    </div>


                                    <div className={` border border-opacity-10  ${isDarkMode ? "border-white" : "border-black"} mt-5 rounded-2xl`} >
                                        {detailRow?.Confidence_status.toLowerCase() === "high"
                                            ? <>
                                                {
                                                    detailRow?.Reply_status
                                                        ? <div className="p-2 w-full ">
                                                            <div className={`text-[12px] 2xl:text-[16px] !font-poppins bg-transparent ${isDarkMode ? "text-white text-opacity-55 emailview-dark" : "text-black text-opacity-60 emailview-light "}`}
                                                                dangerouslySetInnerHTML={{ __html: detailRow.ResponseEmailContentBody }} />
                                                        </div>
                                                        :
                                                        <>{
                                                            !isEditorVisible ?
                                                                <div className="flex flex-end">
                                                                    <div className="" onClick={() => SetIsEditorVisible(true)}>
                                                                        <div> {isDarkMode ? <img className="" src={editIcon} alt="" /> : <img className="" src={editIconLight} alt="" />}</div>
                                                                        <span className="font-poppins font-medium">Response :</span>
                                                                    </div>
                                                                </div>
                                                                : <div className="p-6 flex flex-col gap-2 text-[12px] 2xl:text-[16px] font-poppins  ">
                                                                    {/* Response */}
                                                                    <div className="flex flex-row items-center justify-start gap-1 p-1 " >
                                                                        <div> {isDarkMode ? <img className="" src={editIcon} alt="" /> : <img className="" src={editIconLight} alt="" />}</div>
                                                                        <span className="font-poppins font-medium ">Response :</span>
                                                                    </div>
                                                                    <div className={`border border-opacity-10 font-poppins ${isDarkMode ? "border-white emailview-dark" : "border-black emailview-light"} rounded-lg h-[200px] overflow-auto  ${isDarkMode ? "scrollbar-dark" : "scrollbar-light"}`}>
                                                                        <RichTextEditor value={value} setValue={setValue} />
                                                                    </div>
                                                                    {
                                                                        !isActionButtonVisible &&
                                                                        <div className=" flex flex-row justify-end gap-2 font-poppins text-[12px] 2xl:text-[16px]">
                                                                            {/* Buttons */}
                                                                            <button className="bg-[#18958B] p-1 border border-black border-opacity-[16%] font-poppins text-white font-normal px-2 rounded-md hover:scale-105 transition-all"
                                                                                onClick={() => setIsDetailView(false)}>
                                                                                <span>Cancel</span>
                                                                            </button>
                                                                            <button className="bg-[#18958B] p-1 border border-black border-opacity-[16%] font-poppins text-white font-normal px-2 rounded-md hover:scale-105 transition-all flex flex-row items-center gap-1"
                                                                                onClick={onMailSend}>
                                                                                <img className="w-4" src={sendIcon} alt="send Icon" />
                                                                                <span>{state.postEmailReply.isLoading ? "Sending" : "Send"}</span>
                                                                            </button>
                                                                        </div>
                                                                    }


                                                                </div>
                                                        }
                                                        </>

                                                }
                                            </>
                                            : <>{
                                                (!isEditorVisible) ?
                                                    <div className="flex flex-end">
                                                        <div className="" onClick={() => SetIsEditorVisible(true)}>
                                                            <div> {isDarkMode ? <img className="" src={editIcon} alt="" /> : <img className="" src={editIconLight} alt="" />}</div>
                                                            <span className="font-poppins font-medium">Response :</span>
                                                        </div>
                                                    </div>
                                                    : <div className="p-6 flex flex-col gap-2 text-[12px] 2xl:text-[16px] font-poppins  ">
                                                        {/* Response */}
                                                        <div className="flex flex-row items-center justify-start gap-1 p-1 " >
                                                            <div> {isDarkMode ? <img className="" src={editIcon} alt="" /> : <img className="" src={editIconLight} alt="" />}</div>
                                                            <span className="font-poppins font-medium ">Response :</span>
                                                        </div>
                                                        <div className={` border border-opacity-10 font-poppins ${isDarkMode ? "border-white emailview-dark" : "border-black emailview-light"} rounded-lg h-[200px] overflow-auto  ${isDarkMode ? "scrollbar-dark" : "scrollbar-light"}`}>
                                                            <RichTextEditor value={value} setValue={setValue} />
                                                        </div>
                                                        {
                                                            !isActionButtonVisible &&
                                                            <div className=" flex flex-row justify-end gap-2 font-poppins text-[12px] 2xl:text-[16px]">
                                                                {/* Buttons */}
                                                                <button className="bg-[#18958B] p-1 border border-black border-opacity-[16%] font-poppins text-white font-normal px-2 rounded-md hover:scale-105 transition-all"
                                                                    onClick={() => setIsDetailView(false)}>
                                                                    <span>Cancel</span>
                                                                </button>
                                                                <button className="bg-[#18958B] p-1 border border-black border-opacity-[16%] font-poppins text-white font-normal px-2 rounded-md hover:scale-105 transition-all flex flex-row items-center gap-1"
                                                                    onClick={onMailSend}>
                                                                    <img className="w-4" src={sendIcon} alt="send Icon" />
                                                                    <span>{state.postEmailReply.isLoading ? "Sending" : "Send"}</span>
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                            }
                                            </>

                                        }
                                    </div>


                                </div>


                            </div>


                        </div>
                    </div>

                </div>
            ) : (
                <span className={`${isDarkMode ? "text-white text-[20px] text-opacity-45" : "text-black text-opacity-50"}`}>No data available</span>
            )}

        </div>
    );
};

export default EmailDetailView;