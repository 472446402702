import { FaTwitter, FaLinkedin, FaFacebook } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Footer: React.FC = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    const footerClassName = `footer ${isDarkMode ? "bg-[#141619] text-white" : "bg-[#EFEFEF] text-white"}`;

    return (
        <div className={footerClassName}>
            <div className='border-t border-[#FFFFFF] border-opacity-10 mt-5'>
                <div className=" container px-4 md:px-12 2xl:px-28 3xl:px-0 mx-auto   py-3  flex flex-col md:flex-row justify-between items-center">
                    <p className={`font-poppins font-normal text-sm  ${isDarkMode ? "text-white" : "text-black"}`}>
                        &copy;All Copyright Reserved Tata Motors.
                    </p>
                    <div className="flex flex-col sm:flex-row items-center m-1">
                        <div className={`mr-2 text-black font-poppins font-normal text-sm ${isDarkMode ? "text-white" : ""}`}>
                            Follow Tata Motors Buzz :
                        </div>
                        <div className="flex flex-row items-center mt-1">
                            <a href="https://twitter.com/TataMotors" target="_blank" rel="noopener noreferrer" className="text-[#31C6BB] mr-2 hover:text-gray-400 p-1 border border-[#31C6BB]">
                                <FaTwitter />
                            </a>
                            <a href="https://www.linkedin.com/company/tatamotors/" target="_blank" rel="noopener noreferrer" className="text-[#31C6BB] mr-2 hover:text-gray-400 p-1 border border-[#31C6BB]">
                                <FaLinkedin />
                            </a>
                            <a href="https://www.facebook.com/TataMotors/" target="_blank" rel="noopener noreferrer" className="text-[#31C6BB] hover:text-gray-400 p-1 border border-[#31C6BB]">
                                <FaFacebook />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
