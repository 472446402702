import React, { useState } from "react";
import { FiSun } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import exitImg from "../../../assets/exitImg.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { toggleDarkMode } from "../../../redux/slices/themeSlice";
import { IoMoonOutline } from "react-icons/io5";
import { clearChat } from "../../../redux/slices/ChatSlice";
import { useNavigate } from "react-router-dom";
import { deleteUserChatsRequest } from "../../../redux/slices/deleteUserChatsSlice";
import ConfirmationCard from "../../Cards/ConfirmationCard";


export const ChatFooter: React.FC = () => {

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // State to manage confirmation dialog visibility

    const { userData, success } = useSelector((state: RootState) => state.createUser);
    const deleteChatsState = useSelector((state: RootState) => state.deleteUserChats);

    const userId = success && userData ? userData?.ID : null;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleToggleClick = () => {
        dispatch(toggleDarkMode());
    };

    const handleClearAllClick = () => {
        setIsConfirmationOpen(true); // Open the confirmation dialog
    };

    const handleDeleteConfirmed = () => {
        dispatch(deleteUserChatsRequest({ userId }));
    };

    const handleCancel = () => {
        setIsConfirmationOpen(false); // Close the confirmation dialog
    };
    const handleExitClick = () => {
        navigate("/"); // Navigate to the home URL
    };

    React.useEffect(() => {
        if (deleteChatsState.isSuccess) {
            setIsConfirmationOpen(false); // Close the confirmation dialog
            dispatch(clearChat());
        }
    }, [deleteChatsState.isSuccess]);

    return (
        <div className={`w-full mx-auto border-t border-opacity-10 text-[12px] 2xl:text-[14px] ${isDarkMode ? "border-white" : "border-black"} `}>
            <div className='flex flex-col justify-start mt-2 '>
                <button className={`rounded-md cursor-pointer  pl-2 ${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-10" : "hover:bg-[#F7F8FA] "}`}
                    onClick={handleClearAllClick}>
                    <div className='flex flex-row items-center gap-2 my-2 '>
                        <MdDeleteOutline className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"} w-4 h-4`} />
                        <p className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"}  font-poppins   text-nowrap`}>Clear all conversations</p>
                    </div>
                </button>
                <button className={`rounded-md cursor-pointer  pl-2 ${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-10" : "hover:bg-[#F7F8FA] "}`}
                    onClick={handleToggleClick}>
                    <div className='flex flex-row items-center gap-2 my-2 '>
                        {
                            isDarkMode
                                ? <FiSun className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"} w-4 h-4`} />
                                : <IoMoonOutline className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"} w-4 h-4`} />
                        }
                        <p className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"}  font-poppins `}>{`Switch ${isDarkMode ? "Light" : "Dark"} Mode`}</p>
                    </div>
                </button>
                <button className={`rounded-md cursor-pointer  pl-2 ${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-10" : "hover:bg-[#F7F8FA] "}`}
                    onClick={handleExitClick}>
                    <div className='flex flex-row items-center gap-2 my-2'>
                        <img src={exitImg} alt='exit-img' className='w-4 h-4' />
                        <p className=' text-[#FF5555]  font-poppins '>Exit</p>
                    </div>
                </button>
            </div>

            {isConfirmationOpen && (
                <div className="fixed inset-0 flex items-center z-30 justify-center bg-black bg-opacity-50">
                    <ConfirmationCard
                        onDelete={handleDeleteConfirmed}
                        onCancel={handleCancel}
                    />
                </div>
            )}

        </div >
    );
};
