import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IUserInfoResponse, userInfoRequestFailure, userInfoRequestStart, userInfoRequestSuccess } from "../../slices/userInfoByAzureADSlice";

import { httpGet } from "../../../services";
import { userImgRequestFailure, userImgRequestStart, userImgRequestSuccess } from "../../slices/userImgByAzureADSlice";
import { Buffer } from "buffer";

function* fetchUserInfoSaga(action: PayloadAction<{ email: string, token: string }>): Generator<any, void, IUserInfoResponse> {
    try {
        const { email, token } = action.payload;

        if (!email || !token) {
            throw new Error("Email or SSO token is missing");
        }
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        const response: IUserInfoResponse = yield httpGet(`https://graph.microsoft.com/v1.0/users/${email}?$select=employeeId,mailNickname,mail,userPrincipalName,displayName,givenName,surname`, config);
        yield put(userInfoRequestSuccess(response));
    } catch (error: any) {
        yield put(userInfoRequestFailure(error.message));
    }
}

function* fetchUserImageSaga(action: PayloadAction<{ token: string }>): Generator<any> {
    try {
        const { token } = action.payload;

        if (!token) {
            throw new Error("Email or SSO token is missing");
        }
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "image/jpeg"
            },
            responseType: "arraybuffer"
        };
        const res: any = yield httpGet("https://graph.microsoft.com/v1.0/me/photo/$value", config);
        const ImgBase64 = Buffer.from(res, "binary").toString("base64");
        const base64URL = `data:image/jpeg;base64,${ImgBase64}`;
        yield put(userImgRequestSuccess(base64URL));

    } catch (error: any) {
        yield put(userImgRequestFailure(error.message));
    }
}


export default function* userInfoSaga() {
    yield takeLatest(userInfoRequestStart.type, fetchUserInfoSaga);
    yield takeLatest(userImgRequestStart.type, fetchUserImageSaga);
}
