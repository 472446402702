import React, { useState, useEffect } from "react";
import "./typing.css";


const WaveAnimation = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((index + 1) % 3);
    }, 800);
   
    return () => {
      clearTimeout(timer);
    };
   
  }, [index]);
  return (
    <div className="text-center text-gray-800  px-2 flex flex-row justify-start">
        <div className="flex justify-center items-center">
            <div className={`dot ${index === 0 ? "animate-dot" : ""}`}></div>
            <div className={`dot ${index === 1 ? "animate-dot" : ""}`}></div>
            <div className={`dot ${index === 2 ? "animate-dot" : ""}`}></div>
        </div>
    </div>
);
};

export default WaveAnimation;
