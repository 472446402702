import React, { FunctionComponent, PureComponent } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Label } from "recharts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {
    activityID: any;
    data: any;
};

const LineChartComponent: React.FC<Props> = ({ data }) => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const LIGHT_COLORS = ["#60D8CF", "#FFBB28", "#FF8042", "#e420c8", "#77cc6f", "#aa84d8", "#a4de6c", "#00abFE", "#82ca9d", "#ffc658", "#8dd1e1", "#82ca9d", "#40558d", "#77cc6f", "#e420c8", "#d5834d", "#6b7b14", "#714505", "#b8b67c", "#744bb1", "#4c4c86", "#3ac71f"];
    const DARK_COLORS = [
        "rgba(96,216,207,0.8)", "rgba(255,187,40,0.8)",
        "rgba(255,128,66,0.8)", "rgba(228,32,200,0.8)",
        "rgba(119,204,111,0.8)", "rgba(170,132,216,0.8)",
        "rgba(164,222,108,0.8)", "rgba(0,171,254,0.8)",
        "rgba(130,202,157,0.8)", "rgba(255,198,88,0.8)",
        "rgba(141,209,225,0.8)", "rgba(130,202,157,0.8)",
        "rgba(64,85,141,0.8)", "rgba(119,204,111,0.8)",
        "rgba(228,32,200,0.8)", "rgba(213,131,77,0.8)",
        "rgba(107,123,20,0.8)", "rgba(113,69,5,0.8)",
        "rgba(184,182,124,0.8)", "rgba(116,75,177,0.8)",
        "rgba(76,76,134,0.8)", "rgba(58,199,31,0.8)"
    ];
    const COLORS = ["#60D8CF", "#D5332F", "#EA6A45", "#F8A061", "#FECF87", "#FCF1B3", "#F1F6D8", "#CBE8EE", "#9CCAE1", "#6DA2CA", "#496FAE", "#5D49AE", "#8249AE", "#AC49AE", "#AE4979", "#E06E6E"];

    // Extract relevant data from the original JSON
    const xData = data.x_axis_data;
    const yDataList = data.y_data_list;
    // Get dynamic line labels
    const lineLabels = yDataList.map((item: { y_line_label: any; }) => item.y_line_label);

    // Transform data into the desired format with dynamic line labels
    const transformedData = xData.map((date: string, index: string | number) => {
        const entry: any = {
            name: date,
        };

        lineLabels.forEach((label: string, i: string | number) => {
            entry[label] = yDataList[i].y_value[index];
        });
        return entry;
    });


    const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
        const { x, y, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <foreignObject x={-30} y={0} width={70} height={35}>
                    <div style={{ textAlign: "center", whiteSpace: "normal", wordWrap: "break-word", fontSize: "8px" }} title={payload.value}>
                        {payload.value}
                    </div>
                </foreignObject>
            </g>
        );
    };



    const formatYAxis = (tickItem: number): string => {
        const absTickItem = Math.abs(tickItem);
        let formattedValue = "";

        if (absTickItem >= 1000000000) {
            formattedValue = `${(absTickItem / 1000000000).toFixed(1)}B`;
        } else if (absTickItem >= 1000000) {
            formattedValue = `${(absTickItem / 1000000).toFixed(1)}M`;
        } else {
            formattedValue = absTickItem.toString();
        }

        return tickItem < 0 ? `-${formattedValue}` : formattedValue;
    };
    return (

        <div className={`font-poppins ${isDarkMode ? "bg-[#1F1F1F] scrollbar-dark " : "bg-white scrollbar-light"} rounded-md  mt-2  p-2 text-[#282828] overflow-x-auto overflow-y-hidden md:overflow-hidden  w-full h-full `} >
            <h1 className={`font-semibold my-1 text-[10px] md:text-[14px]  ${isDarkMode ? "text-white" : ""}`} >{data?.graph_title}</h1>
            <ResponsiveContainer className="p-2 pb-0  text-[10px] text-[#aeaeae] " minWidth={"500px"} minHeight={"400px"} width="100%" height="100%"  >
                <LineChart className=' pr-4 w-full h-full' data={transformedData} margin={{ top: 10, right: 10, left: 10, bottom: 20 }} >
                    <CartesianGrid className='opacity-20' strokeDasharray="3 3" stroke="#aeaeae" />

                    <XAxis dataKey="name" height={30} padding={{ left: 15, right: 25 }} minTickGap={20} interval={"equidistantPreserveStart"}>
                        <Label
                            value={data.x_label ?? ""}
                            position="insideBottom"
                            offset={-15}
                            fontWeight={500} />
                    </XAxis>
                    <YAxis padding={{ top: 15, bottom: 15 }} tickFormatter={formatYAxis}>
                        <Label
                            value={data.y_label ?? ""}
                            angle={-90}
                            position="insideLeft"
                            style={{ textAnchor: "middle" }}
                            fontWeight={500}
                            offset={0}
                        />
                    </YAxis>

                    <Tooltip
                        wrapperClassName="p-10 bg-white z-10000 max-h-[300px]  overflow-auto"
                        contentStyle={{ zIndex: 10000, maxHeight:"300px", overflow:"auto" }}
                        defaultIndex={10000}
                    />
                    <Legend verticalAlign="top" height={80} />

                    {lineLabels.map((label: string, index: number) => {
                        return <Line
                            key={label + index}
                            type="monotone"
                            dataKey={label}
                            stroke={isDarkMode ? DARK_COLORS[index] : LIGHT_COLORS[index]}

                            strokeWidth={1.5}
                            activeDot={{ r: 2 }}
                        >
                        </Line>;
                    })}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LineChartComponent;