import React from "react";
import { MdAdd } from "react-icons/md";
import { ProfileHeader } from "./ProfileHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FiSearch } from "react-icons/fi";
import { startNewChat } from "../../../redux/slices/ChatSlice";


const ChatHeader: React.FC<{ setSearchInput: (value: string) => void }> = ({ setSearchInput }) => {

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const dispatch = useDispatch();

    const handleNewChat = () => {
        dispatch(startNewChat());
    };
    return (
        <div className='w-full'>
            <div className='w-full h-12 mb-1'>
                <ProfileHeader />
            </div>
            <div className={`relative w-full h-10 border ${isDarkMode ? "bg-[#1B1B1D]  border-[#4B5268] shadow shadow-[#19213D]-300/50" : "bg-white border-[#E2E9FF] shadow shadow-gray-300/50"} overflow-hidden rounded-md`}>
                <div className='absolute h-full inset-y-0 left-[10px] flex items-center pointer-events-none'>
                    <FiSearch className={`w-4 h-4 ${isDarkMode ? "text-[#31C6BB]" : "text-[#666F8D]"}`} />
                </div>
                <div className='flex items-center w-full h-full'>
                    <input
                        className={`w-full text-[12px] font-poppins ${isDarkMode ? "text-[#31C6BB] placeholder-[#31C6BB] bg-[#1B1B1D]" : "text-[#666F8D] placeholder-[#666F8D]"}  pl-8 focus:outline-none focus:border-[#31C6BB]  `}
                        type='default-search'
                        placeholder='Search for chats...'
                        onChange={(e) => setSearchInput(e.target.value)}
                    /></div>
            </div>
            <div className='mt-3'>
                <button
                    className={`w-full rounded-md flex flex-row gap-1 px-1 py-[7px] 2xl:px-4 2xl:py-2 items-center shadow shadow-[#000000]-[30%] bg-[#31C6BB]
                 ${isDarkMode ? "text-[#1B1B1D] border border-[#31C6BB] hover:bg-[#1B1B1D] hover:border hover:border-[#31C6BB]" : "text-[#2A4745] hover:bg-[#F7F8FA] "}  hover:text-[#31C6BB]  hover:border-[#31C6BB] `}
                    onClick={handleNewChat}
                >
                    <div className='ml-1 flex items-center justify-end'><MdAdd className='w-5 h-5' /></div>
                    <div className='m-1 flex items-center justify-start'>
                        <p className='font-poppins font-medium text-xs text-nowrap'>Start a new chat</p>
                    </div>
                </button>
            </div>
        </div>

    );
};
export default ChatHeader;