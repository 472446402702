import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedMessageState {
    selectedMessageId: string | null;
}

const initialState: SelectedMessageState = {
    selectedMessageId: null,
};

const selectedMessageSlice = createSlice({
    name: "selectedMessage",

    initialState,

    reducers: {
        setSelectedMessageId(state, action: PayloadAction<string | null>) {
            state.selectedMessageId = action.payload;
        },
    },
});

export const { setSelectedMessageId } = selectedMessageSlice.actions;
export default selectedMessageSlice.reducer;
