import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/AuthConfig";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./redux/store";
import UserService from "./services/keyCloak/UserService";


if (UserService.isLoggedIn()) {
  const cb = () => {
    let config;
    config.headers.Authorization = `Bearer ${UserService.getToken()}`;
    Promise.resolve(config);
  };
  UserService.updateToken(cb);
}


const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

  root.render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  );



