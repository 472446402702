import { put, takeLatest } from "redux-saga/effects";
import { httpPost } from "../../../services";
import { PayloadAction } from "@reduxjs/toolkit";
import { IMatchRoleApiResponse, matchRoleRequestFailure, matchRoleRequestStart, matchRoleRequestSuccess } from "../../slices/matchRoleSlice";

interface IFetchMatchRoles {
    success: boolean;
    matchedRoles: IMatchRoleApiResponse[];
}


function* fetchMatchedRoles(action: PayloadAction<{ domainId: string }>): Generator<any, void, IFetchMatchRoles> {
    try {
        const response: IFetchMatchRoles = yield httpPost(`${process.env.REACT_APP_API_URL}/api/v1/role/match-role`, {
            domainId: action.payload.domainId,
        });
        if (response.success) {
            yield put(matchRoleRequestSuccess(response.matchedRoles));
        } else {
            yield put(matchRoleRequestFailure("Failed to fetch matched roles"));
        }
    } catch (error: any) {
        yield put(matchRoleRequestFailure(error.message));
    }
}

export default function* watchFetchRoles() {
    yield takeLatest(matchRoleRequestStart.type, fetchMatchedRoles);
}
