import { put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { httpGet } from "../../../services";
import { fetchBingArticleFailure, fetchBingArticleRequest, fetchBingArticleSuccess } from "../../slices/bingApiSlice";



function* fetchBingArcticleSaga(action: PayloadAction<{ query: string }>): Generator<any> {
    try {
        const { query } = action.payload;
        const response = yield httpGet(`${process.env.REACT_APP_API_URL}/api/v1/search?query=Article intelligence top recent news`);
        yield put(fetchBingArticleSuccess(response));

    } catch (error: any) {
        yield put(fetchBingArticleFailure(error.message));
    }
}

export default function* BingArticleSaga() {
    yield takeLatest(fetchBingArticleRequest.type, fetchBingArcticleSaga);
}
