import React, { useState, useRef } from "react";
import "../MainChatSection/markdown.css"; // Assuming you have some basic styles
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "./details.css";
import { MdOutlineFileDownload } from "react-icons/md";
import { httpGet, httpPost } from "../../services";
interface AdditionalDetailsProps {
    url: string;
}
 
const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({ url }) => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
 
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error state for invalid link
 
    // function checkIfUrlExpired(timestamp: string, url: string) {
    //     const inputTime = new Date(timestamp);
    //     const currentTime = new Date();
 
    //     // Add 60 minutes (1 hour) to the input time
    //     const expiryTime = new Date(inputTime.getTime() + 60 * 60 * 1000);
 
    //     if (expiryTime > currentTime) {
    //         // console.log("URL is still valid.",url);
    //         window.open(url, "_blank");
    //     } else {
    //         // console.log("URL is expired.");
    //         setErrorMessage("* The link for Additional details is expired or invalid.");
 
    //     }
    // }
    const checkLinkValidity = async (url: string) => {
        try {
            const response = await httpPost(`${process.env.REACT_APP_API_URL}/api/v1/checkLink`, { url: url });
            console.log("res", response);
            if (response.valid) {
                window.open(url, "_blank");
            } else {
                setErrorMessage("* The link is expired or invalid.");
            }
        } catch (error) {
            setErrorMessage("* The link is expired or invalid.");
        }
    };
    const handleButtonClick = (url: string) => {
        setErrorMessage(null);
        // checkIfUrlExpired(time, url);
        checkLinkValidity(url);
    };
 
    return (
        <div className="h-full w-full my-3 flex flex-col gap-2">
            <div className={` flex flex-row gap-1 w-36 items-center justify-center  border border-opacity-50 p-1 transition-transform duration-300 ease-in-out hover:scale-105 shadow-sm rounded-md
                ${isDarkMode ? "border-[#aeaeae]" : "border-[#777676]"} font-poppins text-[10px] 2xl:text-[12px]`} >
                <button
                    onClick={() => handleButtonClick(url)} // Call the handler on click
                    className="text-[#31C6BB] font-poppins text-[12px] 2xl:text-[12px]"
                >
                    Additional Details
                </button>
                <MdOutlineFileDownload className="w-3 h-3  text-[#31C6BB]  " />
            </div>
            {/* Display error message if link is expired or invalid */}
            {errorMessage && (
                <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
            )}
 
        </div>
    );
};
 
export default AdditionalDetails;
 