import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { getInitials } from "../../../../App";
import avatar from "../../../../assets/avatar.png";
import { AllMailIconSVG } from "../../../../assets";
import { ReactComponent as RepliedMailIcon } from "../../../../assets/mailMitraIcons/repliedMail.svg";
import { useEffect, useState } from "react";
import { setActiveMenu } from "../../../../redux/slices/appSlice";

const LeftHeader: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedButton, setSelectedButton] = useState("allMail"); // Track the selected button

    const userImg = useSelector((state: RootState) => state.userImg);
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);



    const handlemailType = (buttonName) => {
        setSelectedButton(buttonName);

    };

    useEffect(() => { dispatch(setActiveMenu(selectedButton)); }, [selectedButton]);
    return (
        <div className="w-full p-1 flex items-center justify-center">
            <div className={`w-[97%] border-b border-opacity-10 ${isDarkMode ? "border-white" : "border-black"} flex flex-col gap-1`}>
                {/* profile header */}
                <div className={"w-full pb-2 flex flex-row justify-between items-center "}>
                    <div className='w-full h-full flex flex-row items-center justify-start gap-2'>
                        <div className="h-full  bg-transparent  cursor-pointer flex items-center justify-start text-white font-semibold">
                            {userImg.IsSuccess && userImg.data
                                ? <img className="h-[38px] w-[38px] border border-[#31C6BB] rounded-full overflow-hidden" src={userImg.data} alt="User profile" title={userInfo.userInfoIsSuccess ? userInfo.userInfoData?.displayName : ""} />
                                : userInfo.userInfoIsSuccess && userInfo.userInfoData
                                    ? <div className="text-[#31C6BB]   border border-[#31C6BB] w-[38px] h-[38px] flex items-center justify-center rounded-full" title={userInfo.userInfoData.displayName}>{getInitials(userInfo.userInfoData?.givenName, userInfo.userInfoData?.surname)}</div>
                                    : <img className="h-[38px] w-[38px]  border border-[#31C6BB]  rounded-full overflow-hidden" src={avatar} alt="avatar" />
                            }

                        </div>
                        <p className={`w-[80%] text-[11px] 2xl:text-[13px] ${isDarkMode ? "text-white text-opacity-80" : "text-[#666F8D]"} font-poppins font-medium inline break-words`}>{userInfo.userInfoIsSuccess && userInfo.userInfoData ? userInfo.userInfoData.displayName : ""}</p>
                    </div>
                </div>


                {/* Mail Types section */}
                <div className="w-full flex items-center justify-center " >
                    <div className='w-full pb-1  flex flex-col justify-center  text-[12px] 2xl:text-[16px] gap-1'>
                        <button
                            onClick={() => handlemailType("allMail")}
                            className={`rounded-md cursor-pointer 2xl:p-1 
                                ${selectedButton === "allMail"
                                    ? `${isDarkMode ? "bg-[#FFFFFF] bg-opacity-10 shadow-sm rounded-lg" : "bg-white shadow-sm rounded-lg"}`
                                    : `${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-5" : "hover:bg-[#FFFFFF] hover:bg-opacity-80"}`}
                           
                                `}>

                            <div className='flex flex-row px-[10px]  items-center gap-2 my-2 '>
                                <div className={`${isDarkMode ? "fill-white" : "fill-gray-500"}`}>
                                    <AllMailIconSVG height={20} width={20} />
                                </div>
                                <span className={` ${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"}  font-poppins `}>All Mails</span>
                            </div>
                        </button>
                        <button
                            onClick={() => handlemailType("repliedMail")}
                            className={`rounded-md cursor-pointer 2xl:p-1 
                                ${selectedButton === "repliedMail"
                                    ? `${isDarkMode ? "bg-[#FFFFFF] bg-opacity-10 shadow-sm rounded-lg" : "bg-white shadow-sm rounded-lg"}`
                                    : `${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-5" : "hover:bg-[#FFFFFF] hover:bg-opacity-80"}`}
                           
                           `}>

                            <div className='flex flex-row px-[10px]   items-center gap-2 my-2 '>
                                <div className={`${isDarkMode ? "fill-white" : "fill-gray-500"}`}>
                                    <RepliedMailIcon height={20} width={20} />
                                </div>
                                <span className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"}  font-poppins `}>Replied Mail</span>
                            </div>
                        </button>
                        {/* <button
                            onClick={() => handlemailType("repeatedMail")}
                            className={`rounded-md cursor-pointer 2xl:p-1  
                                ${selectedButton === "repeatedMail"
                                    ? `${isDarkMode ? "bg-[#FFFFFF] bg-opacity-10 shadow-sm rounded-lg" : "bg-white shadow-sm rounded-lg"}`
                                    : `${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-5" : "hover:bg-[#FFFFFF] hover:bg-opacity-80"}`}
                           
                        `}>
                            <div className='flex flex-row px-[10px]  items-center gap-2 my-2 '>
                                <div className={`${isDarkMode ? "fill-white" : "fill-gray-500"}`}>
                                    <RepeatedMailIconSVG height={20} width={20} />

                                </div>
                                <p className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"}  font-poppins `}>Repeated Mail</p>
                            </div>
                        </button> */}

                    </div>
                </div>

            </div>
        </div>
    );
};

export default LeftHeader;
