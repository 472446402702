import { useEffect, useRef, useState } from "react";
import ArticleCard from "./ArticleCard";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchBingArticleRequest } from "../../redux/slices/bingApiSlice";
import { RootState } from "../../redux/store";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NoDataCard from "../Cards/NoDataCard";

const ArticleList: React.FC = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const skeletonBaseColor = isDarkMode ? "#2c2c2c" : "#e0e0e0";
    const skeletonHighlightColor = isDarkMode ? "#3a3a3a" : "#f0f0f0";
    const carouselRef = useRef<HTMLDivElement>(null);
    const bingSearch = useSelector((state: RootState) => state.bingSearch);
    const articles = bingSearch.bingIsSuccess && bingSearch.bingData?.searchResults?.length ? bingSearch.bingData.searchResults : [];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchBingArticleRequest("Top News of Article intelligence"));
    }, []);


    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -200, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 200, behavior: "smooth" });
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (carouselRef.current) {
                if (carouselRef.current.scrollLeft + carouselRef.current.clientWidth >= carouselRef.current.scrollWidth - 1) {
                    carouselRef.current.scrollTo({ left: 0, behavior: "smooth" });
                }
                else {
                    carouselRef.current.scrollBy({ left: 200, behavior: "smooth" });
                }
            }
        }, 3000);

        return () => clearInterval(interval);
    }, []);



    return (

        <div className='flex justify-center items-center flex-col w-full '>
            <div className='container px-4 md:px-12 2xl:px-28 3xl:px-0 mx-auto'>
                <div className=' flex items-center justify-between my-4 '>
                    <div className=' flex flex-col flex-start '>
                        <h1 className='text-[#31C6BB] font-poppins font-semibold text-sm sm:text-lg'>Popular News on AI</h1>
                        <h5 className='text-gray-400  font-poppins font-normal text-xs sm:text-sm'>Here is what we have been up to recently.</h5>
                    </div>
                    {/* <button className='text-[#31C6BB] border border-[#31C6BB] cursor-pointer p-1 md:px-6 rounded-md font-poppins font-normal text-sm flex flex-row text-nowrap justify-between items-center'>
                        View all
                        <FaArrowRight className='ml-2' />
                    </button> */}
                </div>
            </div>
            <div className='w-[98%] mx-auto flex items-center justify-center relative'>
                {bingSearch.bingIsSuccess && articles.length &&
                    <button
                        className={`hidden sm:block absolute left-2 z-10 ${isDarkMode ? "bg-[#1B1B1D] text-white" : "bg-white text-[#1B1B1D]"} p-2 rounded-full shadow`}
                        onClick={scrollLeft}
                    >
                        <MdOutlineKeyboardArrowLeft size={24} />
                    </button>
                }
                {
                    bingSearch.bingIsSuccess && articles
                        ? (
                            articles.length ?
                                <div className="flex flex-nowrap overflow-x-auto gap-5 no-scrollbar " ref={carouselRef}>
                                    {
                                        articles.length && articles?.map((article: any, index: number) => (
                                            <div className="min-w-56" key={index}>
                                                <ArticleCard
                                                    title={article.name}
                                                    description={article.snippet}
                                                    image={article.openGraphImage?.contentUrl}
                                                    url={article?.url}
                                                    index={index}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                                : <NoDataCard message="No Articles Found" />
                        )
                        : bingSearch.bingIsError
                            ? <NoDataCard message="No Articles Found" />
                            : <div className=" w-full flex flex-nowrap overflow-x-auto gap-5 no-scrollbar">
                                {Array.from({ length: 10 }).map((_, index) => (
                                    <div key={index} >
                                        <Skeleton
                                            height={220}
                                            width={220}
                                            baseColor={skeletonBaseColor}
                                            highlightColor={skeletonHighlightColor}
                                        />
                                    </div>
                                ))}
                            </div>
                }
                {bingSearch.bingIsSuccess && articles.length && <button
                    className={`hidden sm:block absolute right-2 z-10 ${isDarkMode ? "bg-[#1B1B1D] text-white" : "bg-white text-[#1B1B1D]"} p-2 rounded-full shadow `}
                    onClick={scrollRight}
                >
                    <MdOutlineKeyboardArrowRight size={24} />
                </button>}
            </div>
        </div>
    );
};

export default ArticleList;