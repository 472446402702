import React, { useEffect, useState } from "react";
import { activeDrawer } from "../../redux/slices/DrawerSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { LogoImage, LogoName } from "../../assets";
import { RxCross2 } from "react-icons/rx";
import { RiMessage3Line } from "react-icons/ri";
import { ChatFooter } from "../PrevChatsSection/ChatFooter/ChatFooter";
import { TiMessages } from "react-icons/ti";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { FiMessageSquare } from "react-icons/fi";
import { setSelectedMessageId } from "../../redux/slices/selectedMessageSlice";
import { selectChat } from "../../redux/slices/ChatSlice";


const SideDrawer: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedComponent, setSelectedComponent] = useState<string | null>(null);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const selectedThreadId = useSelector((state: RootState) => state.chat.selectedThreadId);
    const threads = useSelector((state: RootState) => state.chat.threads);
    const selectedChat = threads.find(thread => thread.threadId === selectedThreadId);

    // const handleLogClick = (messageId: string) => {
    //     dispatch(setSelectedMessageId(messageId));
    // };
    // const handleChatSelect = (chatId: string) => {
    //     dispatch(selectChat(chatId));
    // };

    const handleDrawerToggle = () => {
        dispatch(activeDrawer());
    };
    const handleComponentRender = (component: string) => {
        setSelectedComponent((prevComponent) => (prevComponent === component ? null : component));
    };
    return (
        <div className={"w-full h-full relative"}>
            <div className='flex justify-between items-center p-4'>
                <div className={`flex items-center space-x-2 cursor-pointer ${isDarkMode ? "fill-white" : "fill-black"}`}>
                    <div><LogoImage width={38} height={38} /></div>
                    <div className='md:hidden'><LogoName width={45} height={45} /></div>
                </div>
                <div>
                    <button onClick={handleDrawerToggle}>
                        <div className='border-2 border-[#31C6BB] rounded-[7px]'>
                            <RxCross2 className='text-[#31C6BB] w-4 h-4' />
                        </div>
                    </button>
                </div>
            </div>
            <div className='w-[90%] mx-auto mt-4'>
                <h1 className={`font-poppins font-semibold text-normal border-b border-[#000000] border-opacity-10 ${isDarkMode ? "text-[#666666]" : "text-[#292D32]"}`}>Menu</h1>
                <div className='flex flex-col justify-start my-2 gap-2'>
                    <div
                        onClick={() => threads.length > 0 && handleComponentRender("chatLogs")}
                        className={`cursor-pointer flex items-center justify-between mt-3 pl-2 py-1 ${isDarkMode ? "text-white hover:bg-[#FFFFFF] hover:bg-opacity-10" : "text-[#292D32] hover:bg-[#F7F8FA]"} rounded-md`}
                    >
                        <div className='flex items-center'>
                            <TiMessages className='m-1' />
                            <h3 className={`font-poppins font-normal text-[12px] ${isDarkMode ? "text-white" : "text-[#292D32]"}`}>Chat Logs</h3>
                        </div>
                        {selectedComponent === "chatLogs" ? <IoIosArrowForward className='m-1' /> : <IoIosArrowDown className='m-1' />}
                    </div>
                    {/* {selectedComponent === 'chatLogs' && (
                        <div className={` w-[95%]  ml-auto overflow-y-auto ${isDarkMode ? ' scrollbar-dark ' : ' scrollbar-light'}`}>
                            {chats?.map((chat, index) => (
                                <div
                                    key={chat.id}
                                    className={`p-1 border text-[10px] 2xl:text-[14px] block ${isDarkMode ? 'border border-[#FFFFFF] border-opacity-10 bg-[#1B1B1D]' : 'bg-[#F7F8FA] bg-opacity-60'} flex flex-row items-center justify-start my-1 rounded-md cursor-pointer
                                    ${isDarkMode ? 'hover:bg-[#FFFFFF] hover:bg-opacity-10' : 'hover:bg-opacity-100 '} chat-item`}
                                    onClick={() => handleChatSelect(chat.id)}>
                                    <div className='ml-1 flex items-center justify-end'>
                                        <FiMessageSquare className={`${isDarkMode ? 'text-[#E5E5E5]' : 'text-[#666666]'} w-5 h-5`} />
                                    </div>
                                    <div className=' truncate m-1 flex items-center justify-start px-1 w-full'>
                                        <p className={`truncate ${isDarkMode ? 'text-[#E5E5E5]' : 'text-[#666666]'} text-[12px] 2xl:text-[14px]  font-poppins font-normal`} title={chat.title}>
                                            {chat.title}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )} */}
                    <div
                        onClick={() => (selectedChat && selectedChat?.activities?.length > 0) && handleComponentRender("conversionLogs")}
                        className={`cursor-pointer flex items-center justify-between pl-2 py-1 ${isDarkMode ? "text-white hover:bg-[#FFFFFF] hover:bg-opacity-10" : "text-[#292D32] hover:bg-[#F7F8FA]"} rounded-md`}
                    >
                        <div className='flex items-center'>
                            <RiMessage3Line className='m-1' />
                            <h3 className={`font-poppins font-normal text-[12px] ${isDarkMode ? "text-white" : "text-[#292D32]"}`}>Conversion Logs</h3>
                        </div>
                        {selectedComponent === "conversionLogs" ? <IoIosArrowForward className='m-1' /> : <IoIosArrowDown className='m-1' />}
                    </div>
                    {/* {selectedComponent === 'conversionLogs' && (
                        <div className={`w-[95%] ml-auto overflow-y-auto ${isDarkMode ? ' scrollbar-dark ' : ' scrollbar-light'}`}>
                            {selectedChat && selectedChat.activities
                                .filter(activity => activity.from.id === 'TML-Neural')
                                .map((userActivity, index) => (
                                    <div
                                        key={userActivity.id as string + index}
                                        onClick={() => handleLogClick(userActivity.id as string)}
                                        className={`p-1 border text-[10px] 2xl:text-[14px]  py-[10px] block ${isDarkMode ? 'border border-[#FFFFFF] border-opacity-10 bg-[#1B1B1D]' : 'bg-[#F7F8FA] bg-opacity-60'} flex flex-row items-center justify-start my-1 rounded-md cursor-pointer
                             ${isDarkMode ? 'hover:bg-[#FFFFFF] hover:bg-opacity-10' : 'hover:bg-opacity-100 '} chat-item`} >
                                        <p className='truncate text-gray-500  font-poppins font-normal overflow-x-hidden text-nowrap mx-2 ' title={userActivity?.text}>
                                            <span className={`font-medium  ${isDarkMode ? 'text-white' : 'text-[#666F8D]'} `}>Question : </span>
                                            {userActivity?.text}
                                        </p>
                                    </div>
                                ))}
                        </div>
                    )} */}
                </div>
            </div>
            <div className='w-full absolute bottom-0 p-3'>
                <ChatFooter />
            </div>
        </div>
    );
};

export default SideDrawer;

