import React, { useRef, useEffect, useState } from "react";
import { FiMessageSquare } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectChat } from "../../../redux/slices/ChatSlice";
import "./chatListing.css"; // Import your CSS file for styling 
import NoDataCard from "../../Cards/NoDataCard";
import Skeleton from "react-loading-skeleton";

export const ChatListing: React.FC<{ searchInput: string }> = ({ searchInput }) => {

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const { isLoading} = useSelector((state: RootState) => state.userChats);
    const threads = useSelector((state: RootState) => state.chat.threads);
    const skeletonBaseColor = isDarkMode ? "#2c2c2c" : "#e0e0e0";
    const skeletonHighlightColor = isDarkMode ? "#3a3a3a" : "#f0f0f0";
    const selectedThreadId = useSelector((state: RootState) => state.chat.selectedThreadId);
    const dispatch = useDispatch();
    const chatsContainerRef = useRef<HTMLDivElement>(null);

    const [animate, setAnimate] = useState(false); // State to trigger animation

    const handleChatSelect = (chatId: string) => {
        dispatch(selectChat(chatId));
    };

    // Filter chats based on search input
    const filteredChats = threads.filter(thread =>
        thread.threadTitle?.toLowerCase().includes(searchInput.toLowerCase())
    );

    useEffect(() => {
        // Trigger animation when filteredChats length changes
        setAnimate(true);
        setTimeout(() => setAnimate(false), 300); // Disable animation after 300ms
    }, [filteredChats.length, threads.length]);

    useEffect(() => {
        // Scroll to top when filtered chats change (due to search input change)
        if (chatsContainerRef.current) {
            chatsContainerRef.current.scrollTop = 0;
        }
    }, [filteredChats]);

    return (
        <div className='w-full h-[calc(100vh-300px)] 2xl:!h-[calc(100vh-315px)] 3xl:!h-[calc(100vh-330px)] mx-auto flex flex-col '>

            <div ref={chatsContainerRef} className={`h-full overflow-y-auto  ${isDarkMode ? "scrollbar-dark" : "scrollbar-light"}  w-full mx-auto flex flex-col gap-1`}>
                {isLoading ?
                    Array.from({ length: 10 }).map((_, index) => (
                        <div key={index} className="mt-2">
                            <Skeleton
                                height={25}
                             
                                baseColor={skeletonBaseColor}
                                highlightColor={skeletonHighlightColor}
                            />
                        </div>
                    )) :
                    filteredChats.length
                        ? (filteredChats.map((chat, index) => (
                            <div
                                key={chat.threadId}
                                className={` min-h-[38px] flex flex-row items-center p-1 2xl:px-4 2xl:py-2 w-full cursor-pointer overflow-hidden rounded-[5px]  ${isDarkMode ? "border border-[#FFFFFF] border-opacity-10" : "border"}  
                                   ${selectedThreadId === chat.threadId ? `${isDarkMode ? "bg-[#FFFFFF] bg-opacity-10" : "bg-[#FFFFFF] shadow-sm "} ` : ""} chat-item ${animate && index === 0 ? "" : ""}`}

                                onClick={() => handleChatSelect(chat.threadId)}>
                                <div className='ml-1 flex items-center justify-end'>
                                    <FiMessageSquare className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666666]"} w-5 h-5`} />
                                </div>
                                <div className=' truncate m-1 flex items-center justify-start px-1 w-full'>
                                    <p className={`truncate ${isDarkMode ? "text-[#E5E5E5]" : "text-[#666666]"} text-[12px] 2xl:text-[14px]  font-poppins font-normal`} title={chat.threadTitle}>
                                        {chat.threadTitle}
                                    </p>
                                </div>
                            </div>
                        )))
                        : <NoDataCard message="No Chats Found" />
                }
            </div>
        
        </div>
    );
};