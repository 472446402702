import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface RowsPerPageSelectorProps {
    initialRowsPerPage: number;
    onRowsPerPageChange: (rowsPerPage: number) => void;
}

const RowsPerPageSelector: React.FC<RowsPerPageSelectorProps> = ({ initialRowsPerPage, onRowsPerPageChange }) => {
    const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRowsPerPage(selectedValue);
        onRowsPerPageChange(selectedValue);
    };

    return (
        <div className="flex items-center justify-center">
            <div className=" h-full flex flex-row items-center justify-center gap-2">
                <h2 className={`font-poppins font-normal text-sm ${isDarkMode ? "text-white text-opacity-80" : "text-[#666F8D]"}`}>Rows per page :</h2>
                <select className={` h-8  px-[0.5rem] text-[12px] 2xl:text-[16px] rounded-md border focus:outline-none   ${isDarkMode ? "bg-[#1B1B1D]  border-[#4B5268] shadow shadow-[#19213D]-300/50 text-white" : "bg-white border-[#E2E9FF] shadow shadow-gray-300/50"}`} 
                value={rowsPerPage} onChange={handleRowsPerPageChange}>
                    {[5, 10, 25, 50, 100].map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default RowsPerPageSelector;
