import { call, put, take, takeLatest } from "redux-saga/effects";
import { httpGet, httpPost } from "../../../services";
import { getRepliedEmailListStart, getRepliedEmailListSuccess, getRepliedEmailListFailure, IGetRepliedEmailListPayload } from "../../slices/EmailSlice/getRepliedEmailListSlice";
// import { FIN_POWER_BASE_URL, ACR_SAP_CONFIG, API_SECRET_TOKEN } from "../../../config";

function* GetRepliedEmailListSaga(actions: { type: string, payload: IGetRepliedEmailListPayload; }) {
    try {
        const data= yield httpGet(`${process.env.REACT_APP_MAIL_MITRA_API}/outlook-email-api/get-replied-email-list?pageNo=${actions.payload.pageNo}&pageSize=${actions.payload.pageSize}&emailId=${actions.payload.emailId}&confidenceStatus=${actions.payload.confidenceStatus}&search=${actions.payload.search}`
            // {
            //     headers: {
            //         // token: API_SECRET_TOKEN,
            //         // "x-teams-origin": window.location.origin
            //         // "x-teams-origin": "http://localhost:3000"
            //     }
            // }
        );

        yield put(getRepliedEmailListSuccess(data));

    } catch (error) {
        console.error("Error fetching GetRepliedEmailListSaga:", error);
        yield put(getRepliedEmailListFailure(error));
    }
}

export default function* watchGetRepliedEmailListSaga() {
    yield takeLatest(getRepliedEmailListStart, GetRepliedEmailListSaga);
    // while (true) {
    //     const { payload } = yield take(getRepliedEmailListStart.type);
    //     yield call(GetRepliedEmailListSaga, payload);
    // }
}