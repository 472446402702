// tokenDecodeSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IActivity } from "./ChatSlice";

export interface IUserChats {
    threadId: string;
    threadTitle: string;
    activities: IActivity[];
}

interface UserChats {
    data: IUserChats[] | null;
    isLoading: boolean;
    success: boolean;
    isError: boolean;
    error: string | null;
}

const initialState: UserChats = {
    data: null,
    isLoading: false,
    success: false,
    isError: false,
    error: null,
};

const userChatsFromDBSlice = createSlice({
    name: "userChats",
    initialState,
    reducers: {
        userChatsRequest(state, action: PayloadAction<{ userId: number }>) {
            state.isLoading = true;
            state.isError = false;
            state.error = null;
        },
        userChatsSuccess(state, action: PayloadAction<IUserChats[]>) {
            state.data = action.payload;
            state.isLoading = false;
            state.success = true;
        },
        userChatsFailure(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.success = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const {
    userChatsRequest,
    userChatsSuccess,
    userChatsFailure,
} = userChatsFromDBSlice.actions;

export default userChatsFromDBSlice.reducer;
