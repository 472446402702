import React, { useState, useEffect } from "react";
import bgImg from "../../assets/carouselImg/baseImg2.png";
import { CarouselImageType } from "./CarousalData";

interface CarouselProps {
  images: CarouselImageType[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="relative flex justify-center items-center w-full h-56 overflow-hidden ">

      {images.map((data, index) => (
        <div
          key={index}
          className={`absolute w-full h-full flex  transition-opacity duration-500 ${index === currentIndex ? "opacity-100" : "opacity-0"}`}
          style={{ background: `linear-gradient(to right, ${data.leftColor}, ${data.rightColor})` }}
        >

          <div className="absolute right-0 w-[65%] h-full ">
            <img src={bgImg} className="w-full h-full object-cover" alt="Background" />
          </div>

          <div className={`z-10 container gap-2 w-full h-full flex flex-col justify-end sm:flex-row items-center  px-4 md:px-12 2xl:px-28 3xl:px-0 mx-auto ${index === currentIndex ? "opacity-100" : "opacity-0"}`}>
            <div className={" sm:w-1/2 text-center sm:text-start flex flex-col gap-1  items-center sm:items-start"}>
              <h6 className=" text-white font-poppins font-normal text-xs lg:text-sm">{data.heading}</h6>
              <h2 className="hidden md:block text-sm md:text-2xl font-poppins font-semibold text-white">{data.title}</h2>
              <h6 className=" text-white font-poppins font-normal text-xs lg:text-sm">{data.content}</h6>
            </div>
            <div className=" sm:w-1/2 bg-transparent flex justify-end ">
              <img src={data.img} className="w-36 h-36 sm:w-52 sm:h-56 object-contain bg-transparent" alt={"carousel front" + index} />
            </div>

          </div>
        </div>
      ))}

      {/* Slider indicators */}
      <div className='absolute bottom-2 hidden w-full sm:flex items-center justify-center  '>
        <div className="absolute bottom-2 ">
          {images.map((_, index) => (
            <button
              key={index}
              type="button"
              className={`w-2 h-2 rounded-full mx-1 ${index === currentIndex ? "bg-white" : "bg-gray-400"
                }`}
              onClick={() => setCurrentIndex(index)}
            ></button>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Carousel;
