// src/redux/slices/domainSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDomain {
    domainId: number;
    name: string;
    description: string;
    order: number;
    subDomainId: number;
    subDomains: string;
    subDomains_Description: string | null;
    subDomains_Order: number | null;
    logo: string | null;
    svg: string | null;
    IsStatus: boolean
}
interface DomainState {
    domainData: IDomain[];
    domainIsSuccess: boolean;
    domainLoading: boolean;
    domainIsError: boolean;
    domainError: string | null;
}

const initialState: DomainState = {
    domainData: [],
    domainIsSuccess: false,
    domainLoading: false,
    domainIsError: false,
    domainError: null,
};

const domainSlice = createSlice({
    name: "domain",
    initialState,
    reducers: {
        fetchDomainsRequest: (state) => {
            state.domainLoading = true;
            state.domainIsError = false;
            state.domainIsSuccess = false;
            state.domainError = null;
        },
        fetchDomainsSuccess: (state, action: PayloadAction<IDomain[]>) => {
            state.domainLoading = false;
            state.domainIsError = false;
            state.domainIsSuccess = true;
            state.domainError = null;
            state.domainData = action.payload;
        },
        fetchDomainsFailure: (state, action: PayloadAction<string>) => {
            state.domainLoading = false;
            state.domainIsError = true;
            state.domainIsSuccess = false;
            state.domainError = action.payload;
        },
    },
});

export const { fetchDomainsRequest, fetchDomainsSuccess, fetchDomainsFailure } = domainSlice.actions;

export default domainSlice.reducer;
