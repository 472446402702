import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface PaginationProps {
    totalItems: number;
    rowsPerPage: number;
    onPageChange: (startIndex: number, endIndex: number) => void;
    currentP: number;
}

const Pagination: React.FC<PaginationProps> = ({ totalItems, rowsPerPage, onPageChange, currentP }) => {
    const [currentPage, setCurrentPage] = useState<number>(currentP);
    const totalPages = Math.ceil(totalItems / rowsPerPage);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);


    const handleNextPage = () => {
        if (currentPage < totalPages) {
            const newPage = currentPage + 1;
            setCurrentPage(newPage);
            onPageChange((newPage - 1) * rowsPerPage, newPage * rowsPerPage);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            onPageChange((newPage - 1) * rowsPerPage, newPage * rowsPerPage);
        }
    };

    const startIndex = (currentPage - 1) * rowsPerPage + 1;
    const endIndex = Math.min(currentPage * rowsPerPage, totalItems);

    return (

        <div className=" flex items-center gap-3 justify-center">
            <span className={`font-poppins font-normal text-[12px] 2xl:text-[16px] ${isDarkMode ? "text-white text-opacity-80" : "text-[#666F8D]"}`}>
                {startIndex}-{endIndex} of {totalItems}
            </span>
            <div className="flex items-center justify-center   ">
                <button
                    className="text-[18px] p-1 pb-2 border-none bg-transparent cursor-pointer text-[#555] transition-colors duration-300 ease-in-out disabled:text-[#ccc] disabled:cursor-not-allowed hover:enabled:text-[#007bff]"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    &#8249;
                </button>
                <button
                    className="text-[18px] p-1 pb-2 border-none bg-transparent cursor-pointer text-[#555] transition-colors duration-300 ease-in-out disabled:text-[#ccc] disabled:cursor-not-allowed hover:enabled:text-[#007bff]"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    &#8250;
                </button>
            </div>

        </div>
    );
};

export default Pagination;
