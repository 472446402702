import React, { useEffect, useState } from "react";
import ChatScreenFooter from "./ChatScreenFooter/ChatScreenFooter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { MsgScreen } from "./MsgScreen";
import { DirectLine, ConnectionStatus } from "botframework-directlinejs";
import { MyStorage } from "../../storage/myStorage";
import { getJWTokenStart, refreshJWTokenStart } from "../../redux/slices/jwTokenSlice";
import { IActivity, addMessage } from "../../redux/slices/ChatSlice";
import TypingAnimation from "../Typing";

let directLine: DirectLine | null;

export const handleSendActivity = (query: string, chartType: string, threadId: string, role: string | null, userId: number, userDomainId: string) => {

    directLine && directLine.postActivity({
        from: { id: "TML-Neural", name: "TML-Neural" }, // required (from.name is optional)
        type: "message",
        timestamp: new Date().toJSON(),
        text: query,
        channelId: "tml",
        channelData: {
            oauthAccessToken: MyStorage.getItem("ssoToken"),
            chartType,
            OPEN_AI_HOST_API: MyStorage.getItem("OPEN_AI_HOST_API"),
            threadId: threadId,
            role: role,
            userId: userId,
            userDomainId: userDomainId
        }
    }).subscribe(
        (id) => { console.log("Posted activity, assigned ID ", id); },
        (error) => console.log("Error posting activity", error)
    );
};


const ChatScreen: React.FC = () => {
    const dispatch = useDispatch();
    const JWToken = useSelector((state: RootState) => state.getJWToken);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const selectedThreadId = useSelector((state: RootState) => state.chat.selectedThreadId);

    const threads = useSelector((state: RootState) => state.chat.threads);
    const selectedChat = threads.find((chat) => chat.threadId === selectedThreadId);

    const UpdateMessagesToStore = (activity: IActivity) => {
        if (!activity?.attachments) {
            if (activity.type !== "typing") {
                dispatch(addMessage({ activity }));
            }
        }
 
    };
 
    const ifTokenExpired = () => {
        const storedTimestamp = MyStorage.getItem("timeStamp");
        const storedTimestampNumber = Number(storedTimestamp);
        return storedTimestampNumber < Date.now();
    };
 
    const createDirectLineSession = (directlineToken: string, conversationId?: string) => {
        const currTimestamp = Date.now();
        const timeStampAfter = currTimestamp + (58 * 60 * 1000);
        (MyStorage.getItem("directlineToken") !== directlineToken) && MyStorage.setItem("timeStamp", timeStampAfter.toString());
        MyStorage.setItem("directlineToken", directlineToken);
        MyStorage.setItem("convId", conversationId);
    };
 
    const clearDirectLineSession = async () => {
        if (MyStorage.getItem("directlineToken") && MyStorage.getItem("convId") && navigator.onLine) {
            console.log("clearDirectLineSession inside", MyStorage.getItem("convId"));
            MyStorage.removeItem("timeStamp");
            MyStorage.removeItem("directlineToken");
            MyStorage.removeItem("convId");
            if (directLine) await directLine.end();
            directLine = null;
            if (JWToken.jwTokenIsSuccess) await dispatch(getJWTokenStart());
        } else console.log("clearDirectLineSession outside", MyStorage.getItem("convId"));
    };
 
    const reconnectDirectLine = async () => {
        // clearDirectLineSession(); // Clear stale session
        const token = JWToken.jwToken.token;
        if (token && navigator.onLine && directLine !== null && directLine !== undefined) {
            await directLine.end();
            dispatch(refreshJWTokenStart(token));
        }
    };
 
    const initializeDirectLine = async () => {
 
        const directlineToken = JWToken.jwToken.token;
        const conversationId = JWToken.jwToken.conversationId;
 
        if (directlineToken && conversationId) {
            if (MyStorage.getItem("directlineToken") && MyStorage.getItem("convId") && !ifTokenExpired()) {
                console.log("Second Call");
                directLine = await new DirectLine({
                    token: directlineToken as string,
                    // conversationId: conversationId,
                    timeout: 60000
                });
            } else {
                console.log("First Call");
                directLine = await new DirectLine({
                    token: directlineToken as string,
                    timeout: 60000
                });
            }
 
            await directLine.connectionStatus$.subscribe({
                next: (cs: number) => {
                    switch (cs) {
                        case ConnectionStatus.Online:
                            console.log("Connected");
                            createDirectLineSession(directlineToken, conversationId);
                            break;
                        case ConnectionStatus.FailedToConnect:
                            console.log("FailedToConnect");
                            reconnectDirectLine();
                            break;
                        case ConnectionStatus.ExpiredToken:
                            console.log("Connection ExpiredToken");
                            clearDirectLineSession();
                            break;
                        case ConnectionStatus.Ended:
                            console.log("Connection Ended");
                            break;
                        case ConnectionStatus.Connecting:
                            console.log("Connecting");
                            break;
                        case ConnectionStatus.Uninitialized:
                            console.log("Connecting Uninitialized");
                            break;
                    }
                },
                error: (err) => {
                    console.log("Error in connection subscription:", err);
                    // clearDirectLineSession();
                }
            });
 
            await directLine.activity$.subscribe({
                next: (activity: IActivity) => {
                    if (activity.type === "message") {
                        console.log("Receive Activity", activity);
                    }
                    UpdateMessagesToStore(activity);
                },
                error: (err) => {
                    console.log("Error in activity subscription:", err.message);
                    // clearDirectLineSession();
                }
            });
        }
    };
 
 
    //  initlization of Botz
    React.useEffect(() => {
        let tokenExpirationInterval;
        if (JWToken.jwTokenIsSuccess && JWToken.jwToken.token) {
            initializeDirectLine();
            tokenExpirationInterval = setInterval(() => {
                if (!ifTokenExpired()) {
                    console.log("Interval 1");
                    reconnectDirectLine();
                } else if (ifTokenExpired()) {
                    console.log("Interval 2");
                    clearDirectLineSession();
                }
 
            }, (JWToken.jwToken.expires_in - 180) * 1000); // Refresh
        }
        // Handle visibility change (e.g., waking from sleep)
        const handleVisibilityChange = () => {
            if (!document.hidden && ifTokenExpired()) {
                console.log("wakeup inside 1");
                clearDirectLineSession();
            } else if (!document.hidden && !ifTokenExpired()) {
                console.log("wakeup inside 2");
                reconnectDirectLine();
            } else console.log("wakeup outside");
        };
 
        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("online", handleVisibilityChange);
        window.addEventListener("offline", () => console.log("offline"));
 
        // Clean up the interval on component unmount
        return () => {
            console.log("clearInterval");
            if (directLine !== null && directLine !== undefined) {
                directLine.end();
                directLine = null;
            }
            clearInterval(tokenExpirationInterval);
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("online", handleVisibilityChange);
            window.removeEventListener("offline", () => console.log("offline"));
        };
 
    }, [JWToken.jwToken.token]);
 
 

    return (
        <div className={`w-full h-full  ${isDarkMode ? "bg-[#1D1E1F] bg-opacity-70  md:border-r border-[#FFFFFF] border-opacity-[15%]" : "bg-[#FFFFFF] bg-opacity-70 border border-[#F0F2F5] border-r-0 "} transition-full duration-300`}>
            <div className='h-full flex flex-col justify-between py-[10px] w-[95%] mx-auto'>
                <MsgScreen />
                <div className="flex flex-col gap-1">
                    {selectedChat?.typing === true && <TypingAnimation />}
                    <ChatScreenFooter />
                </div>
            </div>
        </div>
    );
};
export default ChatScreen;