import { call, put, take, takeLatest } from "redux-saga/effects";
import { httpGet, httpPost } from "../../../services";
import { getEmailListStart, getEmailListSuccess, getEmailListFailure, IGetEmailListPayload } from "../../slices/EmailSlice/getEmailListSlice";
// import { FIN_POWER_BASE_URL, ACR_SAP_CONFIG, API_SECRET_TOKEN } from "../../../config";

function* GetEmailListSaga(actions: { type: string, payload: IGetEmailListPayload; }) {
    try {
        const data = yield httpGet(`${process.env.REACT_APP_MAIL_MITRA_API}/outlook-email-api/get-list-confidence-null?pageNo=${actions.payload.pageNo}&pageSize=${actions.payload.pageSize}&emailId=${actions.payload.emailId}&confidenceStatus=${actions.payload.confidenceStatus}&search=${actions.payload.search}`
            // {
            //     headers: {
            //         // token: API_SECRET_TOKEN,
            //         // "x-teams-origin": window.location.origin
            //         // "x-teams-origin": "http://localhost:3000"
            //     }
            // }
        );

        yield put(getEmailListSuccess(data));

    } catch (error) {
        console.error("Error fetching GetEmailListSaga:", error);
        yield put(getEmailListFailure(error));
    }
}

export default function* watchGetEmailListSaga() {
    yield takeLatest(getEmailListStart, GetEmailListSaga);
    // while (true) {
    //     const { payload } = yield take(getEmailListStart.type);
    //     yield call(GetEmailListSaga, payload);
    // }
}