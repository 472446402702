import React, { useEffect, useRef, useState } from "react";
import backgroundImg from "../assets/BackgroundChat.png";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import LeftContainer from "../components/DVRAutomation/DVRLeftSection/LeftContainer";
import MainMailSection from "../components/DVRAutomation/DVRMailSection/MainMailSection";
import AccessDenied from "../components/AccessDenied";
import LazyFallback from "../components/Loaders";


const DVRAutomation: React.FC = () => {
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const role = useSelector((state: RootState) => state.matchRole);


  const containerStyle = {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    justifyContent: "spaceBetween"
  };
  return (
    <>
      {(role.isSuccess && role.data && role.data.some((app) => app.subDomainId === 14))
        ? <div className="relative w-screen h-dvh">
          <div style={containerStyle} className={`w-full h-full  ${isDarkMode ? "bg-[#1B1B1D] " : "bg-[#F7F8FA]"}  flex items-center flex-col mx-auto transition-full duration-300`}>
            <div className="grid w-full h-full grid-cols-5 ">

              <div className="hidden lg:block lg:col-span-1">
                <LeftContainer />
              </div>
              <div className="col-span-5 lg:col-span-4 flex overflow-hidden ">
                <MainMailSection />
              </div>

            </div>
          </div>
        </div>


        : (role.isSuccess && role.data && !role.data.some((app) => app.subDomainId === 14)) || role.isError ?
          <AccessDenied />
          : <LazyFallback />
      }
    </>
  );
};

export default DVRAutomation;
