/* eslint-disable quotes */
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FiSearch } from "react-icons/fi";
import MailScreen from "./MailScreen";
import { useEffect, useState } from "react";
import EmailDetailView from "../EmailDetailView";
import { IGetEmailListItem } from "../../../redux/slices/EmailSlice/getEmailListSlice";
import { IGetRepliedEmailListItem } from "../../../redux/slices/EmailSlice/getRepliedEmailListSlice";
import MailRepliedScreen from "./MailRepliedScreen";
import EmailDetailRepliedView from "../EmailDetailView/EmailDetailRepliedView";
import { setConfidenceLevel, setSearch } from "../../../redux/slices/appSlice";

// Define the type for dropdown options
interface DropdownOption {
    value: string;
    label: string;
}

// Define the dropdown options
const options: DropdownOption[] = [
    { value: '', label: 'Select' },
    { value: 'low', label: 'Low' },
    { value: 'high', label: 'High' },
    { value: 'medium', label: 'Medium' },
];


const MainMailSection: React.FC = () => {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const [isDetailView, setIsDetailView] = useState(false);
    const [detailRow, setDetailRow] = useState<IGetEmailListItem | null>(null);
    const [isDetailRepliedView, setIsDetailRepliedView] = useState(false);
    const [detailRepliedRow, setDetailRepliedRow] = useState<IGetRepliedEmailListItem | null>(null);
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [searchInput, setSearchInput] = useState("");
    const [rowsPerPageAllMails, setRowsPerPageAllMails] = useState<number>(25);
    const [rowsPerPageAllRepliedMails, setRowsPerPageAllRepliedMails] = useState<number>(25);

    const handleSelectOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        setIsDetailView(false);
        setIsDetailRepliedView(false);
    }, [state.app.activeMenu]);

    useEffect(() => { dispatch(setConfidenceLevel(selectedOption)); }, [selectedOption]);
    useEffect(() => { dispatch(setSearch(searchInput)); }, [searchInput]);

    return (
        <div className={` w-full h-full px-4 py-2 rounded-lg ${isDarkMode ? "bg-[#1D1E1F] bg-opacity-70  border-[#FFFFFF] border-opacity-[15%]" : "bg-[#FFFFFF] bg-opacity-70  border-[#F0F2F5] border-r-0 "} transition-full duration-300 flex flex-col items-center justify-center border `}>
            <div className={`w-full border-b py-2 border-opacity-10  ${isDarkMode ? "border-white" : "border-black"} flex flex-row justify-between items-center `}>
                <div className="font-poppins font-bold text-lg 2xl:text-xl text-[#31C6BB]">
                    <h1 >{state.app.activeMenu === "allMail" ? "All Mails" : "Replied Mails"}</h1>
                </div>
                {(state.app.activeMenu === "allMail" ? !isDetailView : !isDetailRepliedView) &&

                    <div className="flex flex-row items-center justify-between gap-4">
                        <div className=" h-full flex flex-row items-center justify-center gap-2">
                            <h2 className={`font-poppins font-normal text-sm  2xl:text-[16px]  ${isDarkMode ? "text-white text-opacity-80" : "text-[#666F8D]"}`}>Confidence Level :</h2>
                            <select className={`h-8 2xl:h-10  px-[1rem] rounded-md border focus:outline-none text-[12px] 2xl:text-[16px] font-poppins  ${isDarkMode ? "bg-[#1B1B1D]  border-[#4B5268] shadow shadow-[#19213D]-300/50 text-white" : "bg-white border-[#E2E9FF] shadow shadow-gray-300/50"}`}
                                value={selectedOption} onChange={handleSelectOptionChange}>
                                {options.map((option) => (
                                    <option
                                        className={`${isDarkMode ? "bg-[#1B1B1D] text-white" : "bg-white text-black"} hover:bg-[#31C6BB] text-[12px] 2xl:text-[16px] font-poppins`}
                                        key={option.value}
                                        value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={` hidden md:block relative w-[350px] h-8 2xl:h-10 border ${isDarkMode ? "bg-[#1B1B1D]  border-[#4B5268] shadow shadow-[#19213D]-300/50" : "bg-white border-[#E2E9FF] shadow shadow-gray-300/50"} overflow-hidden rounded-md`}>
                            <div className='absolute  h-full inset-y-0 left-[10px] flex items-center pointer-events-none'>
                                <FiSearch className={`w-4 h-4 ${isDarkMode ? "text-[#31C6BB]" : "text-[#666F8D]"}`} />
                            </div>
                            <div className={`relative flex items-center w-[350px] h-8 2xl:h-10 border ${isDarkMode ? "bg-[#1B1B1D]  border-[#4B5268] shadow shadow-[#19213D]-300/50" : "bg-white border-[#E2E9FF] shadow shadow-gray-300/50"} overflow-hidden rounded-md`}>
                                <div className='absolute h-full inset-y-0 left-[10px] flex items-center pointer-events-none'>
                                    <FiSearch className={`w-4 h-4 ${isDarkMode ? "text-[#31C6BB]" : "text-[#666F8D]"}`} />
                                </div>

                                <div className='flex items-center justify-center w-full h-full  '>
                                    <input
                                        className={`w-full h-full text-[12px]  2xl:text-[16px] font-poppins ${isDarkMode ? "text-[#31C6BB] placeholder-[#31C6BB] bg-[#1B1B1D]" : "text-[#666F8D] placeholder-[#666F8D]"}  pl-8 focus:outline-none focus:border-[#31C6BB]  `}
                                        type='default-search'
                                        placeholder='Search for mail...'
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {/* Mail Table */}
            <div className='w-full h-full flex flex-col justify-between '>
                {state.app.activeMenu === "allMail" ?
                    <>
                        {
                            !isDetailView ? <MailScreen setIsDetailView={setIsDetailView} setDetailRow={setDetailRow} rowsPerPage={rowsPerPageAllMails} setRowsPerPage={setRowsPerPageAllMails} /> : <EmailDetailView setIsDetailView={setIsDetailView} detailRow={detailRow} />
                        }

                    </>
                    : <>
                        {
                            !isDetailRepliedView ? <MailRepliedScreen setIsDetailView={setIsDetailRepliedView} setDetailRow={setDetailRepliedRow} rowsPerPage={rowsPerPageAllRepliedMails} setRowsPerPage={setRowsPerPageAllRepliedMails} /> : <EmailDetailRepliedView setIsDetailView={setIsDetailRepliedView} detailRow={detailRepliedRow} />
                        }
                    </>
                }

            </div>

        </div>
    );
};

export default MainMailSection;