import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IfeedbackApiResponse {
    message: string;
    feedbackId: string;
    feedbackAction: string;
}

export interface IfeedBackPayload {
    userId: number;
    threadId: string;
    messageId: string;
    feedback: string;
    cachePayload?: { question: string; response: any };
}

interface IfeedbackApiState {
    loading: boolean;
    isSuccess: boolean;
    isError: boolean;
    data: IfeedbackApiResponse | null;
    error: string | null;
    feedbackActiveId: string;
    feedbackList: {
        feedbackId: string;
        feedbackAction: string;
    }[];
}

const initialState: IfeedbackApiState = {
    loading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
    feedbackActiveId: "",
    feedbackList: []
};

const feedbackApiSlice = createSlice({
    name: "feedback",
    initialState,
    reducers: {
        feedbackApiRequestStart(state, action: PayloadAction<IfeedBackPayload>) {
            state.loading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            state.data = null;
            state.feedbackActiveId = action.payload.messageId;

        },
        feedbackApiRequestSuccess(state, action: PayloadAction<IfeedbackApiResponse>) {
            state.loading = false;
            state.isSuccess = true;
            state.isError = false;
            state.data = action.payload;
            state.feedbackList = [
                ...state.feedbackList,
                { feedbackId: action.payload.feedbackId, feedbackAction: action.payload.feedbackAction }
            ];

        },
        feedbackApiRequestFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});


export const { feedbackApiRequestStart, feedbackApiRequestSuccess, feedbackApiRequestFailure } = feedbackApiSlice.actions;
export default feedbackApiSlice.reducer;
