// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}


.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}

.svg-style-dark {
  svg {
    height: 50px;
    width: 50px;
    max-height: 50px;
    fill: white !important;

    path {
      fill: white !important;
    }
  }
}

.svg-style-light {
  svg {
    height: 50px;
    width: 50px;
    max-height: 50px;
    fill: black !important;
  }

  path {
    fill: black !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Apps/app.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,4BAA4B;AAC9B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;EAGE,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;;AAGA;;EAGE,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,sBAAsB;;IAEtB;MACE,sBAAsB;IACxB;EACF;AACF;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".flip-card {\n  background-color: transparent;\n  perspective: 1000px;\n}\n\n.flip-card-inner {\n  transition: transform 0.6s;\n  transform-style: preserve-3d;\n}\n\n.flip-card:hover .flip-card-inner {\n  transform: rotateY(180deg);\n}\n\n.flip-card-front,\n.flip-card-back {\n  -webkit-backface-visibility: hidden;\n  backface-visibility: hidden;\n  border-radius: 10px;\n}\n\n.flip-card-front {\n  color: black;\n}\n\n.flip-card-back {\n  color: white;\n  transform: rotateY(180deg);\n}\n\n\n.flip-card-front,\n.flip-card-back {\n  -webkit-backface-visibility: hidden;\n  backface-visibility: hidden;\n  border-radius: 10px;\n}\n\n.flip-card-front {\n  color: black;\n}\n\n.flip-card-back {\n  color: white;\n  transform: rotateY(180deg);\n}\n\n.svg-style-dark {\n  svg {\n    height: 50px;\n    width: 50px;\n    max-height: 50px;\n    fill: white !important;\n\n    path {\n      fill: white !important;\n    }\n  }\n}\n\n.svg-style-light {\n  svg {\n    height: 50px;\n    width: 50px;\n    max-height: 50px;\n    fill: black !important;\n  }\n\n  path {\n    fill: black !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
