import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IActiveDomain {
    activeDomainId: number;
}

const initialState: IActiveDomain = {
    activeDomainId: 1,
};

const activeDomainSlice = createSlice({
    name: "activeDomain",
    initialState,
    reducers: {
        setActiveDomain: (state, action: PayloadAction<number>) => {
            state.activeDomainId = action.payload;
        },
    },
});

export const { setActiveDomain } = activeDomainSlice.actions;
export default activeDomainSlice.reducer;
