import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routers from "./routes";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";

import { LoginScreen } from "./containers/LoginScreen";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./config/AuthConfig";
import { MyStorage } from "./storage/myStorage";
import LazyFallback from "./components/Loaders";
import { useDispatch, useSelector } from "react-redux";
import { userInfoRequestStart } from "./redux/slices/userInfoByAzureADSlice";
import { fetchDomainsRequest } from "./redux/slices/domainSlice";
import { matchRoleRequestStart } from "./redux/slices/matchRoleSlice";
import { RootState } from "./redux/store";
import { userImgRequestStart } from "./redux/slices/userImgByAzureADSlice";
import { createUserRequest } from "./redux/slices/createUserSlice";


export const getInitials = (firstName: string, lastName: string) => {
  const fI = firstName ? firstName[0].toUpperCase() : "";
  const lI = lastName ? lastName[0].toUpperCase() : "";
  return fI.concat(lI);
};

// const App: React.FC = () => {
const App: React.FC = () => {
  const dispatch = useDispatch();

  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated(); // MSAL Authentication
  // const [userInfo, setUserInfo] = React.useState<{ token?: string, email?: string }>();
  const [userInfo, setUserInfo] = React.useState<{ token?: string, email?: string, firstName?: string, lastName?: string }>();

  const { userInfoData, userInfoIsSuccess, userInfoLoading } = useSelector((state: RootState) => state.userInfo);


  // sso
  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      // instance.ssoSilent(loginRequest);
      instance.loginRedirect(loginRequest);

    } else if (isAuthenticated && accounts) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response: any) => {
          setUserInfo({ token: response.accessToken, email: response.account?.username });
          MyStorage.setItem("ssoToken", response.accessToken);
          MyStorage.setItem("email", response.account?.username);
        })
        .catch((error: any) => {
          console.error("Error acquiring token silently:", error);
          // instance.loginRedirect(loginRequest);
          if (error.errorCode === "monitor_window_timeout") {
            instance.acquireTokenRedirect(loginRequest)
              .then((response: any) => {
                setUserInfo({ token: response.accessToken, email: response.account?.username });
                MyStorage.setItem("ssoToken", response.accessToken);
                MyStorage.setItem("email", response.account?.username);
              }).catch((error: any) => {
                console.error("Error in aquire token redirect:", error);
                instance.loginRedirect(loginRequest);
              });
          }
          else {
            instance.loginRedirect(loginRequest);
          }
        });
    }
  }, [isAuthenticated, inProgress, instance, accounts]);

  // azureAD graphapi
  useEffect(() => {
    if (userInfo?.email && userInfo?.token) {
      dispatch(userInfoRequestStart({ email: userInfo?.email, token: userInfo?.token }));
      dispatch(userImgRequestStart({ token: userInfo?.token }));
    }
  }, [userInfo]);

  // domains and apps 
  useEffect(() => {
    if (userInfoIsSuccess && userInfoData.mailNickname) {
      dispatch(createUserRequest({ email: userInfo.email, firstName: userInfoData.givenName, lastName: userInfoData.surname, userDomainId: userInfoData.mailNickname, profileDesc: userInfoData.displayName }));
      dispatch(fetchDomainsRequest());
      dispatch(matchRoleRequestStart({ domainId: userInfoData?.mailNickname }));
    }
  }, [userInfoIsSuccess]);



  return (
    <>
      <BrowserRouter>
        <AuthenticatedTemplate>
          <Routers />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LazyFallback />
        </UnauthenticatedTemplate>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default App;
