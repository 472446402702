// src/redux/sagas/domainSaga.ts
import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { httpGet } from "../../../services";
import { fetchDomainsFailure, fetchDomainsRequest, fetchDomainsSuccess, IDomain } from "../../slices/domainSlice";

interface IFetchDomains {
    success: boolean;
    domains: IDomain[];
}

function* fetchDomains(): Generator<any, void, IFetchDomains> {
    try {
        const response: IFetchDomains = yield httpGet(`${process.env.REACT_APP_API_URL}/api/v1/domains`, {});
        if (response.success)
            yield put(fetchDomainsSuccess(response.domains));
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            yield put(fetchDomainsFailure(error?.message));
        } else {
            yield put(fetchDomainsFailure("Error occurred while APi calling of domains"));
        }
    }
}

function* domainSaga() {
    yield takeLatest(fetchDomainsRequest.type, fetchDomains);
}

export default domainSaga;
