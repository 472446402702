import React, { useEffect, useState } from "react";
import { MailData } from "./MailScreen";
import RowsPerPageSelector from "./RowsPerPageSelector";
import Pagination from "./Pagination";
import { IGetEmailListItem } from "../../../redux/slices/EmailSlice/getEmailListSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type MailTablePaginationProps = {
    mailData: IGetEmailListItem[];
    totalItems: number;
    rowsPerPage: number;
    currentPage: number;
    onPageChange: (pageNo: number, rowsPerPage: number) => void;
    onRowsPerPageChange: (newRowsPerPage: number) => void;
};

// eslint-disable-next-line no-empty-pattern
const MailTablePagination: React.FC<MailTablePaginationProps> = ({ mailData,
    totalItems,
    rowsPerPage,
    currentPage,
    onPageChange,
    onRowsPerPageChange }) => {
    const [currentRange, setCurrentRange] = useState<[number, number]>([1, rowsPerPage]);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    useEffect(() => {
        setCurrentRange([(currentPage - 1) * rowsPerPage, currentPage * rowsPerPage]);
    }, [currentPage, rowsPerPage]);

    const handlePageChange = (startIndex: number, endIndex: number) => {
        const newPageNo = Math.floor(startIndex / rowsPerPage) + 1;
        onPageChange(newPageNo, rowsPerPage);
        setCurrentRange([startIndex, endIndex]);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        onRowsPerPageChange(newRowsPerPage);
        setCurrentRange([0, newRowsPerPage]);
    };



    return (
        <div className={` ${isDarkMode ? "bg-[#1D1E1F] bg-opacity-70 border-t border-[#FFFFFF] border-opacity-[15%]" : "bg-[#FFFFFF] bg-opacity-70 border-t border-[#F0F2F5] "}  p-1 rounded-md flex flex-row justify-end px-3 gap-7` } 
        >
            <RowsPerPageSelector
                initialRowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
            <Pagination
                totalItems={totalItems}
                rowsPerPage={rowsPerPage}
                currentP={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default MailTablePagination;