/* eslint-disable quotes */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAppConfig {
    dimension: { deviceWidth: number; deviceHeight: number; };
    path?: string;
    activeMenu: string;
    confidence_level: string;
    search: string;
}

// Initial state matching the shape of IAppConfig
const initialState: IAppConfig = {
    dimension: { deviceWidth: 0, deviceHeight: 0 },
    path: '',
    activeMenu: 'allMail',
    confidence_level: 'low',
    search: ""

};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        deviceDimensions(state, action: PayloadAction<{ deviceWidth: number; deviceHeight: number; }>) {
            state.dimension = action.payload;
        },
        setPath(state, action: PayloadAction<string>) {
            state.path = action.payload;
        },
        setActiveMenu(state, action: PayloadAction<string>) {
            state.activeMenu = action.payload;
        },
        setConfidenceLevel(state, action: PayloadAction<string>) {
            state.confidence_level = action.payload;
        },
        setSearch(state, action: PayloadAction<string>) {
            state.search = action.payload;
        },
    },
});

// Export the actions
export const { deviceDimensions,
    setPath,
    setActiveMenu,
    setConfidenceLevel,
    setSearch
} = appSlice.actions;

// Export the reducer
export default appSlice.reducer;
