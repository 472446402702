import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface HttpPostResponse {
    conversationId: string;
    expires_in: number;
    token: string;
}

export interface IJWToken {
    jwTokenLoading: boolean;
    jwTokenIsSuccess: boolean;
    jwTokenIsError: boolean;
    jwToken: HttpPostResponse;
    jwTokenError: string | null;
}

const initialState: IJWToken = {
    jwTokenLoading: false,
    jwTokenIsSuccess: false,
    jwTokenIsError: false,
    jwToken: {
        conversationId: "",
        expires_in: 3600,
        token: "",
    },
    jwTokenError: null,
};

const getJWTokenSlice = createSlice({
    name: "getJWToken",
    initialState,
    reducers: {
        getJWTokenStart(state) {
            state.jwTokenLoading = true;
            state.jwTokenIsSuccess = false;
            state.jwTokenIsError = false;
            state.jwTokenError = null;
        },
        getJWTokenSuccess(state, action: PayloadAction<HttpPostResponse>) {
            state.jwTokenLoading = false;
            state.jwTokenIsSuccess = true;
            state.jwTokenIsError = false;
            state.jwToken = action.payload;

        },
        getJWTokenFailure(state, action: PayloadAction<string>) {
            state.jwTokenLoading = false;
            state.jwTokenIsSuccess = false;
            state.jwTokenIsError = true;
            state.jwTokenError = action.payload;
        },
        getJWTokenClear(state) {
            state.jwToken = initialState.jwToken;
            state.jwTokenLoading = false;
            state.jwTokenIsSuccess = false;
            state.jwTokenIsError = false;
            state.jwTokenError = null;
        },
        refreshJWTokenStart(state, action: PayloadAction<string>) {
            // state.jwTokenLoading = true;
            // state.jwTokenIsSuccess = false;
            // state.jwTokenIsError = false;
            // state.jwTokenError = null;
        },
        refreshJWTokenSuccess(state, action: PayloadAction<HttpPostResponse>) {
            // state.jwTokenLoading = false;
            // state.jwTokenIsSuccess = true;
            // state.jwTokenIsError = false;
            state.jwToken = action.payload;
        },
        refreshJWTokenFailure(state, action: PayloadAction<string>) {
            // state.jwTokenLoading = false;
            // state.jwTokenIsSuccess = false;
            // state.jwTokenIsError = true;
            // state.jwTokenError = action.payload;
        },
    },
});

export const { getJWTokenStart, getJWTokenSuccess, getJWTokenFailure, getJWTokenClear, refreshJWTokenStart, refreshJWTokenSuccess, refreshJWTokenFailure } = getJWTokenSlice.actions;
export default getJWTokenSlice.reducer;
