/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./mailTable.css";
import { MailData } from "./MailScreen";
import { IGetEmailListItem } from "../../../redux/slices/EmailSlice/getEmailListSlice";
import moment from "moment";
import { EditIconSVG, ViewIconSVG } from "../../../assets";
import { useState } from "react";
import { setActiveMailId } from "../../../redux/slices/activeTableMailId";
import { ConfidenceStatus, ConfidenceStatusDarkStyles, ConfidenceStatusLightStyles } from "../../../constants";
import Skeleton from "react-loading-skeleton";

interface MailTableProps {
    mailData: IGetEmailListItem[];
    setIsDetailView: React.Dispatch<React.SetStateAction<boolean>>;
    setDetailRow: React.Dispatch<React.SetStateAction<IGetEmailListItem>>;
}
// eslint-disable-next-line react/prop-types
const MailTable: React.FC<MailTableProps> = ({ mailData, setIsDetailView, setDetailRow }) => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const { isLoading, isSuccess, response, isError } = useSelector((state: RootState) => state.getEmailList);

    const skeletonBaseColor = isDarkMode ? "#2c2c2c" : "#e0e0e0";
    const skeletonHighlightColor = isDarkMode ? "#3a3a3a" : "#f0f0f0";

    const activeMailId = useSelector((state: RootState) => state.activeMailId.activeTableMailId);
    const dispatch = useDispatch();

    const handleDetailView = (mail: any, id: number) => {
        dispatch(setActiveMailId(id));
        setDetailRow(mail);
        setIsDetailView(true);
    };

    return (
        <div className={` ${isDarkMode ? "mailTable-dark " : "mailTable"} h-full w-full scrollbar-light `}>
            <table className="w-full text-[12px] 2xl:text-[16px] ">
                <thead>
                    <tr>
                        <div className="flex flex-row justify-between">
                            <div>
                                <th>
                                    <div className="w-10 flex justify-center items-center">
                                        S No.
                                    </div>
                                </th>
                                <th>
                                    <div className="field-container">
                                        Email Received Date
                                    </div>
                                </th>
                                <th>
                                    <div className="field-container">
                                        Sender Email
                                    </div>
                                </th>
                                <th>
                                    <div className="field-container">
                                        Sender Name
                                    </div>
                                </th>
                                <th>
                                    <div className="field-container">
                                        Subject
                                    </div>
                                </th>

                            </div>
                            <div>
                                <th>
                                    <div className=" field-container-right flex justify-center items-center">
                                        Confidence Level
                                    </div>
                                </th>
                                <th>
                                    <div className=" field-container-right flex justify-center ">
                                        Action
                                    </div>
                                </th>
                            </div>
                        </div>
                    </tr>
                </thead>
                {
                    isLoading
                        ? Array.from({ length: 20 }).map((_, index) => (
                            <div
                                key={index}
                                className={`overflow-hidden w-full flex flex-col  ${index % 2 === 0 ? "animate-bounce-up" : "animate-bounce-down"}`} >
                                <div className="table-cell p-2 overflow-hidden w-full">
                                    <Skeleton
                                        height={25}
                                        style={{ width: '77vw' }}
                                        baseColor={skeletonBaseColor}
                                        highlightColor={skeletonHighlightColor}
                                    />
                                </div>
                            </div>
                        ))
                        : <tbody>
                            {isSuccess && mailData && mailData.length ? (mailData?.map((mail, index) => (
                                <tr className={activeMailId === mail.ID? `border-[0.5px] ${isDarkMode ? "border-[#444B5F] !bg-[#dad8d81a]" : "border-[#DEDEDE] !bg-[#7a78781a]"} ` : ""} key={mail.InternetMessageId}>
                                    <div className="flex flex-row justify-between">
                                        <div className=" flex items-center">
                                            <td>
                                                <div className="w-10 flex justify-center items-center" >
                                                    {index + 1}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="field-container" title={moment(mail.ReceivedDateTime).format('YYYY-MM-DD HH:mm:ss')}>
                                                    {moment(mail.ReceivedDateTime).format('YYYY-MM-DD HH:mm:ss')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="field-container" title={mail.Sender_email}>
                                                    {mail.Sender_email}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="field-container" title={mail.Sender_name}>
                                                    {mail.Sender_name}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="field-container" title={mail.Subject}>
                                                    {mail.Subject}
                                                </div>
                                            </td>
                                        </div>
                                        <div className=" flex items-center">
                                            <td  >
                                                <div className="field-container-right flex justify-center items-center ">
                                                    <div className="font-poppins">
                                                        {
                                                            <div
                                                                className="text-center text-[11px] 2xl:text-[14px] font-medium px-2  py-1 rounded w-20"
                                                                style={{
                                                                    backgroundColor: isDarkMode
                                                                        ? ConfidenceStatusDarkStyles[mail?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.bgColor
                                                                        : ConfidenceStatusLightStyles[mail?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.bgColor,
                                                                    color: isDarkMode
                                                                        ? ConfidenceStatusDarkStyles[mail?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.textColor
                                                                        : ConfidenceStatusLightStyles[mail?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.textColor
                                                                }}
                                                            >
                                                                {mail?.Confidence_status
                                                                    ? `${mail?.Confidence_status[0].toUpperCase()}${mail?.Confidence_status.substring(1).toLowerCase()}`
                                                                    : ''}
                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                            <td >
                                                <div
                                                    style={{ stroke: isDarkMode ? "white" : "black" }}
                                                    className={`field-container-right flex justify-center items-center `}>

                                                    {
                                                        mail?.Confidence_status && (
                                                            <>
                                                                {
                                                                    mail?.Reply_status !== null && (
                                                                        <div
                                                                            onClick={() => handleDetailView(mail, mail.ID)}
                                                                            className="cursor-pointer hover:scale-105">
                                                                            {mail?.Reply_status ? (
                                                                                <ViewIconSVG height={20} width={20} />
                                                                            ) : (
                                                                                <EditIconSVG height={20} width={20} />
                                                                            )}
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            </td>
                                        </div>
                                    </div>
                                </tr>
                            )))
                                : <div className={`flex justify-center font-medium h-full items-center p-2  ${isDarkMode ? "text-white text-opacity-50 " : "text-black  text-opacity-50"}`}>No data found</div>
                            }
                        </tbody>
                }

            </table >

        </div >
    );
};

export default MailTable;