import React from "react";
import amaAvatar from "../../assets/amalogo.svg";
import bgImg from "../../assets/BackgroundChat.png";
import welcomeLogo from "../../assets/welcomeLogo.png";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const WelcomeCard: React.FC = () => {
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const userInfo = useSelector((state: RootState) => state.userInfo);

  return (
    <div className='w-full flex items-center justify-center gap-1 flex-col'>
      <div className={`border rounded-md w-full ${isDarkMode ? "bg-[#1B1B1B] border-[#444B5F]" : "bg-white border-[rgb(217,221,255)] "}  flex items-center justify-center flex-col gap-2 sm:gap-0 sm:flex-row py-2 `}
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}>
        <div className='h-full flex-1 flex items-center justify-center flex-col'>
          <div className='w-full sm:w-[80%] mx-auto'>
            <div className='w-full flex items-center justify-center sm:justify-start gap-2 font-poppins my-1'>
              <img className='w-7' src={amaAvatar} alt="" />
              <h3 className={`font-bold text-xs sm:text-sm ${isDarkMode ? "text-[#FFFFFF]" : "text-black"}`}>Ask me anything</h3>
            </div>
            <h6 className={`text-center sm:text-left ${isDarkMode?"text-[#FFFFFF]":"text-[#666666]"} text-[10px] sm:text-sm font-medium `}>{`Welcome ${userInfo.userInfoIsSuccess && userInfo.userInfoData && userInfo.userInfoData.displayName}, I’m AskMeAnything Bot, your personalized assistant`}</h6>
          </div>
        </div>
        <div className='w-full h-full flex-1 mx-auto'>
          <div className='flex items-center justify-center px-3'><img className='w-44 sm:w-56 h-full ' src={welcomeLogo} alt="" /></div>
        </div>
      </div>
      <div className='flex items-center justify-center w-[90%] sm:w-[80%] mx-auto'>
        <h3 className={` font-poppins font-normal text-[8px] sm:text-[12px] text-center ${isDarkMode ? "text-[#FFFFFF]" : "text-[#666666]"}`}>I’m here to make your experience with Ask me Anything smoother and more efficient. Lets embark on this journey together!</h3>
      </div>
    </div >
  );
};

export default WelcomeCard;