import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetRepliedEmailListPayload {
    pageNo: number;
    pageSize: number;
    emailId: string;
    confidenceStatus: string;
    search: string;
}

export interface IGetRepliedEmailListItem {
    ID:number
    Subject: string;
    BodyPreview: string;
    InternetMessageId: string;
    Sender_name: string;
    Sender_email: string;
    ReceivedDateTime: string;
    EmailContentType: string;
    EmailContentBody: string;
    Confidence_status: string | null;
    Reply_status: boolean | null;
    ResponseEmailContentBody: string;
    ResponseEmailContentType: string;
}

export interface IGetRepliedEmailListData {
    status_code: string;
    message: string;
    totalRecords: number;
    data: IGetRepliedEmailListItem[];

}

export interface IGetRepliedEmailListDataState {
    response: IGetRepliedEmailListData;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any | null;
}


const initialState: IGetRepliedEmailListDataState = {
    response: {
        status_code: "",
        message: "",
        totalRecords: null,
        data: []
    },
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null
};

export const getRepliedEmailListSlice = createSlice({
    name: "getRepliedEmailListSlice",
    initialState,
    reducers: {
        getRepliedEmailListStart: (state, action: PayloadAction<IGetRepliedEmailListPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getRepliedEmailListSuccess: (state, action: PayloadAction<IGetRepliedEmailListData>) => {
            state.response = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        getRepliedEmailListFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getRepliedEmailListStart, getRepliedEmailListSuccess, getRepliedEmailListFailure } = getRepliedEmailListSlice.actions;

export default getRepliedEmailListSlice.reducer;