// tokenDecodeSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TokenState {
    decodedToken: any | null;
    isLoading: boolean;
    success: boolean;
    isError: boolean;
    error: string | null;
}

const initialState: TokenState = {
    decodedToken: null,
    isLoading: false,
    success: false,
    isError: false,
    error: null,
};

const tokenDecodeSlice = createSlice({
    name: "tokenDecode",
    initialState,
    reducers: {
        decodeTokenRequest(state, action: PayloadAction<string>) {
            state.isLoading = true;
            state.isError = false;
            state.error = null;
        },
        decodeTokenSuccess(state, action: PayloadAction<any>) {
            state.decodedToken = action.payload;
            state.isLoading = false;
            state.success = true;
        },
        decodeTokenFailure(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.success = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const {
    decodeTokenRequest,
    decodeTokenSuccess,
    decodeTokenFailure,
} = tokenDecodeSlice.actions;

export default tokenDecodeSlice.reducer;
