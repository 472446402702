import React, { useEffect, useRef, useState } from "react";
import amaAvatar from "../../assets/amalogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import "./markdown.css";
import LineChartComponent from "../Charts/LineChartComponent";
import { setSelectedMessageId } from "../../redux/slices/selectedMessageSlice";
import WelcomeCard from "../Cards/WelcomeCard";
import { IActivity } from "../../redux/slices/ChatSlice";
import BarChartComponent from "../Charts/BarChartComponent";
import FeedbackCard from "../Cards/FeedbackCard";
import PieChartComponent from "../Charts/PieChartComponent";
import { FiCopy } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import avatar from "../../assets/avatar.png";
import CombinedGraphComponent from "../Charts/CombinedGraph";
import { userChatsRequest } from "../../redux/slices/getUserChatsSlice";
import AdditionalDetails from "../AdditionalDetails";
import { httpGet, httpPost } from "../../services";

TimeAgo.addDefaultLocale(en);

export const MsgScreen: React.FC = () => {

    const timeAgo = new TimeAgo("en-US");
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const userImg = useSelector((state: RootState) => state.userImg);

    const { userData, success } = useSelector((state: RootState) => state.createUser);
    const userId = success && userData ? userData?.ID : null;

    const [lastCopiedId, setLastCopiedId] = useState<string | null>(null);

    const handleCopy = (text: string, messageId: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setLastCopiedId(messageId);
            setTimeout(() => {
                setLastCopiedId(null);
            }, 1000);
        });
    };

    const selectedThreadId = useSelector((state: RootState) => state.chat.selectedThreadId);
    const threads = useSelector((state: RootState) => state.chat.threads);

    const selectedMessageId = useSelector((state: RootState) => state.selectedMessage.selectedMessageId);

    const [highlightedMsgId, setHighlightedMsgId] = useState<string | null>(null);

    const selectedChat = threads.find((chat) => chat.threadId === selectedThreadId);

    const messageRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const [errorMessage, setErrorMessage] = useState<{ activityId: string, msg: string }>({
        activityId: "",
        msg: ""
    }); // Error state for invalid link
    const checkLinkValidity = async (url: string, activityId: string) => {
        try {
            const response = await httpPost(`${process.env.REACT_APP_API_URL}/api/v1/checkLink`, { url: url });
            console.log("res", response);
            if (response.valid) {
                window.open(url, "_blank");
            } else {
                setErrorMessage({ activityId: activityId, msg: "* The link is expired or invalid." });
            }
        } catch (error) {
            setErrorMessage({ activityId: activityId, msg: "* The link is expired or invalid." });
        }
    };
    const handleButtonClick = (link: string, activityId: string) => {
        setErrorMessage({ activityId: "", msg: "" });
        checkLinkValidity(link, activityId); // Check the link validity before opening it
    };
    const scrollToBottom = () => {

        if (selectedChat && selectedChat?.activities?.length > 0) {
            setTimeout(() => {
                messagesEndRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
            });
        }
    };

    useEffect(() => {
        userId && dispatch(userChatsRequest({ userId }));
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [selectedThreadId]);


    useEffect(() => {
        // Scroll to the end of the messages
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });

    }, [selectedChat?.activities?.length]);


    //scrolling to particular conversation log
    useEffect(() => {
        if (selectedMessageId !== null) {
            const messageElement = messageRefs.current[selectedMessageId];
            if (messageElement) {
                messageElement.scrollIntoView({ behavior: "smooth", block: "start" });
                setHighlightedMsgId(selectedMessageId);
                setTimeout(() => setHighlightedMsgId(null), 2000); // Remove highlight after 2 seconds
                dispatch(setSelectedMessageId(null));
            }
        }
    }, [selectedMessageId, setHighlightedMsgId]);




    return (
        <div className="h-[90%] w-full">
            <div className="w-full h-full flex flex-grow flex-col items-end justify-end ">
                <div className="w-full overflow-y-auto no-scrollbar">
                    <div className={`flex flex-col w-full gap-2 my-1 justify-start p-5 rounded-md ${isDarkMode ? "bg-[#2D2C2D]" : "bg-[#F5F5F5] shadow shadow-[#19213D]-300/30"}`}>
                        <WelcomeCard />
                    </div>

                    {selectedChat && selectedChat?.activities?.length > 0 ? (
                        selectedChat?.activities
                            .filter((activity: IActivity) => activity.type === "message")
                            .map((activity: IActivity, index: number) => (
                                <div
                                    key={activity.id as string + index}
                                    ref={(el) => {
                                        if (el) {
                                            messageRefs.current[activity.id as string] = el;
                                        }
                                    }}
                                    className="self-end flex w-full p-1 msg-chat-item">
                                    {activity.from.id === "TML-Neural"
                                        ? (
                                            // USer Message
                                            <div className={`flex flex-col w-full gap-2 my-1 justify-start p-5 rounded-md ${isDarkMode ? "bg-[#2D2C2D]" : "bg-[#F5F5F5] shadow shadow-[#19213D]-300/30"}`}>
                                                <div className="flex items-center justify-between gap-2  ">
                                                    <div className="flex items-center justify-start gap-2 ">
                                                        {userImg.IsSuccess && userImg.data
                                                            ? <img className="w-5 h-5 rounded-full overflow-hidden" src={userImg.data} alt="User profile" title={userInfo.userInfoIsSuccess ? userInfo.userInfoData?.displayName : ""} />
                                                            : <img className="w-5 h-5 rounded-full overflow-hidden" src={avatar} alt="avatar" />
                                                        }
                                                        <div className='font-poppins font-normal flex items-start flex-col gap-1'>
                                                            <div className='flex items-center gap-3 text-xs w-full'>
                                                                <p className={`font-medium ${isDarkMode ? "text-white" : "text-[#aeaeae]"}`}>{userInfo.userInfoIsSuccess && userInfo.userInfoData ? userInfo.userInfoData.displayName : ""}</p>
                                                                <span className={`${isDarkMode ? "text-[#F0F2F5]" : "text-[#CCCCCC]"} `}> | </span>
                                                                <p className='text-[10px] text-[#aeaeae]'>{timeAgo.format(new Date(activity?.timestamp), "round-minute")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => handleCopy(activity?.text, activity.id)}
                                                        className={` ${isDarkMode ? "text-[#aeaeae]" : "text-[#777676]"}`}>
                                                        {lastCopiedId === activity.id
                                                            ? <div className="flex flex-row items-center gap-1">
                                                                <FaCheck />
                                                                <span className="text-[12px]">Copied!</span>
                                                            </div>
                                                            : <FiCopy className="text-[13px] hover:text-[#6d6b6b]" title="copy" />}
                                                    </div>
                                                </div>

                                                <div className='font-poppins flex items-start flex-col gap-1 w-full'>
                                                    <div className={`font-normal text-[12px] 2xl:text-[14px] w-full break-words  ${isDarkMode ? "text-[#aeaeae]" : "text-[#777676]"}`}>
                                                        <h1>{activity?.text}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        : (
                                            //  Bot Message
                                            <div className={`flex flex-col w-full gap-2 my-1 justify-start p-5 rounded-md ${isDarkMode ? "bg-[#2D2C2D]" : "bg-[#F5F5F5] shadow shadow-[#19213D]-300/30"}`}>
                                                <div className="flex items-center justify-start ">
                                                    <img
                                                        className="w-5 h-5 mr-3"
                                                        src={amaAvatar}
                                                        alt="Bot avatar"
                                                    />
                                                    <div className='flex items-center gap-3 text-xs'>
                                                        <p className={`font-medium ${isDarkMode ? "text-white" : "text-[#aeaeae]"}`}>AskMeAnything</p>
                                                        <span className={`${isDarkMode ? "text-[#F0F2F5]" : "text-[#CCCCCC]"} `}> | </span>
                                                        <p className='text-[10px] font-normal text-[#aeaeae]'>{timeAgo.format(new Date(activity?.timestamp), "round-minute")} </p>
                                                    </div>

                                                </div>
                                                <div className='font-poppins flex items-start flex-col gap-1 '>
                                                    <div className={`font-normal text-[12px] 2xl:text-[14px]  ${isDarkMode ? "text-[#aeaeae]" : "text-[#777676]"} w-full`}>
                                                        {
                                                            activity.type === "message"
                                                                ? (activity?.channelData?.output
                                                                    ? <div className="flex gap-3 flex-col w-auto">
                                                                        {activity.channelData.output.map((obj: any, index: number) => {
                                                                            if (obj.type === "pie graph" && Array.isArray(obj.data?.graph_data) && obj.data.graph_data.length) {
                                                                                return <PieChartComponent activityID={index} key={index} data={obj.data} />;
                                                                                // } else if ((obj.type === "bar graph" || obj.type === "line graph") && Array.isArray(obj.data?.x_axis_data) && Array.isArray(obj.data?.y_data_list)) {
                                                                                //     return <CombinedGraphComponent activityID={index} key={index} data={obj.data} chartType={obj.type} />;

                                                                            } else if (obj.type === "bar graph" && Array.isArray(obj.data?.x_axis_data) && Array.isArray(obj.data?.y_data_list)) {
                                                                                return <BarChartComponent activityID={index} key={index} data={obj.data} />;
                                                                            } else if (obj.type === "line graph" && Array.isArray(obj.data?.x_axis_data) && Array.isArray(obj.data?.y_data_list)) {
                                                                                return <LineChartComponent activityID={index} key={index} data={obj.data} />;
                                                                            } else if (obj.type === "url") {  // Ensure obj.text exists
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <div className="flex flex-col md:flex-row items-start gap-2 justify-start ">
                                                                                            <div className="flex flex-row gap-2">
                                                                                                <p>Table is too large, You can download the file from the link:</p>
                                                                                                <button
                                                                                                    onClick={() => handleButtonClick(obj.text, activity.id)} // Wrap in arrow function to call on click
                                                                                                    className="text-[#31C6BB] font-poppins text-[13px] 2xl:text-[14px]"
                                                                                                >
                                                                                                    Download file
                                                                                                </button>
                                                                                            </div>
                                                                                            {errorMessage.activityId == activity.id && (
                                                                                                <p className="text-red-500 text-sm ">{errorMessage.msg}</p>
                                                                                            )}

                                                                                        </div>

                                                                                        {obj?.ccdetails && obj.ccdetails !== "NA" &&
                                                                                            <div>
                                                                                                <AdditionalDetails url={obj.ccdetails} />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <div className={` ${isDarkMode ? "scrollbar-dark" : "scrollbar-light"}`} key={index}>
                                                                                        <Markdown className={` markdown-summary ${isDarkMode ? "markdown-content-dark scrollbar-dark" : "markdown-content scrollbar-light"} no-scrollbar`} remarkPlugins={[remarkGfm]}>
                                                                                            {obj.text}
                                                                                        </Markdown>
                                                                                        {obj?.ccdetails && obj.ccdetails !== "NA" &&
                                                                                            <div><AdditionalDetails url={obj.ccdetails} />
                                                                                            </div>}
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })}
                                                                        <FeedbackCard activity={activity} />
                                                                    </div>
                                                                    :
                                                                    <div>

                                                                        <h1>{activity?.text}</h1>
                                                                        <FeedbackCard activity={activity} />
                                                                    </div>
                                                                ) : ""

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            ))
                    )
                        : ""
                    }
                    <div ref={messagesEndRef}></div>

                </div>
            </div >
        </div >
    );
};