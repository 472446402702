import React, { useState } from "react";
import "./article.css";
import { Link } from "react-router-dom";
import bg1 from "../../assets/ArticlesBg/bg1.png";
import bg2 from "../../assets/ArticlesBg/bg2.png";
import bg3 from "../../assets/ArticlesBg/bg3.png";
import bg4 from "../../assets/ArticlesBg/bg4.png";

interface ArticleCardProps {
    title: string;
    description: string;
    image: string | null;
    url: string;
    index: number;
}

const gradientStyle = {
    background: "linear-gradient(to bottom,rgba(24, 59, 86, 0.4) 0.01%, rgba(21, 37, 50) 100%)",
};

const backgroundImages = [bg1, bg2, bg3, bg4];

const ArticleCard: React.FC<ArticleCardProps> = ({ title, description, image, url, index }) => {
    const backgroundImage = backgroundImages[index % backgroundImages.length];
    const [imgVisible, setImgVisible] = useState(true);

    return (
        <Link to={url} target={url === null ? "_self" : "_blank"}>
            <div className="shadow-lg overflow-hidden relative h-[250px] group rounded-lg">
                <div
                    className="absolute inset-0 w-full h-full bg-cover bg-center"
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                ></div>
                {image && imgVisible && (
                    <img src={image}
                        alt=""
                        className="absolute inset-0 w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-100"
                        onError={() => setImgVisible(false)}
                    />
                )}
                <div
                    className="absolute inset-0 transition-opacity duration-300"
                    style={gradientStyle}
                ></div>
                <div className="absolute bottom-2 left-0 right-0 p-4 transition-transform duration-300 transform group-hover:translate-y-[-15px] max-h-[70%]">
                    <h2 className="text-md font-sans font-normal text-white mb-2">{title}</h2>
                    <p className="w-full text-white font-poppins font-normal text-[10px] line-clamp-2 break-words">
                        {description}
                    </p>
                </div>
            </div>
        </Link>
    );
};

export default ArticleCard;
