import React from "react";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import avatar from "../../../assets/avatar.png";
import { getInitials } from "../../../App";
import { _kc } from "../../../services/keyCloak/UserService";

export const ProfileHeader: React.FC = () => {
    const userImg = useSelector((state: RootState) => state.userImg);
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    return (
        <div className={"w-full h-full flex flex-row justify-between items-center "}>
            <div className='w-full h-full flex flex-row items-center justify-start gap-2'>
                <div className="h-full  bg-transparent  cursor-pointer flex items-center justify-start text-white font-semibold">
                    {userImg.IsSuccess && userImg.data
                        ? <img className="h-[38px] w-[38px]   border border-[#31C6BB] rounded-full overflow-hidden" src={userImg.data} alt="User profile" title={userInfo.userInfoIsSuccess ? userInfo.userInfoData?.displayName : ""} />
                        : userInfo.userInfoIsSuccess && userInfo.userInfoData
                            ? <div className="text-[#31C6BB]   border border-[#31C6BB] w-[38px] h-[38px] flex items-center justify-center rounded-full" title={userInfo.userInfoData.displayName}>{getInitials(userInfo.userInfoData?.givenName, userInfo.userInfoData?.surname)}</div>
                            : <img className="h-[38px] w-[38px]  border border-[#31C6BB]  rounded-full overflow-hidden" src={avatar} alt="avatar" />
                    }
                    {/* <div className="text-[#31C6BB]   border border-[#31C6BB] w-[38px] h-[38px] flex items-center justify-center rounded-full" title={_kc.tokenParsed.name}>{getInitials(_kc.tokenParsed?.given_name, _kc.tokenParsed?.family_name)}</div> */}





                </div>
                <p className={`w-[80%]  text-[11px]  2xl:text-[13px] ${isDarkMode ? "text-white text-opacity-80" : "text-[#666F8D]"} font-poppins font-medium inline break-words`}>{userInfo.userInfoIsSuccess && userInfo.userInfoData ? userInfo.userInfoData.displayName : ""}</p>
                {/* <p className={`w-[80%]  text-[11px]  2xl:text-[13px] ${isDarkMode ? "text-white text-opacity-80" : "text-[#666F8D]"} font-poppins font-medium inline break-words`}>{_kc.tokenParsed.name}</p> */}


            </div>
           
        </div>
    );
};
