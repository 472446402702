import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ActiveMailId {
    activeTableMailId: number;
}

const initialState: ActiveMailId = {
    activeTableMailId: null,
};

const activeMailIdSlice = createSlice({
    name: "activeMailId",
    initialState,
    reducers: {
        setActiveMailId: (state, action: PayloadAction<number>) => {
            state.activeTableMailId = action.payload;
        },
    },
});

export const { setActiveMailId } = activeMailIdSlice.actions;
export default activeMailIdSlice.reducer;
