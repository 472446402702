import { createSlice } from "@reduxjs/toolkit";

interface DrawerState {
    isDrawerActive: boolean;
}

const initialState: DrawerState = {
    isDrawerActive: false,
};

const drawerSlice = createSlice({
    name: "drawer",
    initialState,
    reducers: {
        activeDrawer: (state) => {
            state.isDrawerActive = !state.isDrawerActive;
        }
    }
});

export const { activeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;