import React, { useState, useEffect, useRef } from "react";
import { IoSendSharp } from "react-icons/io5";
import { FaChartLine, FaRegChartBar, FaChartPie } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { handleSendActivity } from "../ChatScreen";

const ChatScreenFooter: React.FC = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const selectedThreadId = useSelector((state: RootState) => state.chat.selectedThreadId);
    const { data, isSuccess } = useSelector((state: RootState) => state.matchRole);
    const { userData, success } = useSelector((state: RootState) => state.createUser);
    const userId = success && userData ? userData?.ID : null;
    const matchedRole = isSuccess && data ? data.find((appData) => appData.subDomainId === 2 && appData.domainId === 2)?.role : null;

    const [query, setQuery] = useState("");
    const [chartType, setChartType] = useState("line graph");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleSendActivity(query, chartType, selectedThreadId, matchedRole ?? null, userId);
        setQuery("");
    };

    const handleChartTypeChange = (type: string) => {
        setChartType(type);
    };

    const chartIcon = () => {       
        switch (chartType) {
            case "bar graph":
                return <FaRegChartBar className={"w-5 h-5 text-[#31C6BB]"} />;
            case "pie graph":
                return <FaChartPie className={"w-5 h-5 text-[#31C6BB]"} />;
            case "line graph":
            default:
                return <FaChartLine className={"w-5 h-5 text-[#31C6BB]"} />;
        }
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);


    return (
        <div className="w-full">
            <div className="relative">
                <form className="form flex items-center" onSubmit={handleSubmit}>
                    <input
                        type="input"
                        id="default-search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className={`block w-full text-sm border outline-none px-[15px] py-[15px] pr-24 ${isDarkMode
                            ? "bg-[#212121] border-[#FFFFFF] border-opacity-30 placeholder-[#E5E5E5] placeholder-opacity-30 text-[#E5E5E5] text-opacity-80"
                            : "border-[#666F8D] border-opacity-30 bg-white placeholder-[#666F8D] placeholder-opacity-30  text-[#666F8D] text-opacity-80"
                            } rounded-lg`}
                        placeholder="Type your query... Ex: Last 6 months OTIF trend?"
                        required
                    />
                    <div className="relative" ref={dropdownRef}>
                        <button
                            type="button"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                            className="absolute border inset-y-0 end-16  bg-transparent border-none flex items-center justify-center text-xl">
                            {chartIcon()}
                        </button>
                        {dropdownOpen && (
                            <div className={`absolute right-0 bottom-full mb-2 w-40 border rounded-lg shadow-lg z-10 ${isDarkMode
                                ? "bg-[#212121] border-[#FFFFFF] border-opacity-30 placeholder-[#E5E5E5] placeholder-opacity-30 text-[#E5E5E5] "
                                : "border-[#666F8D] border-opacity-30 bg-white placeholder-[#666F8D] placeholder-opacity-30 text-[#666F8D] "
                                } text-opacity-80`}>
                                <ul className="">
                                    <li onClick={() => handleChartTypeChange("line graph")} className={`cursor-pointer px-4 py-2 flex justify-between items-center ${isDarkMode ? "hover:bg-[#FFFFFF] " : "hover:bg-[#c0bebe] "} hover:bg-opacity-10`}>
                                        Line Chart
                                        {chartType === "line graph" && <div className="w-2 h-2 bg-[#31C6BB] rounded-full ml-2"></div>}
                                    </li>
                                    <li onClick={() => handleChartTypeChange("bar graph")} className={`cursor-pointer px-4 py-2 flex justify-between items-center ${isDarkMode ? "hover:bg-[#FFFFFF] " : "hover:bg-[#c0bebe] "} hover:bg-opacity-10`}>
                                        Bar Chart
                                        {chartType === "bar graph" && <div className="w-2 h-2 bg-[#31C6BB] rounded-full ml-2"></div>}
                                    </li>
                                    <li onClick={() => handleChartTypeChange("pie graph")} className={`cursor-pointer px-4 py-2 flex justify-between items-center ${isDarkMode ? "hover:bg-[#FFFFFF] " : "hover:bg-[#c0bebe] "} hover:bg-opacity-10`}>
                                        Pie Chart
                                        {chartType === "pie graph" && <div className="w-2 h-2 bg-[#31C6BB] rounded-full ml-2"></div>}
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <button type="submit" className="absolute bg-[#31C6BB] flex items-center justify-center inset-y-0 end-1 my-2 mx-1 rounded-lg text-black">
                        <IoSendSharp className={`w-5 h-4 m-2 ${isDarkMode ? "text-black" : "text-white"} rounded-md`} />
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ChatScreenFooter;
