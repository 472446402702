import React from "react";
import NoDataImg from "../../assets/noData.png";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";


interface NoDataCardProps {
    message: string;
}

const NoDataCard: React.FC<NoDataCardProps> = ({ message }) => {

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    return (
        <div className={`h-full w-full flex items-center justify-center  py-5 gap-2 flex-col pt-10 border  ${isDarkMode ? "border-[#FFFFFF] border-opacity-10" : "border-gray-200"} `}>
            <div><img className="w-32" src={NoDataImg} alt="COMING SOON" /></div>
            <div><h1 className='text-[#636363] text-opacity-45 font-poppins font-bold text-md'>{message}</h1></div>
        </div>
    );
};

export default NoDataCard;