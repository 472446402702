import { ReactElement } from "react";
import img1 from "../../assets/carouselImg/img1.png";
import img2 from "../../assets/carouselImg/img2.png";
import img3 from "../../assets/carouselImg/img3.png";
import img4 from "../../assets/carouselImg/img4.png";
import img5 from "../../assets/carouselImg/img5.png";

export type CarouselImageType = {
  heading: string;
  title: string;
  content: string;
  img: string;
  leftColor: string;
  rightColor: string;

};

export const CarouselImageData: CarouselImageType[] = [
  {
    heading: "Discover Tata Neural",
    title: "Your Gateway to Centralized AI and Custom Applications ",
    content: "Tata Neural integrates cutting-edge AI with your business applications, providing a unified platform for seamless operations.",
    img: img1,
    leftColor: "#0D2927",
    rightColor: "#2F8F88"
  },
  {
    heading: "Discover Tata Neural",
    title: "All Your AI and Custom Applications in One Place",
    content: "Experience the convenience of managing and accessing all your AI tools and custom applications through a single, centralized portal.",
    img: img2,
    leftColor: "#002B76",
    rightColor: "#2191D2"
  },
  {
    heading: "User-Friendly Interface",
    title: "Effortlessly Navigate Our Intuitive Platform",
    content: "Our platform is designed with user experience in mind, ensuring smooth and intuitive navigation for all users, regardless of technical expertise.",
    img: img3,
    leftColor: "#461F72",
    rightColor: "#D12D6A"
  },
  {
    heading: "Enhanced Efficiency",
    title: "Streamline Your Workflow with Quick Access to AI Solutions",
    content: "Save valuable time by integrating AI solutions that automate repetitive tasks and optimize your workflows for maximum efficiency.",
    img: img4,
    leftColor: "#412500",
    rightColor: "#F59C23"

  },
  {
    heading: "Financial data analysis",
    title: "Make Data-Driven Financial Decisions with AI-Powered Insights",
    content: "Leverage AI to analyze financial data, forecast trends, and optimize budgeting and investment strategies.",
    img: img5,
    leftColor: "#1C232D",
    rightColor: "#00BFF3"
  },
];
