/* eslint-disable quotes */
import React from "react";
import { useSlate } from "slate-react";
import { Editor, Transforms, Element as SlateElement } from "slate";
import { FaBold } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

// Define custom types
export type CustomElement =
    | { type: 'paragraph'; children: CustomText[]; }
    | { type: 'heading-one'; children: CustomText[]; }
    | { type: 'heading-two'; children: CustomText[]; }
    | { type: 'block-quote'; children: CustomText[]; }
    | { type: 'code-block'; children: CustomText[]; };

export type CustomText = {
    text: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    strikethrough?: boolean;
    code?: boolean;
    superscript?: boolean;
    subscript?: boolean;
};

// Helper functions to toggle formatting
const isMarkActive = (editor: Editor, format: keyof Omit<CustomText, 'text'>) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

const toggleMark = (editor: Editor, format: keyof Omit<CustomText, 'text'>) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isBlockActive = (editor: Editor, format: CustomElement['type']) => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    }));

    return !!match;
};

const toggleBlock = (editor: Editor, format: CustomElement['type']) => {
    const isActive = isBlockActive(editor, format);

    Transforms.setNodes(
        editor,
        { type: isActive ? 'paragraph' : (format as SlateElement['type']) },
        { match: (n) => SlateElement.isElement(n) && (n as CustomElement).type !== undefined && Editor.isBlock(editor, n) }
    );
};

// ToolbarButton component
const ToolbarButton: React.FC<{ format: string; icon: string; }> = ({ format, icon }) => {
    const editor = useSlate();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);


    return (
        <button
            className={`border w-[30px] h-[30px] rounded-md  font-poppins hover:scale-105 ${isDarkMode ?" border-white border-opacity-35":"border-black border-opacity-15"} `}
            onMouseDown={(event) => {
                event.preventDefault();
                if (['bold', 'italic', 'underline', 'strikethrough', 'code', 'superscript', 'subscript'].includes(format)) {
                    toggleMark(editor, format as keyof Omit<CustomText, 'text'>);
                } else {
                    toggleBlock(editor, format as CustomElement['type']);
                }
            }}

            style={{
                fontWeight: (isMarkActive(editor, format as keyof Omit<CustomText, 'text'>) ||
                    isBlockActive(editor, format as CustomElement['type'])) ? 'bold' : 'normal',
                // padding: '5px 10px',
                // cursor: 'pointer',
                // border: 'none',
                // background: 'none',
            }}
        >
            {icon}
        </button>
    );
};

// Toolbar component
export const Toolbar: React.FC = () => {
    return (
        <div className="flex flex-row items-center p-2 gap-3">
            <ToolbarButton format="bold" icon="B" />
            <ToolbarButton format="italic" icon="I" />
            <ToolbarButton format="underline" icon="U" />
            {/* <ToolbarButton format="strikethrough" icon="S" /> */}
            {/* <ToolbarButton format="code" icon="{}" />
            <ToolbarButton format="superscript" icon="x²" />
            <ToolbarButton format="subscript" icon="x₂" /> */}
            {/* <ToolbarButton format="heading-one" icon="H1" />
            <ToolbarButton format="heading-two" icon="H2" /> */}
            {/* <ToolbarButton format="blockquote" icon="“”" /> */}
            {/* You can add more ToolbarButtons here for underline, strikethrough, etc. */}
        </div>
    );
};