// UserSaga.js
import { put, takeLatest } from "redux-saga/effects";
import { httpDelete, httpPost } from "../../../services";
import { PayloadAction } from "@reduxjs/toolkit";
import { createUserFailure, createUserRequest, createUserSuccess, IUser } from "../../slices/createUserSlice";
import { restoreChats } from "../../slices/ChatSlice";
import { IUserChats, userChatsFailure, userChatsRequest, userChatsSuccess } from "../../slices/getUserChatsSlice";
import { deleteUserChatsFailure, deleteUserChatsRequest, deleteUserChatsSuccess, IDeleteChatApiResponse } from "../../slices/deleteUserChatsSlice";
import { feedbackApiRequestFailure, feedbackApiRequestStart, feedbackApiRequestSuccess, IfeedbackApiResponse, IfeedBackPayload } from "../../slices/updateFeedbackSlice";

interface ICreateUser {
    success: boolean;
    data: IUser;
}
interface IGetUserChats {
    success: boolean;
    data: IUserChats[];
}
interface IupdateFeedback {
    success: boolean;
    data: IfeedbackApiResponse;
}
interface IDeleteChats {
    success: boolean;
    data: IDeleteChatApiResponse;
}


function* createUserSaga(action: PayloadAction<{ email: string, firstName: string, lastName: string, userDomainId: string, profileDesc: string }>): Generator<any, void, ICreateUser> {
    try {
        const { email, firstName, lastName, userDomainId, profileDesc } = action.payload;
        const response: ICreateUser = yield httpPost(`${process.env.REACT_APP_API_URL}/api/v1/user/create`, { email, firstName, lastName, userDomainId, profileDesc });
        if (response.success) {
            yield put(createUserSuccess(response?.data));
        }
    } catch (error) {
        yield put(createUserFailure(error.message));
    }
}

function* getUserChatsSaga(action: PayloadAction<{ userId: number }>): Generator<any, void, IGetUserChats> {
    try {
        const { userId } = action.payload;
        const response: IGetUserChats = yield httpPost(`${process.env.REACT_APP_API_URL}/api/v1/user/chats`, { userId });
        yield put(restoreChats(response.data));
        yield put(userChatsSuccess(response.data));
    } catch (error) {
        yield put(userChatsFailure(error.message));
    }
}

function* deleteUserChatsSaga(action: PayloadAction<{ userId: number }>): Generator<any> {
    try {
        const { userId } = action.payload;
        const response: any = yield httpDelete(`${process.env.REACT_APP_API_URL}/api/v1/user/chats?userId=${userId}`);

        yield put(deleteUserChatsSuccess(response.data));
    } catch (error) {
        yield put(deleteUserChatsFailure(error.message));
    }
}

function* updateFeedbackSaga(action: PayloadAction<IfeedBackPayload>): Generator<any, void, IupdateFeedback> {
    try {
        const { userId, threadId, messageId, feedback, cachePayload } = action.payload;
        const response: IupdateFeedback = yield httpPost(`${process.env.REACT_APP_API_URL}/api/v1/user/feedback`, { userId, threadId, messageId, feedback, cachePayload });
        if (response.success) {
            yield put(feedbackApiRequestSuccess({ ...response.data, feedbackId: messageId, feedbackAction: feedback }));
        }
    } catch (error) {
        yield put(feedbackApiRequestFailure(error.message));
    }
}

function* watchUserDetails() {
    yield takeLatest(createUserRequest.type, createUserSaga);
    yield takeLatest(userChatsRequest.type, getUserChatsSaga);
    yield takeLatest(deleteUserChatsRequest.type, deleteUserChatsSaga);
    yield takeLatest(feedbackApiRequestStart.type, updateFeedbackSaga);

}

export default watchUserDetails;
