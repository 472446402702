import React from "react";
import warningImg from "../../assets/warning.png";
import { RootState } from "../.././redux/store";
import { useSelector } from "react-redux";



const ConfirmationCard: React.FC<{ onDelete: () => void, onCancel: () => void }> = ({ onDelete, onCancel }) => {
    const { isLoading, isError } = useSelector((state: RootState) => state.deleteUserChats);

    return (
        <div className="flex items-center justify-center">
            <div className="flex flex-col gap-2 items-center justify-center p-6  bg-white rounded-lg shadow-md w-[400px]">

                <img className="w-12" src={warningImg} alt="warning img" />
                <div className=" w-full h-14 flex flex-col items-center justify-center">
                    {
                        isError
                            ? <p className="text-red-400 font-poppins font-medium">Try Again...</p>
                            :
                            <div className="text-center flex flex-col gap-[2px] h-14 ">
                                <h2 className="text-lg font-semibold font-poppins">Are you sure?</h2>
                                <p className="text-sm text-gray-600   text-center">
                                    Do you really want to delete all conversations?
                                </p>
                            </div>
                    }
                </div>

                <div className="flex space-x-4">

                    <button
                        onClick={onDelete}
                        className=" relative w-20 text-white px-4 py-1 bg-[#cf5f5f]  rounded-md hover:bg-[#da4848] focus:outline-none transition-all ease-in "
                    >
                        {isLoading
                            ? <div className="absolute inset-0 flex items-center justify-center ">
                                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-[#FFFFFF] "></div>
                            </div>
                            : " Delete"
                        }
                    </button>

                    <button
                        onClick={onCancel}
                        className=" border px-4 py-1  text-black rounded-md hover:bg-white focus:outline-none  transition-all ease-in "
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );

};
export default ConfirmationCard;
