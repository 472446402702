import { createSlice, PayloadAction } from "@reduxjs/toolkit";



interface IUserImgState {
    IsLoading: boolean;
    IsSuccess: boolean;
    IsError: boolean;
    data: string | null;
    Error: string | null;
}

const initialState: IUserImgState = {
    IsLoading: false,
    IsSuccess: false,
    IsError: false,
    data: null,
    Error: null,
};

const userImgApiSlice = createSlice({
    name: "userImg",
    initialState,
    reducers: {
        userImgRequestStart(state, action: PayloadAction<{ token: string }>) {
            state.IsLoading = true;
            state.IsSuccess = false;
            state.IsError = false;
            state.Error = null;
        },
        userImgRequestSuccess(state, action: PayloadAction<string>) {
            state.IsLoading = false;
            state.IsSuccess = true;
            state.IsError = false;
            state.data = action.payload;
        },
        userImgRequestFailure(state, action: PayloadAction<string>) {
            state.IsLoading = false;
            state.IsSuccess = false;
            state.IsError = true;
            state.Error = action.payload;
        },
    },
});


export const { userImgRequestStart, userImgRequestSuccess, userImgRequestFailure } = userImgApiSlice.actions;
export default userImgApiSlice.reducer;
