import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import fallbackLoaderLight from "../../assets/fallbackLoaderLight.gif";
import fallbackLoaderDark from "../../assets/fallbackLoaderDark.gif";


const LazyFallback: React.FC = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
      
    return (
        <div className='w-[100vw] h-[100vh] bg-transparent flex justify-center items-center'>
            {
                // isDarkMode
                //     ? <img src={fallbackLoaderDark} alt="" />
                //     : 
                <img  className='w-[350px]' src={fallbackLoaderLight} alt="" />
            }
        </div>
    );
};

export default LazyFallback;
