
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface HelpAndSupportPopupProps {
    onClose: () => void;
}

const HelpAndSupportPopup: React.FC<HelpAndSupportPopupProps> = ({ onClose }) => {
    const popupRef = useRef<HTMLDivElement>(null);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    const [isAdditionalInfoVisible, setIsAdditionalInfoVisible] = useState(false); // State to track visibility

    // Handle click outside the popup content
    const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            onClose(); // Trigger the onClose function if clicked outside
        }
    };

    useEffect(() => {
        // Add event listener for click outside
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={popupRef} className="  w-full fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 shadow-lg">
            <div className={`border ${isDarkMode ? "bg-[#28292a] border-black" : "bg-white"}   p-6 rounded-md shadow-lg flex flex-col  justify-center gap-4  w-[45%]`}>
                <h2 className={`text-lg text-center font-bold 2xl:text-2xl ${isDarkMode ? "text-[#b1b0b0]" : ""} `}>Help & Support</h2>
                {!isAdditionalInfoVisible && (<div className="flex flex-col gap-2">
                    <h5 className="text-base font-semibold  2xl:text-lg text-[#777676] font-poppins">User Query Guidelines</h5>
                    <ul className={` pl-1 list-disc ml-4 text-[10px]  2xl:text-[13px] font-poppins flex flex-col gap-1  ${isDarkMode ? "text-[#b1b0b0]" : ""} `}>
                        <li>
                            <strong>Cost Centre & Cost Element & Cost Center Hierarchy Queries:</strong> Include one or both keywords in addition to the fiscal year (FY) and business unit (PV/CV/EV) into your query.
                        </li>
                        <li>
                            <strong>Data Availability:</strong> The data shared has special characters and incomplete values, hence it is very much recommended to use the exact entity name in case of incorrect answers or &quot;NO data found&quot; cases. Also all queries should be based on master data. If the data isn&apos;t in the master data, you&apos;ll receive a &quot;NO data found&quot; message.
                        </li>
                        <li>
                            <strong>Incorrect Values:</strong> Start a new conversation if you receive incorrect values or error messages.
                        </li>
                        <li>
                            <strong>Bot Priority:</strong> Since there is a chance that an entity of cost center may be present in some other KPI such as cost element (For example, there is a cost center named canteen and also a cost element group named canteen), the bot works on a priority basis (if the keyword is not mentioned) which is <strong> Cost Element Group &gt; Cost Element &gt; Cost Center Hierarchy &gt; Cost Center.</strong>
                        </li>
                        <li>
                            <strong>Role Based Access:</strong> RBAC policy has been implemented for PV/CV users and the user will have to specify the business unit for each query. For example, give me publicity expenses for period 01 FY 2024 for CV.
                        </li>
                    </ul>
                </div>)}

                {isAdditionalInfoVisible && (
                    <div className="flex flex-col gap-2">
                        <h5 className="text-base font-semibold  2xl:text-lg  font-poppins text-[#777676]">Bot Usage Rules (Year & Month/Period Combinations):</h5>
                        <ul className={`pl-1 list-disc ml-4 text-[10px]   2xl:text-[13px] font-poppins  flex flex-col gap-1  ${isDarkMode ? "text-[#b1b0b0]" : ""} `}>
                            <li>
                                <strong>Supported Formats:</strong> Use either the year alone or year + month, or year + period.
                            </li>
                            <li>
                                <strong>Month vs. Period:</strong> Use month names (e.g., Jan, Feb) or numerical periods (e.g., 01, 02) correctly. For example (for month April FY 2024 or for period 01 FY 2024)
                            </li>
                            <li>
                                <strong>Quarter/Yearly Data:</strong> Quarter, half-year, and full-year queries automatically use periods.
                            </li>
                            <li>
                                <strong>Fiscal Year Focus:</strong> The bot always operates on the fiscal year, even if not explicitly mentioned.
                            </li>
                            <li>
                                <strong>No Calendar Year:</strong> The bot does not understand the calendar year concept.
                            </li>
                            <li>
                                <strong>Missing Year:</strong> If the year isn&apos;t mentioned, the bot will ask for a period.
                            </li>
                            <li>
                                <strong>Cost Element and Group:</strong> If the query has cost element keyword mentioned before the value then it&apos;ll display the results for cost element group and if the query has cost element description keyword mentioned before the value then the results will be displayed for the cost element description.
                            </li>
                            <li>
                                <strong>Cost Center Hierarchy:</strong> Since the amount of data is too large, the results are shown in  an excel file.
                            </li>
                            <li>
                                <strong>Clear Conversations:</strong>  Since we are using GPT, it is advised to have a single chat session for a single business unit i.e. if users start their conversation with cv and want to check pv then they can type the pv query in a new chat session(if the user has the access to both).
                            </li>
                            <li>Graph types such as line chart, bar chart, and pie chart will be generated for hierarchy expenses when the token range is 13,000 or less. If the token range exceeds 13,000, the graph will be disabled.
                            </li>
                            <li>In hierarchy, the sum of each sub-level in the hierarchy will be displayed provided the data has 50 rows or less, with details of all cost centers expenses under that particular hierarchy in the excel sheet.
                            </li>
                        </ul>
                    </div>
                )}

                <div className={`w-full flex items-end flex-row justify-center gap-4 ${isAdditionalInfoVisible ? "flex-row-reverse" : ""}`}>
                    <button className={`mt-[2px] w-20 2xl:w-28  text-[#31C6BB] px-3  py-1 2xl:py-2  ${isDarkMode ? "border border-[#1D1E1F]" : "border"} shadow-md rounded-md font-medium text-center flex justify-center items-center`} onClick={onClose}>
                        Close
                    </button>
                    <button
                        className="mt-[2px] w-20 2xl:w-28 bg-[#31C6BB] text-white px-3 py-1 2xl:py-2  shadow-md  rounded-md font-medium text-center flex justify-center items-center"
                        onClick={() => setIsAdditionalInfoVisible(!isAdditionalInfoVisible)}
                    >
                        {isAdditionalInfoVisible ? "Previous" : "Next"}
                    </button>
                </div>
            </div>
        </div >
    );
};

export default HelpAndSupportPopup;