import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setSelectedMessageId } from "../../redux/slices/selectedMessageSlice";
import "./ConvSection.css";

const ConvSection: React.FC = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const selectedThreadId = useSelector((state: RootState) => state.chat.selectedThreadId);
    const threads = useSelector((state: RootState) => state.chat.threads);
    const selectedChat = threads.find(chat => chat.threadId === selectedThreadId);
    const [animateId, setAnimateId] = useState<string | null>(null); // State to trigger animation
    const prevActivitiesRef = useRef(selectedChat?.activities || []);
    const hasRendered = useRef(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const handleLogClick = (messageId: string) => {
        dispatch(setSelectedMessageId(messageId));
    };

    useEffect(() => {
        // Scroll to the end of the messages
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });

    }, [selectedChat?.activities]);

    useEffect(() => {
        if (!hasRendered.current) {
            hasRendered.current = true;
            prevActivitiesRef.current = selectedChat?.activities || [];
            return;
        }
        const prevActivities = prevActivitiesRef.current;
        const currentActivities = selectedChat?.activities || [];

        // Check if a new activity with from.id === "TML-Neural" is added
        if (currentActivities.length > prevActivities.length) {
            const newActivities = currentActivities.slice(prevActivities.length);
            const neuralActivity = newActivities.find(activity => activity.from.id === "TML-Neural");

            if (neuralActivity) {
                // Set animation to the new activity's ID
                setAnimateId(neuralActivity.id);
                setTimeout(() => setAnimateId(null), 500); // Reset after 500ms
            }
        }

        // Update the previous activities reference
        prevActivitiesRef.current = currentActivities;

    }, [selectedChat?.activities, selectedThreadId]);

    useEffect(() => {
        // Clear the animation ID when thread changes
        setAnimateId(null);
        // Reset prevActivitiesRef to new thread activities
        prevActivitiesRef.current = selectedChat?.activities || [];
    }, [selectedThreadId]);

    return (
        <div className={`h-[98vh] flex flex-col gap-3 w-full rounded-sm  ${isDarkMode ? " bg-[#1B1B1D]" : "shadow shadow-[#353E5C]-[7%]  bg-white"}`}>
            <div className=' flex flex-col items-center justify-between font-poppins  py-4'>
                <h3 className={`${isDarkMode ? "text-white" : "text-[#666F8D]"} m-2 text-base 2xl:text-xl`}>Conversation log</h3>
                <h6 className={` ${isDarkMode ? "text-[#3C6562]" : "text-[#666F8D]"} text-center m-2 text-[10px] 2xl:text-[14px] text-wrap`}>Dive into the logs to review past discussions and gain insights that enhance your journey with us. </h6>
            </div>

            <div className={` w-full overflow-y-auto ${isDarkMode ? "scrollbar-dark" : "scrollbar-light"}  pb-4`}>
                <div ref={messagesEndRef}></div>

                <div className='w-[90%] mx-auto flex flex-col-reverse'>
                    {selectedChat && selectedChat?.activities?.length
                        ?
                        selectedChat?.activities
                            .filter(activity => activity.from.id === "TML-Neural")
                            .map((userActivity, index) => (
                                <div
                                    key={userActivity.id as string + index}
                                    onClick={() => handleLogClick(userActivity.id as string)}
                                    className={`p-1 text-[10px] 2xl:text-[14px]  h-9 2xl:h-12 block ${isDarkMode ? "border border-[#FFFFFF] border-opacity-10 bg-[#1B1B1D]" : "bg-[#F7F8FA] bg-opacity-60"
                                        } flex flex-row items-center justify-start my-1 rounded-md cursor-pointer  transition-transform  ${animateId === userActivity.id ? "div-to-animate max-h-9 2xl:max-h-12" : ""}
                                      ${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-10" : "hover:bg-opacity-100"
                                        } `}

                                >

                                    <p className='truncate text-gray-500  font-poppins font-normal overflow-x-hidden text-nowrap mx-2 ' title={userActivity?.text}>
                                        <span className={`font-medium  ${isDarkMode ? "text-white" : "text-[#666F8D]"} `}>Question : </span>
                                        {userActivity?.text}
                                    </p>
                                </div>
                            ))
                        : <div className="flex items-center justify-center flex-col gap-2">
                            <div className={`w-[30%] h-[2px] ${isDarkMode ? "bg-[#aeaeae]" : "bg-[#666F8D]"}`}></div>
                            <h1 className={`${isDarkMode ? "text-[#aeaeae]" : "text-[#666F8D] font-poppins"}`}>No conversations Yet!</h1>
                        </div>
                    }
                </div>

            </div>


        </div>
    );
};

export default ConvSection;

