export enum ConfidenceStatus {
    High = "high",
    Low = "low",
    Medium = "medium",
}
interface StatusStyle {
    textColor: string;
    bgColor: string;
}

export const ConfidenceStatusLightStyles: Record<ConfidenceStatus, StatusStyle> = {
    [ConfidenceStatus.High]: {
        textColor: "#2B8A3E",
        bgColor: "#D3F9D8",
    },
    [ConfidenceStatus.Medium]: {
        textColor: "#FF8A00",
        bgColor: "#FFF2DA",
    },
    [ConfidenceStatus.Low]: {
        textColor: "#DCB900",
        bgColor: "#FFF8ba",
    },
};

export const ConfidenceStatusDarkStyles: Record<ConfidenceStatus, StatusStyle> = {
    [ConfidenceStatus.High]: {
        textColor: "#2B8A3E",
        bgColor: "#D3F9D8",
    },
    [ConfidenceStatus.Medium]: {
        textColor: "#FF8A00",
        bgColor: "#FFF2DA",
    },
    [ConfidenceStatus.Low]: {
        textColor: "#DCB900",
        bgColor: "#FFF8ba",
    },
};