import React, { useEffect, useState } from "react";
import ChatScreenFooter from "./ChatScreenFooter/ChatScreenFooter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { MsgScreen } from "./MsgScreen";
import { DirectLine, ConnectionStatus } from "botframework-directlinejs";
import { MyStorage } from "../../storage/myStorage";
import { getJWTokenStart, refreshJWTokenStart } from "../../redux/slices/jwTokenSlice";
import { IActivity, addMessage } from "../../redux/slices/ChatSlice";
import TypingAnimation from "../Typing";

let directLine: DirectLine | null;

export const handleSendActivity = (query: string, chartType: string, threadId: string, role: string | null, userId: number) => {

    directLine && directLine.postActivity({
        from: { id: "TML-Neural", name: "TML-Neural" }, // required (from.name is optional)
        type: "message",
        timestamp: new Date().toJSON(),
        text: query,
        channelId: "tml",
        channelData: {
            oauthAccessToken: MyStorage.getItem("ssoToken"),
            chartType,
            OPEN_AI_HOST_API: MyStorage.getItem("OPEN_AI_HOST_API"),
            threadId: threadId,
            role: role,
            userId: userId
        }
    }).subscribe(
        (id) => { console.log("Posted activity, assigned ID ", id); },
        (error) => console.log("Error posting activity", error)
    );
};


const ChatScreen: React.FC = () => {
    const dispatch = useDispatch();
    const JWToken = useSelector((state: RootState) => state.getJWToken);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const selectedThreadId = useSelector((state: RootState) => state.chat.selectedThreadId);

    const threads = useSelector((state: RootState) => state.chat.threads);
    const selectedChat = threads.find((chat) => chat.threadId === selectedThreadId);

    const UpdateMessagesToStore = (activity: IActivity) => {
        if (!activity?.attachments) {
            if (activity.type !== "typing") {
                dispatch(addMessage({ activity }));
            }
        }

    };

    const ifTokenExpired = () => {
        const storedTimestamp = MyStorage.getItem("timeStamp");
        const storedTimestampNumber = Number(storedTimestamp);
        return storedTimestampNumber < Date.now();
    };


    //  initlization of Botz
    React.useEffect(() => {

        const directlineToken = JWToken.jwToken.token;
        const conversationId = JWToken.jwToken.conversationId;

        if (directlineToken && conversationId) {

            if (MyStorage.getItem("directlineToken") && MyStorage.getItem("convId") && !ifTokenExpired()) {
                console.log("Second Call");
                directLine = new DirectLine({
                    token: directlineToken as string,
                    conversationId: conversationId
                });
            } else {
                console.log("First Call");
                directLine = new DirectLine({
                    token: directlineToken as string,
                });
            }

            directLine.activity$.subscribe((activity: IActivity) => {
                if (activity.type === "message") console.log("Receive Activity", activity);
                UpdateMessagesToStore(activity);
            });

            const currTimestamp = Date.now();
            const timeStampAfter = currTimestamp + (58 * 60 * 1000);

            directLine.connectionStatus$.subscribe((cs: number) => {
                switch (cs) {
                    case ConnectionStatus.Online:
                        console.log("Connected");
                        (MyStorage.getItem("directlineToken") !== directlineToken) && MyStorage.setItem("timeStamp", timeStampAfter.toString());
                        MyStorage.setItem("directlineToken", directlineToken);
                        MyStorage.setItem("convId", conversationId);
                        break;
                    case ConnectionStatus.FailedToConnect:
                        console.log("FailedToConnect");
                        MyStorage.removeItem("timeStamp");
                        MyStorage.removeItem("directlineToken");
                        MyStorage.removeItem("convId");
                        directLine = null;
                        JWToken.jwTokenIsSuccess && dispatch(getJWTokenStart());
                        break;
                    case ConnectionStatus.ExpiredToken:
                        console.log("ExpiredToken");
                        MyStorage.removeItem("timeStamp");
                        MyStorage.removeItem("directlineToken");
                        MyStorage.removeItem("convId");
                        directLine = null;
                        JWToken.jwTokenIsSuccess && dispatch(getJWTokenStart());
                        break;
                    case ConnectionStatus.Ended:
                        console.log("FailedToConnect Ended Ended");
                        break;
                    case ConnectionStatus.Connecting:
                        console.log("Connecting");
                        break;
                    case ConnectionStatus.Uninitialized:
                        console.log("Connecting Uninitialized");
                        break;
                }
            });
        }

        const tokenExpirationInterval = setInterval(() => {
            const token = MyStorage.getItem("directlineToken");
            if (token && !ifTokenExpired()) {
                dispatch(refreshJWTokenStart(token));
            }

        }, (JWToken.jwToken.expires_in - 180) * 1000); // Refresh

        // Clean up the interval on component unmount
        return () => clearInterval(tokenExpirationInterval);

    }, [JWToken.jwToken.token]);


    return (
        <div className={`w-full h-full  ${isDarkMode ? "bg-[#1D1E1F] bg-opacity-70  md:border-r border-[#FFFFFF] border-opacity-[15%]" : "bg-[#FFFFFF] bg-opacity-70 border border-[#F0F2F5] border-r-0 "} transition-full duration-300`}>
            <div className='h-full flex flex-col justify-between py-[10px] w-[95%] mx-auto'>
                <MsgScreen />
                <div className="flex flex-col gap-1">
                    {selectedChat?.typing === true && <TypingAnimation />}
                    <ChatScreenFooter />
                </div>
            </div>
        </div>
    );
};
export default ChatScreen;