import { all } from "redux-saga/effects";
import watchGetJWToken from "./AuthSaga/jwTokenSaga";
import domainSaga from "./DomainSaga";
import watchFetchRoles from "./RolesSaga";
import userInfoSaga from "./AzureADSaga";
import BingArticleSaga from "./BingArticleSaga";
import watchGetEmailListSaga from "./EmailSaga/getEmailListSaga";
import watchPostEmailReplySaga from "./EmailSaga/postEmailReplySaga";
import watchGetRepliedEmailListSaga from "./EmailSaga/getRepliedEmailListSaga";
import watchUserDetails from "./UserSaga";


function* rootSaga() {
  yield all([
  watchGetJWToken(),
  domainSaga(),
  watchFetchRoles(),
  userInfoSaga(),
  BingArticleSaga(),
  watchGetEmailListSaga(),
  watchPostEmailReplySaga(),
  watchGetRepliedEmailListSaga(),
  watchUserDetails(),
]);
 
}
export default rootSaga;
