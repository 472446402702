import React, { useState } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface IPieChartData {
  name: string;
  value: number;
}

type Props = {
  activityID: any;
  data: any;
};
const LIGHT_COLORS = ["#60D8CF", "#FFBB28", "#FF8042", "#e420c8", "#77cc6f", "#aa84d8", "#a4de6c", "#00abFE", "#82ca9d", "#ffc658", "#8dd1e1", "#82ca9d", "#40558d", "#77cc6f", "#e420c8", "#d5834d", "#6b7b14", "#714505", "#b8b67c", "#744bb1", "#4c4c86", "#3ac71f"];
const DARK_COLORS = [
  "rgba(96,216,207,0.8)", "rgba(255,187,40,0.8)",
  "rgba(255,128,66,0.8)", "rgba(228,32,200,0.8)",
  "rgba(119,204,111,0.8)", "rgba(170,132,216,0.8)",
  "rgba(164,222,108,0.8)", "rgba(0,171,254,0.8)",
  "rgba(130,202,157,0.8)", "rgba(255,198,88,0.8)",
  "rgba(141,209,225,0.8)", "rgba(130,202,157,0.8)",
  "rgba(64,85,141,0.8)", "rgba(119,204,111,0.8)",
  "rgba(228,32,200,0.8)", "rgba(213,131,77,0.8)",
  "rgba(107,123,20,0.8)", "rgba(113,69,5,0.8)",
  "rgba(184,182,124,0.8)", "rgba(116,75,177,0.8)",
  "rgba(76,76,134,0.8)", "rgba(58,199,31,0.8)"
];
// const COLORS = ["#60D8CF", "#D5332F", "#EA6A45", "#F8A061", "#FECF87", "#FCF1B3", "#F1F6D8", "#CBE8EE", "#9CCAE1", "#6DA2CA", "#496FAE", "#5D49AE", "#8249AE", "#AC49AE", "#AE4979", "#E06E6E"];

interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: CustomizedLabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 20;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill}>{`${payload.name}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={14} textAnchor={textAnchor} fill={fill}>
        {` ${payload.actualValue} (${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const transformedData = (data: any) => {

  return data?.map((entry, index: number) => {
    return {
      name: entry.name,
      value: Math.abs(Number(entry.value)),
      actualValue: Number(entry.value)
    };
  });
};


const PieChartComponent: React.FC<Props> = ({ data }) => {
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={`font-poppins ${isDarkMode ? "bg-[#1F1F1F] scrollbar-dark " : "bg-white scrollbar-light"} rounded-md  mt-2  p-2 text-[#282828] overflow-x-auto w-full h-full `} >
      <h1 className={`font-semibold my-1 text-[10px] md:text-[14px]  ${isDarkMode ? "text-white" : ""}`} >{data?.graph_title}</h1>
      <ResponsiveContainer className=" p-2 pb-0  text-[10px] max-h-full text-[#aeaeae] " minWidth={"500px"} minHeight={"320px"} width="100%" height="100%"  >
        <PieChart
          margin={{ top: 5, right: 0, bottom: 10, left: 0 }}
          width={400}
          height={400}>
          <Pie
            stroke={`${isDarkMode ? "#AEAEAE" : "#FFF"}`}
            isAnimationActive={true}
            startAngle={360}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={(_, index: number) => {
              setActiveIndex(index);
            }}
            minAngle={2}
            endAngle={0}
            data={transformedData(data?.graph_data)}
            cx="50%"
            cy="50%"
            labelLine={false}
            // label={renderCustomizedLabel}
            outerRadius={120}
            dataKey="value"
            animationBegin={500} // Animation start after 500ms
            animationEasing="ease-in-out"
            className="focus:outline-none"
          >
            {data?.graph_data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={`${isDarkMode ? DARK_COLORS[index % DARK_COLORS.length] : LIGHT_COLORS[index % LIGHT_COLORS.length]}`} />
              // fill={COLORS[index % COLORS.length]} />
            ))}

          </Pie>
          {/* <Tooltip /> */}

          {/* <Legend
            wrapperStyle={{ width: "50%", paddingLeft: "30px" }}
            layout="centric"
            verticalAlign="top"
            align="center"
            height={100}
            margin={{ top: 0, left: 100, right: 0, bottom: 0 }}
            chartHeight={600} /> */}

        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartComponent;