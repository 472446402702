import React, { useEffect, useRef, useState } from "react";
import ChatsSection from "../components/PrevChatsSection/ChatsSection";
import ChatScreen from "../components/MainChatSection/ChatScreen";
import ConvSection from "../components/ConvSection/ConvSection";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import backgroundImg from "../assets/BackgroundChat.png";
import Navbar from "../components/Navbar/Navbar";
import SideDrawer from "../components/Drawer/SideDrawer";
import { activeDrawer } from "../redux/slices/DrawerSlice";
import LazyFallback from "../components/Loaders";
import { MyStorage } from "../storage/myStorage";
import { getJWTokenStart } from "../redux/slices/jwTokenSlice";
import AccessDenied from "../components/AccessDenied";

const AskMeAnything: React.FC = () => {

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const isDrawerActive = useSelector((state: RootState) => state.drawer.isDrawerActive);
    const jwTokenData = useSelector((state: RootState) => state.getJWToken); // Access the loading state of fetching token


    const drawerRef = useRef<HTMLDivElement | null>(null);
    const navbarRef = useRef<HTMLDivElement | null>(null); // Separate ref for Navbar
    const role = useSelector((state: RootState) => state.matchRole);
    
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false); // Local state for loader visibility

    const containerStyle = {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        justifyContent: "spaceBetween"
    };

    const ifTokenExpired = () => {
        const storedTimestamp = MyStorage.getItem("timeStamp");
        const storedTimestampNumber = Number(storedTimestamp);
        return storedTimestampNumber < Date.now();
    };

    useEffect(() => {
        if ((!MyStorage.getItem("directlineToken") || (MyStorage.getItem("directlineToken") && ifTokenExpired())) && role.isSuccess && role.data) {
            dispatch(getJWTokenStart());
        }
    }, [role.data]);

    const handleClickOutside = (event: MouseEvent) => {
        // Check if the click was outside the side drawer and if the drawer is currently open
        if (isDrawerActive && drawerRef.current && !drawerRef.current.contains(event.target as Node) && !navbarRef.current?.contains(event.target as Node)) {
            dispatch(activeDrawer());
        }
    };

    useEffect(() => {
        // Add the click event listener to the document
        document.addEventListener("click", handleClickOutside);

        // Remove the event listener on cleanup
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };

    }, [isDrawerActive]);



    return (
        <>
            {jwTokenData.jwTokenIsSuccess && jwTokenData.jwToken && role.isSuccess && role.data && role.data.some((app) => app.subDomainId === 2)
                ? <div className="relative w-screen h-dvh">

                    <div
                        ref={drawerRef}
                        className={`absolute h-full top-0 left-0 md:p-2 w-[65%] sm:w-[30%] ${isDarkMode ? "bg-[#1B1B1D]" : "bg-[#F7F8FA]"}  ${isDrawerActive ? "translate-x-0" : "translate-x-[-100%]"} transition-full duration-300 md:hidden z-30`}>
                        <SideDrawer />
                    </div>

                    <div style={containerStyle} className={`w-full h-full md:p-2 ${isDarkMode ? "bg-[#1B1B1D] " : "bg-[#F7F8FA]"}  flex items-center flex-col mx-auto transition-full duration-300`}>

                        <div
                            ref={navbarRef}
                            className={`flex items-center p-3  md:hidden w-full  ${isDarkMode ? " border-b border-[#FFFFFF] border-opacity-[15%]" : ""}`}>
                            <Navbar />
                        </div>

                        <div className=" grid w-full h-[calc(100vh-63px)] md:h-full grid-cols-3 md:grid-cols-4 lg:grid-cols-5 ">
                            <div className="hidden md:block md:col-span-1">
                                <ChatsSection />
                            </div>
                            <div className="col-span-3 flex overflow-hidden ">
                                <ChatScreen />
                            </div>
                            <div className="hidden lg:block lg:col-span-1 rounded-sm ">
                                <ConvSection />
                            </div>
                        </div>
                    </div>
                </div>:
                (role.isSuccess && role.data && !role.data.some((app) => app.subDomainId === 2)) || role.isError? 
                <AccessDenied/>
                : <LazyFallback />
            }
        </>
    );
};

export default AskMeAnything;