import Navbar from "../components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import DomainComponent, { IDomains } from "../components/Domains/DomainComponent";
import AppSection from "../components/Apps/AppSection";
import Carousel from "../components/Carousel/Carousel";
import Footer from "../components/Footer/Footer";
import ArticleList from "../components/Articles/ArticleList";
import { CarouselImageData } from "../components/Carousel/CarousalData";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import DarkBgImg from ".././assets/blackBgImg.png";

const Home: React.FC = () => {

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const containerStyle = {
    backgroundImage: isDarkMode ? `url(${DarkBgImg})` : "",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  useEffect(() => {
    document.body.className = isDarkMode ? "dark" : "";
  }, [isDarkMode]);

  return (
    <div className='flex justify-center items-center '>
      <div className={`w-full h-full ${isDarkMode ? "bg-[#1B1B1D]" : ""} transition-full duration-300`}>
        <div className=' container my-4 px-4 md:px-12 2xl:px-28 3xl:px-0 mx-auto '>
          <Navbar />
        </div>
        <div style={containerStyle}>
          <div className='w-full'>
            <Carousel images={CarouselImageData} />
          </div>
          <div className='container my-4 px-4 md:px-12 2xl:px-28 3xl:px-0 mx-auto '>
            <DomainComponent />
          </div>
        </div>
        <div className='container my-4 px-4 md:px-12 2xl:px-28 3xl:px-0  mx-auto'>
          <AppSection />
        </div>
        <div className='w-full'>
          <ArticleList />
        </div>
        <Footer />
      </div>
    </div>
  );

};

export default Home;
