import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { toggleDarkMode } from "../../../../redux/slices/themeSlice";
import { useNavigate } from "react-router-dom";
import { FiSun } from "react-icons/fi";
import { IoMoonOutline } from "react-icons/io5";
import exitImg from "../../../../assets/exitImg.png";

const LeftFooter: React.FC = () => {
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleClick = () => {
    dispatch(toggleDarkMode());
  };

  const handleExitClick = () => {
    navigate("/"); // Navigate to the home URL
  };

  return (
    <div className="w-full p-1 mx-auto ">
      <div className='flex flex-col justify-start mt-2  text-[12px] 2xl:text-[16px]'>
        <button className={`rounded-md cursor-pointer pl-2 ${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-10" : "hover:bg-[#F7F8FA] "}`}
          onClick={handleToggleClick}>
          <div className='flex flex-row items-center gap-2 my-2 '>
            {
              isDarkMode
                ? <FiSun className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"} w-4 h-4`} />
                : <IoMoonOutline className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"} w-4 h-4`} />
            }
            <span className={`${isDarkMode ? "text-[#E5E5E5]" : "text-[#666F8D]"}  font-poppins `}>{`Switch ${isDarkMode ? "Light" : "Dark"} Mode`}</span>
          </div>
        </button>
        <button className={`rounded-md cursor-pointer  pl-2 ${isDarkMode ? "hover:bg-[#FFFFFF] hover:bg-opacity-10" : "hover:bg-[#F7F8FA] "}`}
          onClick={handleExitClick}>
          <div className='flex flex-row items-center gap-2 my-2'>
            <img src={exitImg} alt='exit-img' className='w-4 h-4' />
            <span className=' text-[#FF5555]  font-poppins '>Exit</span>
          </div>
        </button>
      </div>
    </div >
  );
};

export default LeftFooter;