import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BingApiState {
    bingData: any;
    bingIsSuccess: boolean;
    bingLoading: boolean;
    bingIsError: boolean;
    bingError: string | null;
}

const initialState: BingApiState = {
    bingData: [],
    bingIsSuccess: false,
    bingLoading: false,
    bingIsError: false,
    bingError: null,
};

const bingSlice = createSlice({
    name: "bingApi",
    initialState,
    reducers: {
        fetchBingArticleRequest: (state, action: PayloadAction<string>) => {
            state.bingLoading = true;
            state.bingIsError = false;
            state.bingError = null;
        },
        fetchBingArticleSuccess: (state, action: PayloadAction<any>) => {
            state.bingLoading = false;
            state.bingIsError = false;
            state.bingIsSuccess = true;
            state.bingError = null;
            state.bingData = action.payload;
        },
        fetchBingArticleFailure: (state, action: PayloadAction<string>) => {
            state.bingLoading = false;
            state.bingIsError = true;
            state.bingIsSuccess = false;
            state.bingError = action.payload;
        },
    },
});

export const { fetchBingArticleRequest, fetchBingArticleSuccess, fetchBingArticleFailure } = bingSlice.actions;

export default bingSlice.reducer;
