import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
export interface User {
  id: string;
  name?: string;
  iconUrl?: string;
  role?: string;
}
export interface IActivity {
  type: string;
  channelData?: any;
  channelId?: string;
  conversation?: {
    id: string;
  };
  eTag?: string;
  from: User;
  text?: string;
  id?: string;
  replyToId?: string;
  timestamp?: string;
  attachments?: any;
  feedback?: string
}

export interface Threads {
  threadId: string;
  threadTitle: string;
  activities: IActivity[];
  typing?: boolean;
}

interface ChatState {
  threads: Threads[];
  selectedThreadId: string;
}

const initialState: ChatState = {
  threads: [],
  selectedThreadId: uuid()
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {

    restoreChats: (state, action) => {
      // state.threads = [...state.threads, action.payload];
      state.threads = action.payload;
    },

    addMessage: (state, action: PayloadAction<{ activity: IActivity }>) => {
      const { activity } = action.payload;
      const selectedThreadId = state.selectedThreadId;

      // Check if the activity exists in any chat
      const activityExists = state.threads.some(chat =>
        chat.activities.some(act => act.id === activity.id)
      );

      if (!activityExists) {
        let thread;

        activity?.replyToId
          ? thread = state.threads.find((thread) => thread.activities.find((act) => act.id === activity.replyToId))
          : thread = state.threads.find((thread) => thread.threadId === selectedThreadId);

        if (thread) {
          activity.type === "customTyping" ? thread.typing = true : thread.typing = false;
          activity.type !== "customTyping" && thread.activities.push(activity);

        } else {
          thread = {
            threadId: selectedThreadId,
            threadTitle: activity?.text || "bot",
            activities: [activity],
            typing: false
          };
          state.threads.unshift(thread);
        }
      }
    },

    selectChat: (state, action: PayloadAction<string>) => {
      state.selectedThreadId = action.payload;
    },

    startNewChat: (state) => {
      // const newChatId = state.chats.length ? state.chats.length + 1 : 1;
      const newChatId = uuid();
      state.selectedThreadId = newChatId;
    },

    clearChat: (state) => {
      state.threads = [];
      state.selectedThreadId = uuid();
    },
  },
});

export const { addMessage, selectChat, startNewChat, clearChat, restoreChats } =
  chatSlice.actions;
export default chatSlice.reducer;
