/* eslint-disable react/prop-types */
/* eslint-disable quotes */
import React, { useCallback, useMemo, useState } from "react";
import { createEditor, Descendant, Text } from "slate";
import { Slate, Editable, withReact, RenderElementProps, RenderLeafProps } from "slate-react";
import { withHistory } from "slate-history";
import { Toolbar } from "./Toolbar";
 
export type CustomElement = { type: 'paragraph'; children: CustomText[]; };
type CustomText = { text: string; };
 

export const extractPlainText = (nodes: Descendant[]): string => {
    return nodes
        .map((node) => {
            if (Text.isText(node)) {
                return node.text;
            } else {
                return extractPlainText(node.children);
            }
        })
        .join('');
};
 
interface IRichTextEditor {
    value: Descendant[];
    setValue: React.Dispatch<React.SetStateAction<Descendant[]>>;
}
 
const RichTextEditor: React.FC<IRichTextEditor> = ({ value, setValue }) => {
 
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
    const Element = (props) => {
 
        const { attributes, children, element } = props;
 
        switch (element.type) {
            case 'headingOne':
                return <h1 {...attributes}>{children}</h1>;
            case 'headingTwo':
                return <h2 {...attributes}>{children}</h2>;
            case 'headingThree':
                return <h3 {...attributes}>{children}</h3>;
            case 'blockquote':
                return <blockquote {...attributes}>{children}</blockquote>;
            case 'alignLeft':
                return <div style={{ textAlign: 'left', listStylePosition: 'inside' }} {...attributes}>{children}</div>;
            case 'alignCenter':
                return <div style={{ textAlign: 'center', listStylePosition: 'inside' }} {...attributes}>{children}</div>;
            case 'alignRight':
                return <div style={{ textAlign: 'right', listStylePosition: 'inside' }} {...attributes}>{children}</div>;
            case 'list-item':
                return <li {...attributes}>{children}</li>;
            case 'orderedList':
                return <ol type='1' {...attributes}>{children}</ol>;
            case 'unorderedList':
                return <ul {...attributes}>{children}</ul>;
            case 'table':
                return <table>
                    <tbody {...attributes}>{children}</tbody>
                </table>;
            case 'table-row':
                return <tr {...attributes}>{children}</tr>;
            case 'table-cell':
                return <td {...attributes}>{children}</td>;
            default:
                return <p {...attributes}>{children}</p>;
        }
    };
 
    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>;
        }
   
        if (leaf.italic) {
            children = <em>{children}</em>;
        }
   
        if (leaf.underline) {
            children = <u>{children}</u>;
        }
   
        if (leaf.strikethrough) {
            children = <span style={{ textDecoration: 'line-through' }}>{children}</span>;
        }
   
        if (leaf.superscript) {
            children = <sup>{children}</sup>;
        }
   
        if (leaf.subscript) {
            children = <sub>{children}</sub>;
        }
   
        if (leaf.color) {
            children = <span style={{ color: leaf.color }}>{children}</span>;
        }
   
        if (leaf.bgColor) {
            children = <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>;
        }
   
        return <span {...attributes}>{children}</span>;
    };
   
    const renderElement = useCallback(props => <Element {...props} />, [value]);
 
    // Function to render elements
    // const renderElement = useCallback((props: RenderElementProps) => {
    //     const { attributes, children, element } = props;
 
    //     switch (element.type) {
    //         case 'paragraph':
    //             return <p {...attributes}>{children}</p>;
    //         case 'heading-one':
    //             return <h1 {...attributes}>{children}</h1>;
    //         case 'heading-two':
    //             return <h2 {...attributes}>{children}</h2>;
    //         case 'block-quote':
    //             return <blockquote {...attributes}>{children}</blockquote>;
    //         case 'code-block':
    //             return (
    //                 <pre {...attributes}>
    //                     <code>{children}</code>
    //                 </pre>
    //             );
    //         default:
    //             return <p {...attributes}>{children}</p>;
    //     }
    // }, []);
 
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [value]);
 
    // Function to render leaves (text with formatting);
    // const renderLeaf = useCallback((props: RenderLeafProps) => {
    //     const { attributes, children, leaf } = props;
 
    //     let formattedText = children;
 
    //     if (leaf.bold) {
    //         formattedText = <strong>{formattedText}</strong>;
    //     }
 
    //     if (leaf.italic) {
    //         formattedText = <em>{formattedText}</em>;
    //     }
 
    //     if (leaf.underline) {
    //         formattedText = <u>{formattedText}</u>;
    //     }
 
    //     if (leaf.strikethrough) {
    //         formattedText = <del>{formattedText}</del>;
    //     }
 
    //     return <span {...attributes}>{formattedText}</span>;
    // }, []);
 
    // console.log("Rick Text Value -->", value);
 
    return (
        <Slate editor={editor} initialValue={value} onChange={(newValue) => setValue(newValue)}>
            <Toolbar />
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder="Type your mail here..."
                className=" p-2 justify-start overflow-x-hidden  focus:outline-none font-poppins"
            />
        </Slate>
    );
};
 
export default RichTextEditor;
 
 