import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";
import { setToken } from "../../redux/slices/keyCloakTokenSlice";
import store from "../../redux/store";


const config: KeycloakConfig = {
    realm: "udyog" || "Neural",
    url: "https://qa.sso.tatamotors.com/auth/" || "http://localhost:8080/",
    clientId: "tata-neural-cv" || "neural-test",
};

export const _kc = new Keycloak(config);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void): void => {
    const initOptions: KeycloakInitOptions = {
        onLoad: "login-required",
        checkLoginIframe: false,
        // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        pkceMethod: "S256",
    };

    _kc.init(initOptions)
        .then((authenticated) => {
            if (!authenticated) {
                console.log("User is not authenticated..!");
            }
            store.dispatch(setToken(_kc?.token));

            onAuthenticatedCallback();
        })
        .catch(console.error);
};

const doLogin = (): Promise<void> => _kc.login();

export const doLogout = (): Promise<void> => _kc.logout();

const getToken = (): string | undefined => _kc.token;

const getTokenParsed = (): Keycloak.KeycloakTokenParsed | undefined => _kc.tokenParsed;

const isLoggedIn = (): boolean => !!_kc.token;

const updateToken = (successCallback: () => void): void => {
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);
};

const getUsername = (): string | undefined => _kc.tokenParsed?.preferred_username;

const hasRole = (roles: string[]): boolean => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    getTokenParsed,
    updateToken,
    getUsername,
    hasRole,
    _keycloak: _kc
};

export default UserService;
