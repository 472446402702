import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPostEmailReplyPayload {
    internetMessageId: string;
    replyBy: string;
    confidenceStatus: string;
    emailContentType: string;
    emailContentBody: string;
    emailAutomationId:string;
}



export interface IPostEmailReplyData {
    status_code: string;
    message: string;
    totalRecords: number;

}

export interface IPostEmailReplyDataState {
    response: IPostEmailReplyData;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any | null;
}


const initialState: IPostEmailReplyDataState = {
    response: {
        status_code: "",
        message: "",
        totalRecords: null,
    },
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null
};

export const postEmailReplySlice = createSlice({
    name: "postEmailReplySlice",
    initialState,
    reducers: {
        postEmailReplyStart: (state, action: PayloadAction<IPostEmailReplyPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        postEmailReplySuccess: (state, action: PayloadAction<IPostEmailReplyData>) => {
            state.response = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        postEmailReplyFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { postEmailReplyStart, postEmailReplySuccess, postEmailReplyFailure } = postEmailReplySlice.actions;

export default postEmailReplySlice.reducer;