// keyCloaktokenSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TokenState {
  token: string | null;
}

const initialState: TokenState = {
  token: null,
};

const keyCloakTokenSlice = createSlice({
  name: "keycloakToken",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
    clearToken(state) {
      state.token = null;
    },
  },
});

export const { setToken, clearToken } = keyCloakTokenSlice.actions;

export default keyCloakTokenSlice.reducer;
