// tokenDecodeSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface IUser {
    ID: number;
    EmailID: string;
    FirstName: string;
    LastName: string;
    IsDeleted: boolean;
    IsStatus: boolean;
    CreatedBy: string | null;
    UpdatedBy: string | null;
    CreatedDate: string;
    UpdatedDate: string | null;
    UserDomainId: string;
    ProfileDesc: string;
}
interface UserState {
    userData: IUser | null;
    isLoading: boolean;
    success: boolean;
    isError: boolean;
    error: string | null;
}

const initialState: UserState = {
    userData: null,
    isLoading: false,
    success: false,
    isError: false,
    error: null,
};

const createUserSlice = createSlice({
    name: "createUser",
    initialState,
    reducers: {
        createUserRequest(state, action: PayloadAction<{ email: string, firstName: string, lastName: string, userDomainId: string, profileDesc: string }>) {
            state.isLoading = true;
            state.isError = false;
            state.error = null;
        },
        createUserSuccess(state, action: PayloadAction<IUser>) {
            state.userData = action.payload;
            state.isLoading = false;
            state.success = true;
        },
        createUserFailure(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.success = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const {
    createUserRequest,
    createUserSuccess,
    createUserFailure,
} = createUserSlice.actions;

export default createUserSlice.reducer;
