// tokenDecodeSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDeleteChatApiResponse {
    message: string;
}


interface IDeleteUserChats {
    data: IDeleteChatApiResponse | null;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: string | null;
}

const initialState: IDeleteUserChats = {
    data: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
};

const deleteuserChatsFromDBSlice = createSlice({
    name: "deleteUserChats",
    initialState,
    reducers: {
        deleteUserChatsRequest(state, action: PayloadAction<{ userId: number }>) {
            state.isLoading = true;
            state.isError = false;
            state.error = null;
            state.isSuccess=false;
        },
        deleteUserChatsSuccess(state, action: PayloadAction<IDeleteChatApiResponse>) {
            state.data = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
        },
        deleteUserChatsFailure(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const {
    deleteUserChatsRequest,
    deleteUserChatsSuccess,
    deleteUserChatsFailure,
} = deleteuserChatsFromDBSlice.actions;

export default deleteuserChatsFromDBSlice.reducer;
