import { call, put, take, takeLatest } from "redux-saga/effects";
import { httpPatch } from "../../../services";
import { postEmailReplyStart, postEmailReplySuccess, postEmailReplyFailure, IPostEmailReplyPayload } from "../../slices/EmailSlice/postEmailReplySlice";
// import { FIN_POWER_BASE_URL, ACR_SAP_CONFIG, API_SECRET_TOKEN } from "../../../config";

function* PostEmailReplySaga(actions: { type: string, payload: IPostEmailReplyPayload; }) {
    try {
        const data = yield httpPatch(`${process.env.REACT_APP_MAIL_MITRA_API}/outlook-email-api/update-reply-details`, actions.payload
            // {
            //     headers: {
            //         // token: API_SECRET_TOKEN,
            //         // "x-teams-origin": window.location.origin
            //         // "x-teams-origin": "http://localhost:3000"
            //     }
            // }
        );

        yield put(postEmailReplySuccess(data));

    } catch (error) {
        console.error("Error fetching PostEmailReply:", error);
        yield put(postEmailReplyFailure(error));
    }
}

export default function* watchPostEmailReplySaga() {
    yield takeLatest(postEmailReplyStart, PostEmailReplySaga);
    // while (true) {
    //     const { payload } = yield take(postEmailReplyStart.type);
    //     yield call(PostEmailReplySaga, payload);
    // }
}