import React, { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import avatar from "../../../assets/avatar.png";
import { getInitials } from "../../../App";
import { _kc } from "../../../services/keyCloak/UserService";
import { MyStorage } from "../../../storage/myStorage";
import { MdHelpOutline } from "react-icons/md";
import HelpAndSupportPopup from "../../Cards/HelpAndSupportPopup";


export const ProfileHeader: React.FC = () => {
    const userImg = useSelector((state: RootState) => state.userImg);
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    // State to manage pop-up visibility
    const [isHelpPopupOpen, setIsHelpPopupOpen] = useState(false);

    const [isAnimating, setIsAnimating] = useState(false);

    // Function to toggle the pop-up
    const toggleHelpPopup = () => {
        // Start exit animation
        setIsAnimating(true);
        setIsHelpPopupOpen(!isHelpPopupOpen); // Close the popup after animation ends
        setTimeout(() => {
            setIsAnimating(false);
        }, 200); // Duration should match the CSS transition duration

    };

    useEffect(() => {
        const hasSeenPopup = MyStorage.getItem("helpPopup");
        if (!hasSeenPopup) {
            toggleHelpPopup();
            MyStorage.setItem("helpPopup", "true");
        }
    }, []);

    return (
        <div className={"w-full h-full flex flex-row justify-between items-center "}>
            <div className='w-full h-full flex flex-row items-center justify-between gap-2 '>

                <div className="flex flex-row items-center justify-start gap-2">
                    <div className=" h-full bg-transparent cursor-pointer flex items-center justify-start text-white font-semibold w-[38px]">
                        {userImg.IsSuccess && userImg.data
                            ? <img height="38px" width="38px" className="   border border-[#31C6BB] rounded-full " src={userImg.data} alt="User profile" title={userInfo.userInfoIsSuccess ? userInfo.userInfoData?.displayName : ""} />
                            : userInfo.userInfoIsSuccess && userInfo.userInfoData
                                ? <div className="text-[#31C6BB]  border border-[#31C6BB] w-[38px] h-[38px] flex items-center justify-center rounded-full" title={userInfo.userInfoData.displayName}>{getInitials(userInfo.userInfoData?.givenName, userInfo.userInfoData?.surname)}</div>
                                : <img height="38px" width="38px" className=" border border-[#31C6BB]  rounded-full overflow-hidden" src={avatar} alt="avatar" />
                        }
                    </div>
                    <div className="h-full flex justify-start items-center ">
                        <p className={`break-words w-full text-[11px] 2xl:text-[13px] ${isDarkMode ? "text-white text-opacity-80" : "text-[#666F8D]"} font-poppins font-medium inline`}>
                            {userInfo.userInfoIsSuccess && userInfo.userInfoData ? userInfo.userInfoData.displayName : ""}
                        </p>
                    </div>
                </div>

                <button onClick={toggleHelpPopup}>
                    <MdHelpOutline title="help & support" className="text-[#31C6BB] w-5 h-5 2xl:w-6 2xl:h-6 hover:scale-110 cursor-pointer transition-all 2s ease-in" />
                </button>
            </div>
            {
                isHelpPopupOpen && (
                    <div className="fixed inset-0 flex items-center z-30 justify-center bg-black bg-opacity-50">
                        <div className={`w-full transform transition-all duration-200 ease-in-out
                        ${isAnimating ? "scale-50 opacity-0" : "scale-100 opacity-100"}`}>
                            <HelpAndSupportPopup onClose={toggleHelpPopup} />
                        </div>
                    </div>
                )
            }


        </div >
    );
};
