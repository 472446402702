import React, { useEffect, useState } from "react";
import { IGetEmailListItem } from "../../../redux/slices/EmailSlice/getEmailListSlice";
// import "./emailView.css";
import EmailView from "./EmailView";
import Avatar from "../../../assets/img/Avatar.png";
import editIcon from "../../../assets/img/message-edit.png";
import editIconLight from "../../../assets/img/light-message-edit.png";
import sendIcon from "../../../assets/img/sms-tracking.png";
import RichTextEditor, { extractPlainText } from "./RichTextEditor";
import { useDispatch, useSelector } from "react-redux";
import "./emailView.css";
import { postEmailReplyStart } from "../../../redux/slices/EmailSlice/postEmailReplySlice";
import { createEditor, Descendant, Text } from "slate";
import { RootState } from "../../../redux/store";
import { SuccessToast } from "../../ToastifyComponent";
import { IGetRepliedEmailListItem } from "../../../redux/slices/EmailSlice/getRepliedEmailListSlice";
import moment from "moment";
import { PiArrowFatRight } from "react-icons/pi";
import { ConfidenceStatus, ConfidenceStatusDarkStyles, ConfidenceStatusLightStyles } from "../../../constants";

type Props = { setIsDetailView: React.Dispatch<React.SetStateAction<boolean>>; detailRow: IGetRepliedEmailListItem; };


const EmailDetailRepliedView = ({ setIsDetailView, detailRow }: Props) => {

    // Format the time to 'h:mmA' (e.g., 9:14AM)
    const formattedTime = moment(detailRow.ReceivedDateTime).format("h:mmA");

    // Calculate time ago using fromNow()
    const timeAgo = moment(detailRow.ReceivedDateTime).fromNow();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    return (
        <div className={`w-full mt-3 mb-[5px] h-full rounded-[10px] flex flex-col justify-between border border-opacity-10  ${isDarkMode ? "border-white" : "border-black"}`}>
            {detailRow ? (
                <div className="p-5 w-full h-full flex flex-col gap-2">
                    <div className="flex flex-row justify-between items-center text-[12px] 2xl:text-[16px]">
                        {/* title */}
                        <div className="flex flex-row items-center justify-start gap-4 w-[80%]">
                            <div className={`${isDarkMode ? "text-white text-opacity-75" : "text-black"}`}><span className="font-poppins font-medium text-[18px] 2xl:text-[26px] ">{detailRow.Subject}</span></div>
                            <div className={`${isDarkMode ? "text-white text-opacity-75" : "text-black"} text-opacity-40 font-extrabold`}><PiArrowFatRight /></div>
                            <div className="font-poppins ">
                                <div
                                    className="text-center text-[11px] 2xl:text-[14px] font-medium px-2.5 py-1 rounded w-20"
                                    style={{
                                        backgroundColor: isDarkMode
                                            ? ConfidenceStatusDarkStyles[detailRow?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.bgColor
                                            : ConfidenceStatusLightStyles[detailRow?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.bgColor,
                                        color: isDarkMode
                                            ? ConfidenceStatusDarkStyles[detailRow?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.textColor
                                            : ConfidenceStatusLightStyles[detailRow?.Confidence_status?.toLowerCase() as ConfidenceStatus]?.textColor
                                    }}
                                >
                                    {detailRow?.Confidence_status
                                        ? `${detailRow?.Confidence_status[0].toUpperCase()}${detailRow?.Confidence_status.substring(1).toLowerCase()}`
                                        : ""}
                                </div>
                            </div>
                        </div>
                        {/* Go tO list */}
                        <button onClick={() => setIsDetailView(false)} className="bg-[#18958B] p-1 border border-black border-opacity-[16%] font-poppins text-white font-normal px-2 rounded-md hover:scale-105 transition-all text-[12px] 2xl:text-[16px]">Back to List </button>
                    </div>

                    <div className={`flex flex-row items-start justify-between  ${isDarkMode ? "text-white text-opacity-75" : "text-black"} `}>
                        <div className="flex flex-row items-start justify-start gap-2 w-full h-full ">

                            <div className=""><img className="w-11 bg-white bg-opacity-50 rounded-full " src={Avatar} alt="avatar" /></div>

                            <div className="w-full h-full flex flex-col gap-2 justify-start ">
                                <div className=" flex flex-row justify-between items-start w-full text-[12px] 2xl:text-[16px] font-poppins">
                                    <div className="flex flex-col items-start justify-start">
                                        <div><h1 className={` ${isDarkMode} font-medium text-[14px] 2xl:text-[16px]`}>{detailRow.Sender_email}</h1></div>
                                        <div><h3 className=" text-opacity-30">to me</h3></div>
                                    </div>
                                    <div> {formattedTime} ({timeAgo})</div>
                                </div>
                                {/* Body */}
                                <div className={` body-container w-full  flex flex-col justify-between overflow-y-auto ${isDarkMode ? "scrollbar-dark" : "scrollbar-light"} pt-2 `}>
                                    {/* mail body */}
                                    <div className={` ${isDarkMode ? "emailview-dark text-white text-opacity-75 border-white " : "emailview-light text-black border-black "} border-b border-opacity-10 py-2`}>
                                        <div className={`text-[12px] 2xl:text-[16px] !font-poppins bg-transparent ${isDarkMode ? "text-white text-opacity-55" : "text-black text-opacity-60"}`}
                                            dangerouslySetInnerHTML={{ __html: detailRow.EmailContentBody }} />
                                    </div>

                                    <div className={` border border-opacity-10  ${isDarkMode ? "border-white" : "border-black"} my-5 rounded-2xl`} >

                                        <div className="p-2">
                                            <div className={`text-[12px] 2xl:text-[16px] !font-poppins bg-transparent ${isDarkMode ? "text-white text-opacity-55 emailview-dark" : "text-black text-opacity-60 emailview-light"}`}
                                                dangerouslySetInnerHTML={{ __html: detailRow.ResponseEmailContentBody }} />
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>




                </div>
            ) : (
                <div className={`${isDarkMode ? "text-white text-opacity-15" : "text-black text-opacity-50"}`}>No data available</div>
            )}
        </div>
    );
};

export default EmailDetailRepliedView;