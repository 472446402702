import React from "react";
import { useNavigate } from "react-router-dom";
import forbidden from "../../assets/forbidden.png";


const AccessDenied: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className='w-full h-screen border flex flex-col justify-center items-center gap-4 font-poppins'>

      <div><img className='w-[250px]' src={forbidden} alt="" /></div>
      {/* <div><h3 className='font-semibold text-lg text-[rgb(0,0,0)]'>We are Sorry...</h3></div> */}
      <div><h1 className='text-[#31C6BB] text-4xl font-bold'>Access Denied</h1></div>
      <div className='w-[35%] text-center'><p>The page you are trying to access has restricted access please refer to your system administrator.</p></div>
      <button
        onClick={() => navigate("/")}
        className='rounded-md border  bg-[#60D8CF] px-5 py-2 hover:border hover:border-[#60D8CF] hover:text-[#60D8CF] hover:bg-white'>GO TO HOMEPAGE</button>
    </div>


  );
};

export default AccessDenied;