import React, { useState } from "react";
import ChatHeader from "./ChatHeader/ChatHeader";
import { ChatListing } from "./ChatListing/ChatListing";
import { ChatFooter } from "./ChatFooter/ChatFooter";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const ChatsSection: React.FC = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const [searchInput, setSearchInput] = useState("");

    return (
        <div className={`w-[95%] h-full relative flex flex-col items-center justify-between gap-[12px] ${isDarkMode ? "bg-[#1B1B1D] bg-opacity-70" : ""}`}>

            <ChatHeader setSearchInput={setSearchInput} />
            <ChatListing searchInput={searchInput} />

            <div className='w-full p-1'><ChatFooter /></div>
        </div>
    );
};

export default ChatsSection;