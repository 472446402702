import { useRef, useState } from "react";
import DomainCard from "./DomainCards";
import timerLogo from "../../assets/timerLogo.png";
import { MdArrowForwardIos } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./domains.css";
import { RootState } from "../../redux/store";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { setActiveDomain } from "../../redux/slices/activeDomain";
import NoDataCard from "../Cards/NoDataCard";

export interface IDomains {
    domainId?: number;
    name?: string;
    description?: string;
    order?: number;
    subDomainId?: number;
    subDomains?: string;
    subDomains_Description?: string;
    subDomainURL?: string;
    subDomains_Order?: number;
    logo?: string;
    svg?: string;
    IsStatus?: boolean
}



const DomainComponent: React.FC = () => {

    const isDarkMode = useSelector((state: any) => state.theme && state.theme.isDarkMode);
    const progressBarRef = useRef<HTMLDivElement | null>(null);
    const cardContainerRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const skeletonBaseColor = isDarkMode ? "#2c2c2c" : "#e0e0e0";
    const skeletonHighlightColor = isDarkMode ? "#3a3a3a" : "#f0f0f0";

    const domain = useSelector((state: RootState) => state.domain);
    const role = useSelector((state: RootState) => state.matchRole);
    const activeDomain = useSelector((state: RootState) => state.activeDomain.activeDomainId);

    const domainData = domain.domainIsSuccess && domain.domainData.length ? domain.domainData : [];
    const roleData = role.isSuccess && role.data?.length ? role.data : [];

    const handleDomainClick = (item: number) => {
        dispatch(setActiveDomain(item));
    };

    const [currentPage, setCurrentPage] = useState(1);

    const uniqueDomains = Array.from(new Map(
        domainData.map((domain) => [domain.domainId, { name: domain.name, id: domain.domainId }])
    ).values());


    const filteredDomainData = domainData?.filter(
        (domain) => domain.domainId === activeDomain && domain.subDomains !== null
    );

    //scrolling effects
    const totalPages = Math.ceil(filteredDomainData?.length / 4);
    const initialWidthPercentage = totalPages > 1 ? (1 / totalPages) * 100 : 100;

    const handleScroll = () => {
        const cardContainer = cardContainerRef.current;

        if (cardContainer) {
            const currentScrollWidth = cardContainer.scrollLeft;
            const cardWidth = cardContainer.firstElementChild?.clientWidth || 0;

            // Calculate the current page
            const currentPage = Math.ceil((currentScrollWidth / (4 * cardWidth)) + 1);
            if (currentPage < totalPages) {
                setCurrentPage(currentPage);

                // Calculate scroll percentage based on the page
                const scrollPercentage = (currentPage / totalPages) * 100;
                if (progressBarRef.current) {
                    progressBarRef.current.style.width = `${scrollPercentage}%`;
                }
            }
        }
    };

    const handleRightArrowClick = () => {
        const cardContainer = cardContainerRef.current;
        if (cardContainer) {
            const cardWidth = cardContainer.firstElementChild?.clientWidth || 0;
            const scrollAmount = 4 * cardWidth;
            const newScrollLeft = cardContainer.scrollLeft + scrollAmount;
            cardContainer.scrollTo({ left: newScrollLeft, behavior: "smooth" });
        }
    };

    const handleLeftArrowClick = () => {
        const cardContainer = cardContainerRef.current;
        if (cardContainer) {
            const cardWidth = cardContainer.firstElementChild?.clientWidth || 0;
            const scrollAmount = 4 * cardWidth;
            const newScrollLeft = cardContainer.scrollLeft - scrollAmount;
            cardContainer.scrollTo({ left: newScrollLeft, behavior: "smooth" });
        }
    };
    // Disable buttons based on current page
    const isLeftArrowDisabled = currentPage <= 1;
    const isRightArrowDisabled = currentPage >= totalPages;

    return (
        <>
            {/* Tabs for Mobile Screen and medium screen */}

            <div className="mt-3 lg:hidden">
                {domain.domainIsError
                    ? <div className="flex flex-col">
                        <h1 className="text-[#31C6BB] font-poppins font-semibold text-lg m-2">Apps on TATA Neural</h1>
                        <NoDataCard message="No Domains Found" />
                    </div>
                    : <div>
                        <div className="lg:hidden mt-4 flex flex-col">
                            <div>
                                <ul className="flex flex-nowrap overflow-x-auto no-scrollbar text-center space-x-2">
                                    {domain.domainIsSuccess && domainData.length ?
                                        (uniqueDomains?.map((domain, index) => (
                                            <li
                                                key={index}
                                                className={`w-[95%] cursor-pointer min-w-max p-2 rounded-lg border border-transparent text-sm font-poppins font-normal outline-none hover:border hover:border-[#31C6BB]   ${activeDomain === domain.id
                                                    ? "bg-[#31C6BB] text-white"
                                                    : `${isDarkMode ? "text-white" : ""}`
                                                    }`}
                                                onClick={() => handleDomainClick(domain.id)}
                                            >
                                                {domain.name}
                                            </li>
                                        ))) : Array.from({ length: 5 }).map((_, index) => (
                                            <li key={index}>
                                                <Skeleton
                                                    height={30}
                                                    width={140}
                                                    baseColor={skeletonBaseColor}
                                                    highlightColor={skeletonHighlightColor}
                                                />
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="lg:hidden mt-4">
                            {
                                domain.domainIsSuccess && filteredDomainData
                                    ? filteredDomainData.length
                                        ? (<div className={`grid grid-cols-2 sm:grid-cols-3 gap-3 ${isDarkMode ? "fill-white" : ""}`}>
                                            {
                                                filteredDomainData?.map((domain, index) => (
                                                    <div className="flex justify-center"
                                                        key={index}>
                                                        <DomainCard
                                                            title={domain.subDomains}
                                                            domain={domain.name}
                                                            desc={domain.subDomains_Description as string}
                                                            imageSrc={domain.svg as string}
                                                            link={roleData.some((item) => item.domainId === domain.domainId && item.subDomainId === domain.subDomainId) ? roleData.filter((item) => item.domainId === domain.domainId && item.subDomainId === domain.subDomainId)[0].subDomainURL : null}
                                                            isComingSoon={domain.IsStatus}

                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>) :
                                        <div className='h-full w-full flex items-center justify-center gap-2 py-5 flex-col border '>
                                            <div><img className="w-32" src={timerLogo} alt="COMING SOON" /></div>
                                            <div><h1 className='text-[#636363] text-opacity-45 font-poppins font-bold text-md'>COMING SOON</h1></div>
                                        </div>

                                    : (<div className={`grid grid-cols-2 sm:grid-cols-3 gap-3 ${isDarkMode ? "fill-white" : ""}`}>
                                        {
                                            Array.from({ length: 3 }).map((_, index) => (
                                                <div key={index} >
                                                    <Skeleton
                                                        height={200}
                                                        baseColor={skeletonBaseColor}
                                                        highlightColor={skeletonHighlightColor}
                                                    />
                                                </div>))
                                        }
                                    </div>)

                            }
                        </div>
                    </div>

                }

            </div>

            {/* For Large Screen */}
            <div className="mt-3  hidden lg:block">
                {
                    domain.domainIsError
                        ? <div className="flex flex-col">
                            <h1 className="text-[#31C6BB] font-poppins font-semibold text-lg m-2">Apps on TATA Neural</h1>
                            <NoDataCard message="No Domains Found" />
                        </div>
                        : <div className="hidden lg:grid grid-cols-12 gap-3  ">

                            {/* Domain List */}
                            <div
                                className={`${isDarkMode ? "border-r border-[#FFFFFF] border-opacity-10 mr-[-1px]" : ""} hidden lg:block col-span-12 lg:col-span-3`}>
                                <h1 className="text-[#31C6BB] font-poppins font-semibold text-lg m-2">Apps on TATA Neural</h1>
                                <ul className="list-none md:pl-4">

                                    {domain.domainIsSuccess && domainData.length ?
                                        (uniqueDomains?.map((domain, index) => (
                                            <li
                                                key={index}
                                                className={` transition-colors duration-300 ease-in cursor-pointer mt-3 font-poppins font-normal text-sm text-nowrap ${isDarkMode ? ` ${activeDomain === domain.id ? "font-bold text-[#31C6BB]" : "text-white"}` : ""}  ${activeDomain === domain.id ? "font-bold text-[#31C6BB]" : ""}`}
                                                onClick={() => handleDomainClick(domain.id)} >

                                                <div className="flex items-center justify-between">
                                                    <span>{domain.name}</span>
                                                    {activeDomain === domain.id && (
                                                        <MdArrowForwardIos className="block mr-2" />
                                                    )}
                                                </div>
                                            </li>
                                        )))
                                        : Array.from({ length: 5 }).map((_, index) => (
                                            <li key={index} className="mt-2">
                                                <Skeleton
                                                    height={24}
                                                    width={200}
                                                    baseColor={skeletonBaseColor}
                                                    highlightColor={skeletonHighlightColor}
                                                />
                                            </li>
                                        ))
                                    }

                                </ul>
                            </div>

                            <div className={`border ${isDarkMode ? "border-[#FFFFFF] border-opacity-10" : "border-gray-200"}  rounded-md col-span-12 lg:col-span-9 `}>
                                {/* Domain Cards */}
                                <div className="  p-4 h-[85%] no-scrollbar overflow-x-auto " ref={cardContainerRef} onScroll={handleScroll}>
                                    <div className={`h-full flex flex-start items-center gap-4 ${isDarkMode ? "" : ""}`}>
                                        {
                                            domain.domainIsSuccess && filteredDomainData ?
                                                filteredDomainData.length
                                                    ? (filteredDomainData?.map((domain, index) => (
                                                        <div className={`flex justify-center h-full ${isDarkMode ? "!fill-white" : ""}`}
                                                            key={index}>
                                                            <DomainCard
                                                                title={domain.subDomains}
                                                                domain={domain.name}
                                                                desc={domain.subDomains_Description as string}
                                                                imageSrc={domain.svg as string}
                                                                link={roleData.some((item) => item.domainId === domain.domainId && item.subDomainId === domain.subDomainId) ? roleData.filter((item) => item.domainId === domain.domainId && item.subDomainId === domain.subDomainId)[0].subDomainURL : null}
                                                                isComingSoon={domain.IsStatus}
                                                            />
                                                        </div>
                                                    ))) : (
                                                        <div className='h-full w-full flex items-center justify-center gap-2 flex-col pt-10 '>
                                                            <div><img className="w-32" src={timerLogo} alt="COMING SOON" /></div>
                                                            <div><h1 className='text-[#636363] text-opacity-45 font-poppins font-bold text-md'>COMING SOON</h1></div>
                                                        </div>
                                                    ) : (Array.from({ length: 4 }).map((_, index) => (
                                                        <div key={index} className="flex justify-center items-center pt-2">
                                                            <Skeleton
                                                                height={200}
                                                                width={250}
                                                                baseColor={skeletonBaseColor}
                                                                highlightColor={skeletonHighlightColor}
                                                            />
                                                        </div>))

                                                )
                                        }
                                    </div>
                                </div>

                                {/* Progress Bar */}
                                {filteredDomainData.length ?
                                    (<div className="h-[15%]  hidden lg:block px-5 py-1">
                                        <div className='flex items-center justify-between gap-3'>
                                            <div className="relative w-full bg-gray-200 rounded-full h-[3px]">
                                                <div
                                                    className="absolute left-0 top-[-1.5px] bottom-0 bg-[#31C6BB] h-[6px] rounded-full"
                                                    ref={progressBarRef}
                                                    style={{ width: `${initialWidthPercentage}%` }}
                                                />
                                            </div>

                                            <div className="flex items-center justify-center gap-1">
                                                <button
                                                    className={`border border-[#31C6BB] flex justify-center text-[#31C6BB] rounded-full p-[3px] 
                                         ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                                                        }`}
                                                    onClick={handleLeftArrowClick}
                                                    disabled={isLeftArrowDisabled}
                                                >
                                                    <IoIosArrowBack />
                                                </button>
                                                <span className="text-xs text-gray-600">
                                                    {`${currentPage}/${totalPages}`}
                                                </span>
                                                <button
                                                    className={`border border-[#31C6BB] flex justify-center text-[#31C6BB] rounded-full p-[3px]
                                         ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
                                                        }`}
                                                    onClick={handleRightArrowClick}
                                                    disabled={isRightArrowDisabled}
                                                >
                                                    <IoIosArrowForward />
                                                </button>
                                            </div>
                                        </div>
                                    </div>) : ""
                                }
                            </div>
                        </div>
                }

            </div>
        </>
    );
};

export default DomainComponent;
