// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Define the keyframes for the animation */
@keyframes slideDown {
     0% {
        height: 0;
        opacity: 0;
      }
    
      100% {
        height: 50px;
        opacity: 1;
      }
  }
  
  /* Apply the animation tadsfo the div */
  .div-to-animate {
    opacity: 0; /* Start with opacity 0 */
    overflow: hidden; /* Hide overflow during animation */
    /* background-color: lightblue;  */
    animation: slideDown 0.2s linear forwards; /* Animation name, duration, timing function, and fill mode */
  }
  .transition-transform {
    transition: transform 0.2s linear; /* Add transition for transform if needed */
  }
`, "",{"version":3,"sources":["webpack://./src/components/ConvSection/ConvSection.css"],"names":[],"mappings":";AACA,2CAA2C;AAC3C;KACK;QACG,SAAS;QACT,UAAU;MACZ;;MAEA;QACE,YAAY;QACZ,UAAU;MACZ;EACJ;;EAEA,uCAAuC;EACvC;IACE,UAAU,EAAE,yBAAyB;IACrC,gBAAgB,EAAE,mCAAmC;IACrD,kCAAkC;IAClC,yCAAyC,EAAE,6DAA6D;EAC1G;EACA;IACE,iCAAiC,EAAE,2CAA2C;EAChF","sourcesContent":["\n/* Define the keyframes for the animation */\n@keyframes slideDown {\n     0% {\n        height: 0;\n        opacity: 0;\n      }\n    \n      100% {\n        height: 50px;\n        opacity: 1;\n      }\n  }\n  \n  /* Apply the animation tadsfo the div */\n  .div-to-animate {\n    opacity: 0; /* Start with opacity 0 */\n    overflow: hidden; /* Hide overflow during animation */\n    /* background-color: lightblue;  */\n    animation: slideDown 0.2s linear forwards; /* Animation name, duration, timing function, and fill mode */\n  }\n  .transition-transform {\n    transition: transform 0.2s linear; /* Add transition for transform if needed */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
