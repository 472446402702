import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetEmailListPayload {
    pageNo: number;
    pageSize: number;
    emailId: string;
    confidenceStatus: string;
    search: string;
}

export interface IGetEmailListItem {
    ID: number
    Subject: string;
    BodyPreview: string;
    InternetMessageId: string;
    Sender_name: string;
    Sender_email: string;
    ReceivedDateTime: string;
    EmailContentType: string;
    EmailContentBody: string;
    Confidence_status: string | null;
    Reply_status: boolean | null;
    EmailId: string;
    EmailAutomationID: string;
    ResponseEmailContentBody: string;
    ResponseEmailContentType: string;
}

export interface IGetEmailListData {
    status_code: string;
    message: string;
    totalRecords: number;
    data: IGetEmailListItem[];

}

export interface IGetEmailListDataState {
    response: IGetEmailListData;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any | null;
}


const initialState: IGetEmailListDataState = {
    response: {
        status_code: "",
        message: "",
        totalRecords: null,
        data: []
    },
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null
};

export const getEmailListSlice = createSlice({
    name: "getEmailListSlice",
    initialState,
    reducers: {
        getEmailListStart: (state, action: PayloadAction<IGetEmailListPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getEmailListSuccess: (state, action: PayloadAction<IGetEmailListData>) => {
            state.response = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        getEmailListFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getEmailListStart, getEmailListSuccess, getEmailListFailure } = getEmailListSlice.actions;

export default getEmailListSlice.reducer;