import { ForkEffect, call, cancel, fork, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { JWT_TOKEN_GET_API, JWT_TOKEN_REFRESH_API } from "../../../config";
import { getJWTokenFailure, getJWTokenStart, getJWTokenSuccess, refreshJWTokenFailure, refreshJWTokenStart, refreshJWTokenSuccess } from "../../slices/jwTokenSlice";
import { httpGet, httpPost } from "../../../services";


function* getJWToken(action: any) {
    try {

        const data = yield httpGet(`${process.env.REACT_APP_BOT_URL}/api/directline/token`, {});
        yield put(getJWTokenSuccess(data));

    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            yield put(getJWTokenFailure(error?.message));
        } else {
            yield put(getJWTokenFailure("Error occurred while APi calling"));
        }
    }
}

function* refreshJWToken(action: any) {
    try {

        const data = yield httpPost(`${process.env.REACT_APP_BOT_URL}/api/directline/refresh-token`, { token: action.payload });
        yield put(refreshJWTokenSuccess(data));

    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            yield put(refreshJWTokenFailure(error?.message));
        } else {
            yield put(refreshJWTokenFailure("Error occurred while API calling"));
        }
    }
}


function* watchFetchDataSaga(): Generator<any> {
    let lastTask: any;
    while (true) {
        const action = yield take(getJWTokenStart.type);
        if (lastTask) {
            yield cancel(lastTask);
        }
        lastTask = yield fork(getJWToken, action);
    }
}


export default function* watchGetJWToken() {
    yield fork(watchFetchDataSaga);
    yield takeLatest(refreshJWTokenStart.type, refreshJWToken);
}