/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import MailTable from "./MailTable";
import MailTablePagination from "./MailTablePagination";
import { useDispatch, useSelector } from "react-redux";
import { getEmailListStart, IGetEmailListItem } from "../../../redux/slices/EmailSlice/getEmailListSlice";
import { RootState } from "../../../redux/store";

export interface MailData {
  id: number;
  "Email Received Date": string;
  "Sender Email": string;
  subject: string;
  Response: string;
  "Confidence Level": string;
  Action: string;
}

type IMailScreen = {
  setIsDetailView: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailRow: React.Dispatch<React.SetStateAction<IGetEmailListItem>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
};
const MailScreen: React.FC<IMailScreen> = ({ setIsDetailView, setDetailRow, rowsPerPage, setRowsPerPage }) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const [mailData, setMailData] = useState<IGetEmailListItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const fetchEmails = (pageNo: number, pageSize: number, confidenceStatus: string, search: string) => {
    dispatch(getEmailListStart({ pageNo, pageSize, emailId: process.env.REACT_APP_MAIL_MITRA_EMAIL_ID, confidenceStatus, search }));
  };


  useEffect(() => {
    fetchEmails(currentPage, rowsPerPage, state.app.confidence_level, state.app.search);
  }, [currentPage, rowsPerPage, state.app.confidence_level, state.app.search]);

  useEffect(() => {
    if (state.getEmailList.isSuccess) {
      setMailData(state.getEmailList.response?.data || []);
      setTotalItems(state.getEmailList.response?.totalRecords || 0);  // Assume API returns total items
    }
  }, [state.getEmailList.isSuccess]);

  const handlePageChange = (newPageNo: number, newRowsPerPage: number) => {
    setCurrentPage(newPageNo);
    setRowsPerPage(newRowsPerPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
 

  return (
    <div className="w-full mt-3 mb-[5px] h-full rounded-[10px] flex flex-col justify-between ">
      <MailTable mailData={mailData} setIsDetailView={setIsDetailView} setDetailRow={setDetailRow} />
      <MailTablePagination
        mailData={mailData}
        totalItems={totalItems}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange} />
    </div>

  );
};

export default MailScreen;
