import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IMatchRoleApiResponse {
    ID: number;
    UserDomainID: string;
    role: string | null;
    domainId: number;
    domainName: string;
    domainDescription:string |null;
    domainOrder:number;
    subDomainId: number;
    subDomainName: string;
    subDomainDescription: string;
    subDomainOrder: number;
    subDomainURL: string;
    subDomainLogo: string | null;
    subDomainSvg: string;
}

interface MatchRoleApiState {
    loading: boolean;
    isSuccess: boolean;
    isError: boolean;
    data: IMatchRoleApiResponse[] | null;
    error: string | null;
}

const initialState: MatchRoleApiState = {
    loading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
};

const matchRoleApiSlice = createSlice({
    name: "matchRoleApi",
    initialState,
    reducers: {
        matchRoleRequestStart(state, action: PayloadAction<{ domainId: string }>) {
            state.loading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        matchRoleRequestSuccess(state, action: PayloadAction<IMatchRoleApiResponse[]>) {
            state.loading = false;
            state.isSuccess = true;
            state.isError = false;
            state.data = action.payload;
        },

        matchRoleRequestFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        }

    },
});


export const { matchRoleRequestStart, matchRoleRequestSuccess, matchRoleRequestFailure } = matchRoleApiSlice.actions;
export default matchRoleApiSlice.reducer;
