// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dot {
  width: 5px;   
  height: 5px;
  background-color: #858585;
  border-radius: 50%;
  margin: 0 3px;
}

.animate-dot {
    animation: moveUpwards 2s ease-out infinite; /* Animation definition */
  }
  
  @keyframes moveUpwards {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px); /* Adjust the distance to move upwards */
    }
    100% {
        transform: translateY(0); /* Adjust the distance to move upwards */
      }

  }
`, "",{"version":3,"sources":["webpack://./src/components/Typing/typing.css"],"names":[],"mappings":";AACA;EACE,UAAU;EACV,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;AACf;;AAEA;IACI,2CAA2C,EAAE,yBAAyB;EACxE;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,2BAA2B,EAAE,wCAAwC;IACvE;IACA;QACI,wBAAwB,EAAE,wCAAwC;MACpE;;EAEJ","sourcesContent":["\n.dot {\n  width: 5px;   \n  height: 5px;\n  background-color: #858585;\n  border-radius: 50%;\n  margin: 0 3px;\n}\n\n.animate-dot {\n    animation: moveUpwards 2s ease-out infinite; /* Animation definition */\n  }\n  \n  @keyframes moveUpwards {\n    0% {\n      transform: translateY(0);\n    }\n    50% {\n      transform: translateY(-5px); /* Adjust the distance to move upwards */\n    }\n    100% {\n        transform: translateY(0); /* Adjust the distance to move upwards */\n      }\n\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
