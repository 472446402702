import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { IActivity } from "../../redux/slices/ChatSlice";
import { feedbackApiRequestStart } from "../../redux/slices/updateFeedbackSlice";

interface IFeedbackActivitiesProps {
    activity: IActivity
}
const FeedbackCard: React.FC<IFeedbackActivitiesProps> = ({ activity }) => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const selectedThreadId = useSelector((state: RootState) => state.chat.selectedThreadId);

    const { success, userData } = useSelector((state: RootState) => state.createUser);
    const userId = success && userData ? userData?.ID : null;

    const dispatch = useDispatch();

    const [activeCard, setActiveCard] = useState<{ activityId: string, action: string }>({
        activityId: "",
        action: ""
    });

    let feedbackGiven = false;
    const feedback = activity.feedback;
    if (feedback) feedbackGiven = true;

    // Retrieve the specific activity's loading state
    const { loading, isSuccess, feedbackActiveId, feedbackList } = useSelector((state: RootState) => state.feedback);

    const handleYesClick = () => {
        const payload = {
            userId: userId,
            threadId: selectedThreadId,
            messageId: activity.id,
            feedback: "like",
            cachePayload: {
                question: activity.channelData?.question as string | "",
                response: activity.channelData?.output ?? activity.text,
            }

        };
        setActiveCard({ activityId: activity.id as string, action: "like" });
        dispatch(feedbackApiRequestStart(payload));
    };

    const handleNoClick = () => {
        const payload = {
            userId: userId,
            threadId: selectedThreadId,
            messageId: activity.id,
            feedback: "dislike"
        };
        setActiveCard({ activityId: activity.id as string, action: "dislike" });
        dispatch(feedbackApiRequestStart(payload));
    };
  

    return (
        <div className={`flex flex-col justify-start gap-2 font-normal text-[12px] 2xl:text-[14px] mt-3 p-2 pl-0 ${isDarkMode ? "text-[#aeaeae]" : "text-[#777676]"}`}>
            <h1 className=''>Feedback! Do you like the response of the bot ?</h1>
            <div className='flex flex-row gap-2 justify-start'>
                <button
                    onClick={handleYesClick}
                    disabled={feedbackGiven || feedbackList.some((item) => item.feedbackId === activity.id) || feedbackActiveId === activity.id}
                    className={` ${isDarkMode ? "bg-[#3E3D3D] hover:bg-[#383737]" : "bg-[#ebe9e9] hover:bg-[#FFFFFF]"}
                     py-[10px] px-[20px] rounded-[10px] w-[100px] relative
                     ${(feedbackGiven || feedbackList.some((item) => item.feedbackId === activity.id) || feedbackActiveId === activity.id) ? "opacity-70 cursor-not-allowed" : ""}`}>

                    {(feedback && feedback === "like") || (feedbackList.some((item) => item.feedbackId === activity.id && item.feedbackAction === "like"))
                        ? <div className="flex flex-row items-center justify-center gap-1 ">
                            <span>Liked</span>
                            <span> &#128077;</span>
                        </div>

                        : activity.id === feedbackActiveId && loading && activeCard.action === "like"
                            ? (
                                <div className="absolute inset-0 flex items-center justify-center ">
                                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-[#31C6BB] "></div>
                                </div>
                            ) : (
                                <div className="flex flex-row items-center justify-center gap-1 ">
                                    <span>Yes </span>
                                    <span>&#128512;</span>
                                </div>
                            )}
                </button>
                <button
                    onClick={handleNoClick}
                    disabled={feedbackGiven || feedbackList.some((item) => item.feedbackId === activity.id) || feedbackActiveId === activity.id}
                    className={`${isDarkMode ? "bg-[#3E3D3D] hover:bg-[#383737]" : "bg-[#ebe9e9] hover:bg-[#FFFFFF]"} py-[10px] px-[20px] w-[100px] rounded-[10px] relative
                     ${(feedbackGiven || feedbackList.some((item) => item.feedbackId === activity.id) || feedbackActiveId === activity.id) ? "opacity-70 cursor-not-allowed" : ""}`}>


                    {(feedback && feedback === "dislike") || (feedbackList.some((item) => item.feedbackId === activity.id && item.feedbackAction === "dislike"))
                        ? <div className="flex flex-row items-center justify-center gap-1 ">
                            <span>Disliked</span>
                            <span>&#128078;</span>
                        </div>

                        : activity.id === feedbackActiveId && loading && activeCard.action === "dislike"
                            ? (
                                <div className="absolute inset-0 flex items-center justify-center ">
                                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-[#31C6BB] "></div>
                                </div>
                            ) : (
                                <div className="flex flex-row items-center justify-center gap-1 ">
                                    <span>No</span>
                                    <span>&#128577;</span>
                                </div>
                            )}
                </button>
            </div>
        </div>
    );
};

export default FeedbackCard;