import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import comingSoonTag from "../../assets/comingSoonTag.png";
import "./domains.css";

interface CardProps {
    imageSrc: string;
    title: string;
    domain: string;
    desc: string;
    link: string | null;
    isComingSoon: boolean
}

const DomainCard: React.FC<CardProps> = ({ imageSrc, title, domain, desc, link, isComingSoon }) => {

    const isDarkMode = useSelector((state: any) => state.theme && state.theme.isDarkMode);
    const textColor = isDarkMode ? "text-white" : "text-[#282828]";


    const renderImage = () => {
        if (imageSrc.startsWith("<svg")) {
            return <div className={`${isDarkMode ? "svg-style-dark" : "svg-style-light"}`} dangerouslySetInnerHTML={{ __html: imageSrc }} />;
        }
        return <img src={imageSrc} alt="Domain Logo" className="w-10 h-10" />;
    };

    return (

        <div className="flip-card relative h-full min-h-[215px] w-full md:w-52">
            {/* <Link to={link as string} target={link === null ? "_self" : "_blank"}> */}
            <div className="flip-card-inner w-full h-full relative ">

                <div
                    className={`flip-card-front absolute h-full w-full ${isDarkMode ? "!fill-white" : ""} shadow p-2 pb-7 shadow-[#101010]-300/30 border ${isDarkMode ? "border-[#4B4C56] bg-[#FFFFFF] bg-opacity-10" : "border-gray-200 bg-white"} flex flex-col justify-start gap-1 rounded-sm w-[220px]  `}>

                    <div className={` text-center font-normal font-poppins h-full w-full ${isDarkMode ? "border-[#FFFFFF] border-opacity-10 fill-white" : "border-[#121823] border-opacity-10"} rounded-md  flex flex-col items-center justify-center gap-1`}>
                        {renderImage()}
                        <h6 className={` text-[12px] tracking-tight ${textColor} text-center`}>{title}</h6>
                    </div>

                    {/* <div className="p-[3px]"> {renderImage()}</div>
                        <p className={`truncate text-sm font-poppins max-h-10 font-medium text-wrap tracking-tight  overflow-y-auto no-scrollbar ${textColor}`}>{title}</p>
                        <h6 className='text-[#31C6BB] font-poppins font-normal text-xs '>{domain}</h6>
                        <p className={` text-[10px] overflow-y-auto font-poppins font-light no-scrollbar ${textColor}`} title={desc}>{desc}</p> */}

                    {!isComingSoon &&
                        <div className="absolute bottom-0 w-full mx-auto left-0">
                            <div className="flex justify-center items-center"><img className=" w-[80%] h-full align-center" src={comingSoonTag} alt="COMING SOON" /></div>
                        </div>}
                </div >

                <div className={`flip-card-back absolute text-center h-full w-full px-2 border ${isDarkMode ? "border-[#FFFFFF] border-opacity-10 fill-white" : "border-[#121823] border-opacity-10"} flex flex-col items-center justify-center rounded-md shadow shadow-[#101010]-300/30 gap-1`}>
                    <p className={`font-poppins flex items-center justify-center h-full w-full font-light text-[10px] tracking-tight ${textColor}`}>
                        {desc}
                    </p>
                    {link &&
                        <div className="p-1 pb-2">
                            <Link to={link as string} target={link === null ? "_self" : "_blank"}>
                                <button
                                    className={` cursor-pointer rounded-full bg-[#31C6BB] text-white px-3 py-[6px] font-poppins font-medium text-[12px] shadow-lg border border-transparent hover:border-[#31C6BB] hover:text-[#31C6BB] ${isDarkMode ? "hover:bg-transparent" : "hover:bg-white"}`}>
                                    Click Here
                                </button>
                            </Link>
                        </div>
                    }
                </div>

            </div>
            {/* </Link> */}
        </div>

    );
};

export default DomainCard;
