import React, { useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import "../MainChatSection/markdown.css"; // Assuming you have some basic styles
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import remarkGfm from "remark-gfm";
import "./details.css";
interface ToggleTableProps {
    markdownTable: string;
}

const AdditionalDetails: React.FC<ToggleTableProps> = ({ markdownTable }) => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    const [isTableVisible, setIsTableVisible] = useState(false);
    const tableRef = useRef<HTMLDivElement>(null);

    const toggleTableVisibility = () => {
        if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
        setIsTableVisible(prev => !prev);
    };

    return (
        <div className="h-full w-full my-3 flex flex-col gap-2">


            <button
                onClick={toggleTableVisibility}
                className={`w-24 border border-opacity-50 p-1 transition-transform duration-300 ease-in-out hover:scale-105 shadow-sm rounded-md ${isDarkMode ? "border-[#aeaeae]" : "border-[#777676]"} font-poppins text-[10px] 2xl:text-[12px]`} >
                {isTableVisible ? "Show less.." : "Show details.."}
            </button>

            {isTableVisible && (
                <div className="  rounded-md p-1 ">
                    <ReactMarkdown className={`markdown-summary ${isDarkMode ? "markdown-content-dark scrollbar-dark" : "markdown-content scrollbar-light"}  max-h-[500px]`} remarkPlugins={[remarkGfm]}>
                        {markdownTable}
                    </ReactMarkdown>

                </div>
            )}


        </div>
    );
};

export default AdditionalDetails;
