// store.ts
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import themeReducer from "./slices/themeSlice";
import drawerReducer from "./slices/DrawerSlice";
import chatReducer from "./slices/ChatSlice";
import selectedMessageReducer from "./slices/selectedMessageSlice";
import domainReducer from "./slices/domainSlice";
import getJWTokenReducer from "./slices/jwTokenSlice";
import userInfoReducer from "./slices/userInfoByAzureADSlice";
import userImgReducer from "./slices/userImgByAzureADSlice";
import matchRoleReducer from "./slices/matchRoleSlice";
import bingSearchReducer from "./slices/bingApiSlice";
import activeDomainReducer from "./slices/activeDomain";
import activeTableMailIdReducer from "./slices/activeTableMailId";
import tokenDecodeReducer from "./slices/tokenDecodeSlice";
import keycloaktokenReducer from "./slices/keyCloakTokenSlice";
import createUserReducer from "./slices/createUserSlice";
import userChatsFromDBeducer from "./slices/getUserChatsSlice";
import deleteUserChatsFromDBreducer from "./slices/deleteUserChatsSlice";
import updateFeedbackreducer from "./slices/updateFeedbackSlice";
import rootSaga from "./saga";
import persistStore from "redux-persist/es/persistStore";
import getEmailListReducer from "./slices/EmailSlice/getEmailListSlice";
import getRepliedEmailListReducer from "./slices/EmailSlice/getRepliedEmailListSlice";
import postEmailReplyReducer from "./slices/EmailSlice/postEmailReplySlice";
import appReducer from "./slices/appSlice";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["domain", "matchRole", "userImg", "activeDomain", "userInfo", "activeMailId", "feedback"]
};

const rootReducer = combineReducers({
    theme: themeReducer,
    drawer: drawerReducer,
    chat: chatReducer,
    selectedMessage: selectedMessageReducer,
    getJWToken: getJWTokenReducer,
    domain: domainReducer,
    matchRole: matchRoleReducer,
    userInfo: userInfoReducer,
    bingSearch: bingSearchReducer,
    userImg: userImgReducer,
    activeMailId: activeTableMailIdReducer,
    activeDomain: activeDomainReducer,
    getEmailList: getEmailListReducer,
    getRepliedEmailList: getRepliedEmailListReducer,
    postEmailReply: postEmailReplyReducer,
    app: appReducer,
    tokenDecode: tokenDecodeReducer,
    keycloakToken: keycloaktokenReducer,
    createUser: createUserReducer,
    userChats: userChatsFromDBeducer,
    deleteUserChats: deleteUserChatsFromDBreducer,
    feedback: updateFeedbackreducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(middleware),
    devTools: process.env.NODE_ENV !== "production"
});

sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);


export default store;
export { persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;




